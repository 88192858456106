/**
 * Growingio埋点 设置自定义事件和事件级变量
 * @param { eventId } string  // 事件标识符
 * @param { eventLevelVariables } JSON Object // 包含事件级变量的JSON对象
 * @param eventLevelVariables.page_url // 页面url
 * @param eventLevelVariables.login_date // 登录时间
 * @param eventLevelVariables.reg_date // 注册时间
 * @param eventLevelVariables.user_name // 用户名称
 * @param eventLevelVariables.user_id // 用户id
 * @param eventLevelVariables.m_name // 素材名称
 * @param eventLevelVariables.mid // 素材id
 * @param eventLevelVariables.slt_voice // AI语音-所选声音
 * @param eventLevelVariables.op_date // 操作时间
 * @param eventLevelVariables.pay_type // 支付方式
 * @param eventLevelVariables.cert_channel // 授权渠道
 * @param eventLevelVariables.cert_project // 授权项目
 * @param eventLevelVariables.cert_channel // 授权渠道
 * @param eventLevelVariables.package_name // 套餐名称
 * @param eventLevelVariables.package_type // 套餐类型
 * @param eventLevelVariables.search_name // 搜索内容
 * @param eventLevelVariables.m_type // 素材类型
 */
export function send_eventApi(eventId, eventLevelVariables ){
    // console.log(eventId, eventLevelVariables)
    if(process.env.NODE_ENV == 'development') {
        return false;
    }else{
        // console.log('发送埋点事件')
        if(!eventLevelVariables.user_name || eventLevelVariables.user_name == ''){
            eventLevelVariables.user_name = (window.gio('getVisitUserId') && window.gio('getVisitUserId')[0]) || "";
        }
        if(!eventLevelVariables.user_id || eventLevelVariables.user_id == ''){
            eventLevelVariables.user_id = (window.gio('getVisitUserId') && window.gio('getVisitUserId')[0]) || "";
        }
        // console.log(eventId, eventLevelVariables)
        window.gio('track', eventId, eventLevelVariables);
    }
}
