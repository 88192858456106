// 本地sessionStorage存值isShowMember
function setStorage(val){
    return window.sessionStorage.setItem('isShowMember', val)
}
// 本地sessionStorage取值isShowMember
function getStorage(){
    return window.sessionStorage.getItem('isShowMember')
}

// 存储分享用户id
function setStorageUserId(val){
    return window.sessionStorage.setItem('shareuser_id', val)
}

// 获取分享用户id
function getStorageUserId(val){
    return window.sessionStorage.getItem('shareuser_id', val)
}

// 存储手机端分享用户ID
function setStorageH5shareId(val){
    return window.sessionStorage.setItem('share_id', val)
}

// 获取手机端分享用户id
function getStorageH5shareId(){
    return window.sessionStorage.getItem('share_id')
}
export{
    setStorage,
    getStorage,
    setStorageUserId,
    getStorageUserId,
    setStorageH5shareId,
    getStorageH5shareId
}