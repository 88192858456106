/**
 * 购物车相关的API
 * 2021-12-21 14:24:57
 * @author SnowRock
 */

import request from '@/utils/request'

/**
 * 加入购物车
 * 2021-12-21 14:25:26
 * @author SnowRock
 * @param params
 * @param params.object_id 商品id
 * @param params.object_type 商品类型：1AI语音 2音乐 3音效 4视频
 */
export function insertGoodsToCart(params) {
  return request.post('/fontend/cart/add', params, {})
}

/**
 * 获取购物车列表
 * 2021-12-21 14:59:55
 * @author SnowRock
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function fetchCardGoodsList(params) {
  return request.post('/fontend/cart/list', params, {})
}

/**
 * 删除购物车内的商品
 * 2021-12-21 14:59:55
 * @author SnowRock
 * @param params
 * @param params.cart_id 购物车内商品id
 * @returns {Promise<AxiosResponse<any>>}
 */
export function delCardGoods(params) {
  return request.post('/fontend/cart/del', params, {})
}
