/**
 * 用户
 * 2021-12-16 18:04:55
 * @author SnowRock
 */
import request from '@/utils/request'

/**
 * 用户注册
 * 2021-12-16 18:03:11
 * @author SnowRock
 * @param params
 * @param params.name 用户名称
 * @param params.account 手机号或邮箱
 * @param params.password 密码
 * @param params.transaction_id 验证码事务ID
 * @param params.verify_code 验证码
 * @returns {Promise<AxiosResponse<any>>}
 */
export function registerUser(params) {
  return request.post('/fontend/user/reg', params, {})
}

/**
 * 找回密码
 * 2021-12-28 16:15:14
 * @author SnowRock
 * @param params
 * @param params.account 手机号或邮箱
 * @param params.password 密码
 * @param params.password_two 再次输入密码
 * @param params.transaction_id 验证码事务ID
 * @param params.verify_code 验证码
 * @returns {Promise<AxiosResponse<any>>}
 */
export function findPwdUser(params) {
  return request.post('/fontend/user/resetPwd', params, {})
}

/**
 * 用户登录
 * 2021-12-16 18:03:11
 * @author SnowRock
 * @param params
 * @param params.login_type 登录方式 1账号密码 2验证码
 * @param params.account 手机号或邮箱
 * @param params.password 密码
 * @param params.transaction_id 验证码事务ID
 * @param params.verify_code 验证码
 * @returns {Promise<AxiosResponse<any>>}
 */
export function loginForUser(params) {
  return request.post('/fontend/user/login', params, {})
}

/**
 * 退出登录
 * 2021-12-17 11:44:15
 * @author SnowRock
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function loginOut(params) {
  return request.post('/fontend/user/logout', params, {})
}

/**
 * 获取用户信息
 * 2021-12-16 18:11:43
 * @author SnowRock
 * @param params 为空即可
 * @returns {Promise<AxiosResponse<any>>}
 */
export function fetchUserInfo(params) {
  return request.post('/fontend/user/info', params, {})
}

/**
 * 获取用户的套餐列表
 * 2021-12-22 16:13:12
 * @author SnowRock
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function fetchUserVips(params) {
  return request.post('/fontend/user/vips', params, {})
}
/**
 * 获取会员详情信息
 * 2021年12月23日13:41:39
 * @author SnowRock
 * @param params
 * @param params.order_id 订单id
 * @returns {Promise<AxiosResponse<any>>}
 */
export function fetchUserVipInfo(params) {
  return request.post('/fontend/user/vip', params, {})
}
/**
 * 获取用户的订单套餐列表
 * 2021-12-22 16:13:12
 * @author SnowRock
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function fetchUserVipsOrderList(params) {
  return request.post('/fontend/user/orderList', params, {})
}

/**
 * 获取设备唯一标识
 * 2022-04-18 11:36:15
 * @author SnowRock
 * @returns {*}
 */
export function fetchTempDeviceId() {
  return request.post('/fontend/user/device_info', { }, {})
}

/**
 * @description 授权主体列表
 */
export function fetchUserList(params) {
  return request.post('/fontend/user/userList', params, {})
}

/**
 * @description 创建授权主体
 */
export function fetchAddContractUser(params) {
  return request.post('/fontend/user/addContractUser', params, {})
}