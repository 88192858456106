import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'
import { getTempDeviceId, getToken, setTempDeviceId } from "@/utils/author";
import { getCartList } from '@/utils/operate'

Vue.use(VueRouter)
// 解决跳转路由报不能跳转同一路由的错误
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error)
}


const modulesFiles = require.context('./modules', true, /\.js$/)
let modulesRouters = []

const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  const value = modulesFiles(modulePath)
  // 如果default为空则跳过当前循环
  if (value.default) {
    // 判断传入的路由是否为数组，是则合并数组，否则直接添加进路由集合中
    if (Array.isArray(value.default)) {
      modulesRouters = [...modulesRouters, ...value.default]
    } else {
      modulesRouters.push(value.default)
    }
  }
  return modulesRouters
}, {})

const routes = [
  {
    path: '/',
    redirect: '/playList'
  },
  // {
  //   path: '/test',
  //   component: () => import('@/test')
  // },
  ...modules,
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if(savedPosition){
      window.scroll({ top: savedPosition.y, left: savedPosition.x })
    }else {
      window.scroll({ top: 0, left: 0, behavior: 'smooth' })
    }
  },
  routes
})

router.beforeEach(async (to, from, next) => {
  const { meta } = to
  const ua = navigator.userAgent;
  // 添加设备唯一标识
  const tempDeviceId = getTempDeviceId()
  if (!(tempDeviceId && tempDeviceId !== '')) {
    setTempDeviceId()
  }
  // 如果页面中不存在userinfo 自动获取并且进行处理

  if (!store.state.user.userInfo) {
    if (getToken()) {
      await store.dispatch('user/getUserInfo')
      await getCartList()
      // 获取未读消息数量
      await store.dispatch('message/updateMessageCount')
    }
  }
  // 判断页面是移动端还是pc端
  if(ua.match(/(iPhone|iPad|Android|ios)/i)){
    if(to.path == '/playList'){
      const searchInfo = window.location.search;
      window.location.href = `https://app_m.playlistmusic.com.cn${searchInfo}`
      next()
      // next({
      //   path: '/home'
      // })
      return
    }
    // 路由切换调整 移动端页面限制
    if(to.path == '/package' || to.path == '/package/page'){
      next({
        path: '/mobile/member'
      })
      return
    }
  }
  if (meta) {
    let title = 'PlayList-视频工作者素材平台'
    let content = {
      keywords: '音乐、视频、音效、AI语音、ai语音、背景音乐、视频模版、素材、音乐素材、视频素材、音效素材、版权、音乐版权、正版音乐、音乐授权、视频授权、订阅会员、PLAYLIST、PLAYLISTMUSIC、PlayListMusic、playlistmusic、music、MUSIC、免费音乐、免费视频、音乐下载、视频下载、文字转语音、语音下载、版权音乐、版权视频',
      description: 'PlayList是一款视频工作者素材平台网站。涵盖音乐、音效、视频、AI语音四个种类的内容资源，一站式解决视频工作者的素材需求。海量库存资源，覆盖多数业务场景，每次下载均提供正规授权书，极致性价比，让用户花最少的钱，下载更多资源。'
    }
    let head = document.getElementsByTagName('head');
    let metaRef = document.createElement('meta');
    document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content ? to.meta.content.keywords : content.keywords)
    document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content ? to.meta.content.description : content.description)
    metaRef.content = to.meta.content || content
    head[0].appendChild(metaRef)
    document.title = to.meta.pageTitle ? to.meta.pageTitle : title

    // 拦截需要登录的路由页面
    if (meta.isLogin) {
      const token = getToken()
      if (!token) {
        next({
          path: document.body.clientWidth >= 980 ? from.path || '/' : '/login',
          query: {
            redirect: to.fullPath,
            isOpenLogin: document.body.clientWidth >= 980 ? 1 : ''
          }
        })
        store.dispatch('user/openLogin')
        return
      }
    }
    // // 是否必须要会员
    // if (meta.hasMember && !store.getters.hasMember) {
    //   next({
    //     path: '/package'
    //   })
    //   return
    // }
  }
  next()
})

export default router
