import { fetchUserInfo, loginForUser, loginOut } from "@/api/user";
import { setToken, setUserId, setUserInfo } from "@/utils/author";
import router from "@/router";
import { getCartList } from "@/utils/operate";

const loginName = "LoginForm";
const registerName = "RegisterForm";

const state = {
  userInfo: null,
  // 打开登录模态框
  dialogForLoginVisible: false,
  showMember: window.sessionStorage.getItem("memberStatus"), // 是否显示注册即送会员
  loginComponentsName: "LoginForm",
  dialogForHelpVisible: false, // 是否立即助力分享弹窗

  device: navigator.userAgent.match(/(iPhone|iPad|Android|ios)/i) ? 'mobile' : 'pc',
  membershipExpiringList: [], // 未过期会员弹框
  expiringDialogVisible: false, // 未过期会员开关
  expiredDialogVisible: false,
  registerSuccessDialogVisible: false, // 是否显示注册成功弹框
  swiperIndex: 0,
  sourceInfo: null, // 链接来源信息
  orderCheckDetailRouteInfo: null,
  vipCheckDetailRouteInfo: null // 我的会员查看详情后点击我的会员tab记录去详情页之前的页面信息
};

const actions = {
  /**
   * 设置分享助力弹窗状态
   * @param {*} store
   * @param {*} data
   */
  setSourceInfo(store, data) {
    store.commit("SET_SOURCE_INFO", data);
  },
  setVipRouteInfo(store, data) {
    store.commit("SET_VIP_ROUTE_INFO", data);
  },
  setOrderRouteInfo(store, data) {
    store.commit("SET_ORDER_ROUTE_INFO", data);
  },
  /**
   * 设置分享助力弹窗状态
   * @param {*} store
   * @param {*} data
   */
  setDialogHelp(store, data) {
    store.commit("SET_DIALOG_HELP", data);
  },
  /**
   * 获取用户信息
   * 2021-12-16 18:18:39
   * @param store
   * @returns {Promise<void>}
   */
  async getUserInfo(store) {
    try {
      const { data } = await fetchUserInfo();
      store.dispatch("setUserInfo", data);
      return data;
    } catch (e) {
      console.log(e);
    }
  },
  /**
   * 设置用户信息
   * 2021-12-16 18:18:39
   * @param store
   * @param data
   * @returns {Promise<void>}
   */
  async setUserInfo(store, data) {
    setUserInfo(data);
    store.commit("SET_USER_INFO", data);
  },
  /**
   * 用户登录
   * 2021-12-16 18:18:39
   * @param store
   * @param params
   * @returns {Promise<void>}
   */
  async login(store, params) {
    return new Promise((resolve, reject) => {
      try {
        loginForUser(params).then(({ data }) => {
          setToken(data?.token || "");
          setUserId(data?.user_id || "");
          store.dispatch("setUserInfo", data);
          store.dispatch("closeLoginDialog");
          getCartList();
          resolve();
        }).catch(reject);
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });

  },
  /**
   * 退出登录
   */
  loginOut(store, path = "/") {
    loginOut();
    localStorage.removeItem("userInfo");
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    store.dispatch("setUserInfo", null);
    store.dispatch("music/changeMusicInfo", null, { root: true });
    store.dispatch("music/closeMusicControls", null, { root: true });

    // 判断如果当前页面在推广分享页面 则点击退出停留在当前页 否则回到首页
    const $route = router.app._route.path;
    if ($route !== "/activity/shareActive") {
      router.push(path);
    }
  },
  /**
   * 打开登录模态框
   * 2021-12-16 16:35:15
   * @param store
   */
  openLogin(store) {
    store.commit("SET_LOGIN_FORM_COMPONENT_NAME", loginName);
    if (document.body.clientWidth >= 980) {
      store.commit("SET_LOGIN_VISIBLE", true);
    } else {
      router.push({
        path: "/login",
        query: {
          redirect: router.app.$route.fullPath
        }
      });
    }
  },
  /**
   * 打开注册模态框 同样
   * 2021-12-16 16:35:15
   * @param store
   */
  openRegister(store) {
    store.commit("SET_LOGIN_FORM_COMPONENT_NAME", registerName);
    if (document.body.clientWidth >= 980) {
      store.commit("SET_LOGIN_VISIBLE", true);
    } else {
      router.push({
        path: "/login",
        query: {
          redirect: router.app.$route.fullPath
        }
      });
    }
  },
  /**
   * 切换是登录还是注册 还是忘记密码
   * 2021-12-16 18:15:52
   * @author SnowRock
   * @param store
   * @param name { 'LoginForm' | 'RegisterForm' | 'FindPwdForm' }
   */
  changeLoginFormComponentName(store, name) {
    store.commit("SET_LOGIN_FORM_COMPONENT_NAME", name);
  },
  /**
   * 关闭登录弹框
   * 2021-12-16 18:15:52
   * @author SnowRock
   * @param store
   */
  closeLoginDialog(store) {
    store.commit("SET_LOGIN_VISIBLE", false);
  },
  /**
   * 是否显示左侧注册即领取会员入口
   */
  setMemberstatus({ commit }, status) {
    commit("SET_MEMBER_STATUS", status);
  },
  updateMembershipExpiringList({ commit }, list) {
    commit("UPDATE_MEMBERSHIP_EXPIRING_LIST", list);
  },
  openExpiringDialog({ commit }) {
    commit("UPDATE_EXPIRING_DIALOG_VISIBLE", true);
  },
  closeExpiringDialog({ commit }) {
    commit("UPDATE_EXPIRING_DIALOG_VISIBLE", false);
  },
  openExpiredDialog({ commit }) {
    commit("UPDATE_EXPIRED_DIALOG_VISIBLE", true);
  },
  closeExpiredDialog({ commit }) {
    commit("UPDATE_EXPIRED_DIALOG_VISIBLE", false);
  },
  openRegisterSuccessDialog( { commit}){
    commit('UPDATE_REGISTER_DIALOG_VISIBLE', true)
  },
  closeRegisterSuccessDialog( { commit}){
    commit('UPDATE_REGISTER_DIALOG_VISIBLE', false)
  }
};

const mutations = {
  // 轮播下标
  UPDATE_SWIPER_INDEX(state, val){
    // console.log(val,'离开前的轮播下标')
    state.swiperIndex = val
  },
  UPDATE_EXPIRING_DIALOG_VISIBLE(state, visible) {
    state.expiringDialogVisible = visible;
  },
  UPDATE_EXPIRED_DIALOG_VISIBLE(state, visible) {
    state.expiredDialogVisible = visible;
  },
  UPDATE_MEMBERSHIP_EXPIRING_LIST(state, list) {
    state.membershipExpiringList = list;
  },
  SET_MEMBER_STATUS(state, status) {
    window.sessionStorage.setItem("memberStatus", status);
    state.showMember = status;
  },
  /**
   * 更新注册成功弹框状态
   * @param {*} state
   * @param {*} val
   */
  UPDATE_REGISTER_DIALOG_VISIBLE(state, val){
    state.registerSuccessDialogVisible = val;
  },
  // 设置登录模态框状态
  SET_LOGIN_VISIBLE(state, val) {
    state.dialogForLoginVisible = val;
  },
  SET_LOGIN_FORM_COMPONENT_NAME(state, val) {
    state.loginComponentsName = val;
  },
  SET_USER_INFO(state, userInfo) {
    state.userInfo = userInfo;
  },
  SET_DIALOG_HELP(state, val) {
    state.dialogForHelpVisible = val;
  },
  SET_SOURCE_INFO(state, val) {
    state.sourceInfo = val;
  },
  SET_VIP_ROUTE_INFO(state, val) {
    state.vipCheckDetailRouteInfo = val;
  },
  SET_ORDER_ROUTE_INFO(state, val) {
    state.orderCheckDetailRouteInfo = val;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
