export const HeaderMenuList = [
  {
    name: 'PLAYLIST',
    id: 0,
    path: '/'
  },
  {
    name: '音乐',
    id: 1,
    type: 2,
    // logo: 'https://qkodo.playlistmusic.com.cn/assets/common/202201/music_logo.png', // 展示的logo信息
    logo: require('@/assets/image/header-logo/music.png'), // 展示的logo信息
    path: '/music',
    searchPlaceholder: '全站资源每月只需29.9元'
  },
  {
    name: '音效',
    id: 2,
    type: 3,
    // logo: 'https://qkodo.playlistmusic.com.cn/assets/common/202201/sound-effect-logo_.png', // 展示的logo信息
    logo: require('@/assets/image/header-logo/vocal.png'), // 展示的logo信息
    path: '/soundEffect',
    searchPlaceholder: '请输入音效/标签名称'
  },
  {
    name: '视频',
    id: 3,
    type: 4,
    // logo: 'https://qkodo.playlistmusic.com.cn/assets/common/202201/viedeo_logo.png', // 展示的logo信息
    logo: require('@/assets/image/header-logo/video.png'),
    path: '/video',
    searchPlaceholder: '全站资源每月只需29.9元'
  },
  {
    name: '图片',
    id: 5,
    type: 5,
    logo: require('@/assets/image/header-logo/picture.png'),
    path: '/picture'
  },
  {
    name: 'AI语音',
    id: 4,
    // logo: 'https://qkodo.playlistmusic.com.cn/assets/common/202201/ai_voice_logo.png', // 展示的logo信息
    logo: require('@/assets/image/header-logo/AI.png'),
    path: '/aiVoice'
  },
  {
    name: '限免专区',
    id: 6,
    logo: require('@/assets/image/header-logo/picture.png'),
    path: '/limitExempt'
  },
]
export const autoRenewalCertificate =
  'https://qkodo.playlistmusic.com.cn/assets/common/Playlist%E8%87%AA%E5%8A%A8%E7%BB%AD%E8%B4%B9%E5%8D%8F%E8%AE%AE.pdf' // 订阅自动续费pdf协议
export const businessCertificate =
  'https://qkodo.playlistmusic.com.cn/default/2023-%E6%95%B0%E9%9F%B3%E6%9C%AA%E6%9D%A5%E5%A2%9E%E5%80%BC%E7%94%B5%E4%BF%A1%E8%AE%B8%E5%8F%AF%E8%AF%81.pdf' // 增值电信业务经营许可证
export const userProtocol =
  'https://qkodo.playlistmusic.com.cn/assets/common/playlist%E7%94%A8%E6%88%B7%E5%8D%8F%E8%AE%AE.pdf' // 用户协议
// 订阅服务协议(注册)
export const subscriptionServiceAgreement =
  'https://qkodo.playlistmusic.com.cn/playlist/playlist-20220421/PlayList%20%E8%AE%A2%E9%98%85%E6%9C%8D%E5%8A%A1%E5%8D%8F%E8%AE%AE%E6%A8%A1%E6%9D%BF-%E6%B3%A8%E5%86%8C.pdf'
// 订阅服务协议(签约)
export const subscriptionServiceAgreementQY =
  'https://qkodo.playlistmusic.com.cn/playlist/playlist-20220421/playlist%20%E8%AE%A2%E9%98%85%E6%9C%8D%E5%8A%A1%E5%8D%8F%E8%AE%AE%E6%A8%A1%E6%9D%BF5.pdf'
export const PrivacyProtocol =
  'https://qkodo.playlistmusic.com.cn/assets/common/PlayList%E9%9A%90%E7%A7%81%E5%8D%8F%E8%AE%AE.pdf' // 隐私协议
export const InfringementComplaintProtocol =
  'https://qkodo.playlistmusic.com.cn/assets/common/pdf/%E4%BE%B5%E6%9D%83%E7%94%B3%E8%AF%89%E6%8C%87%E5%BC%95.pdf' // 侵权申诉指引
export const InfringementComplaint =
  'https://qkodo.playlistmusic.com.cn/assets/common/%E4%BE%B5%E6%9D%83%E6%8A%95%E8%AF%89%E9%80%9A%E7%9F%A5%E4%B9%A6.pdf' // 侵权投诉通知书
export const CompanyIntroduction =
  'https://qkodo.playlistmusic.com.cn/assets/common/playlistyincixieyi.pdf' // 公司介绍
export const UserDisputeSettlement =
  'https://qkodo.playlistmusic.com.cn/playlist/playlist-20220421/yonghujiufenchuli.pdf' // 用户纠纷处理
export const MCT_GOV = 'https://jbts.mct.gov.cn/' // 全国举报平台
export const PDF_NETWORK_CULTURE_MANAGEMENT_LICENSE =
  'https://qkodo.playlistmusic.com.cn/playlist/playlist-20220421/%E6%95%B0%E9%9F%B3%E6%9C%AA%E6%9D%A5%E7%BD%91%E7%BB%9C%E6%96%87%E5%8C%96%E7%BB%8F%E8%90%A5%E8%AE%B8%E5%8F%AF%E8%AF%81.pdf' // 网文pdf
export const ICP_filing = 'http://www.beian.gov.cn/portal/index.do' // ICP备案
export const retailAuthTemplate = 'https://qkodo.playlistmusic.com.cn/assets/common/PlayList%20%E9%9F%B3%20%E4%B9%90%20%E9%9B%B6%20%E5%94%AE%20%E6%8E%88%20%E6%9D%83%20%E4%B9%A6.pdf'
export const payReloadTime = 2000 // 下单支付时刷新时长 ms 数
// 单个歌曲下载授权渠道
export const authChannelOptions = [
  { label: '抖音', value: '1' },
  { label: '微信', value: '2' },
  { label: '快手', value: '3' },
  { label: '喜马拉雅', value: '4' },
  { label: 'B站', value: '5' },
  { label: '微博', value: '7' },
  { label: '小红书', value: '8' },
  // { label: '公播', value: '9' },
  { label: '西瓜视频', value: '11' },
  { label: '火山小视频', value: '12' },
  { label: '淘宝直播', value: '13' },
  { label: '好看视频', value: '14' },
  { label: '蜻蜓FM', value: '15' },
  { label: '荔枝FM', value: '16' },
  { label: 'YouTuBe', value: '6' }
  // { label: '其他', value: '10' }
]
// 零售授权场景
export const authSceneOptions = [
  { sk_id: "A2009",sk_name: '线下用途' },
  { sk_id: "A2010",sk_name: '产品内置' },
  { sk_id: "A2011",sk_name: '商业广告/宣传片' },
  { sk_id: "A2012",sk_name: '线上用途' },
  { sk_id: "A2013",sk_name: '自媒体平台' }
]

export const orderList = [
  { value: '', label: '综合' },
  { value: 1, label: '最新' },
  { value: 2, label: '最热' }
]

export const memberMenus = [
  {
    name: '我的会员',
    un_icon: require('@/assets/svg/my-member-unselect.svg'),
    icon: require('@/assets/svg/myMember-select.svg'),
    path: '/member/center/equity',
    eventName: 'MyMember'
  },
  {
    name: '我的订单',
    un_icon: require('@/assets/svg/my-member-unselect.svg'),
    icon: require('@/assets/svg/myMember-select.svg'),
    path: '/member/center/order',
    eventName: 'MyOrder'
  },
  {
    name: '我的收藏',
    un_icon: require('@/assets/svg/my-collect-unselect.svg'),
    icon: require('@/assets/svg/myCollect-select.svg'),
    path: '/member/center/collect',
    eventName: 'Collect'
  },
  {
    name: '我的授权',
    un_icon: require('@/assets/svg/my-authorized-unselect.svg'),
    icon: require('@/assets/svg/myAuthorized-select.svg'),
    path: '/member/center/authorized-download',
    eventName: 'AuthorizedDownload'
  },
  {
    name: '发票管理',
    un_icon: require('@/assets/svg/my-invoice-unselect.svg'),
    icon: require('@/assets/svg/myInvoice-select.svg'),
    path: '/member/center/invoice',
    eventName: 'Receipt'
  },
  {
    name: '我的兑换',
    un_icon: require('@/assets/svg/conversion-unselect.svg'),
    icon: require('@/assets/svg/conversion-select.svg'),
    path: '/member/center/conversion',
    eventName: 'Conversion'
  }
]

// 全局限制播放次数开关
export const globalAudion = true

// 优秀博主
export const bloggerList = [
  {
    avatar: require('@/assets/image/up1.png'),
    name: '陈二狗chairgo',
    platform: '抖音'
  },
  {
    avatar: require('@/assets/image/up2.png'),
    name: '卢姥爷Laoye',
    platform: 'B站'
  },
  {
    avatar: require('@/assets/image/up3.png'),
    name: '剪极社',
    platform: 'B站'
  },
  {
    avatar: require('@/assets/image/up4.png'),
    name: 'LEO叔叔爱摄影',
    platform: 'B站'
  },
  {
    avatar: require('@/assets/image/up5.png'),
    name: 'aiyyo解说',
    platform: ' B站'
  },
  {
    avatar: require('@/assets/image/up6.png'),
    name: '胡萝卜',
    platform: '小红书'
  },
  {
    avatar: require('@/assets/image/up7.png'),
    name: '某科学的未来预测',
    platform: 'B站'
  },
  {
    avatar: require('@/assets/image/up8.png'),
    name: '酷炫摄影教学',
    platform: '抖音'
  },
  {
    avatar: require('@/assets/image/up9.png'),
    name: '费先生（摄影剪辑）pr',
    platform: '快手'
  },
  {
    avatar: require('@/assets/image/up10.png'),
    name: '剪辑白衬衫',
    platform: '抖音'
  },
  {
    avatar: require('@/assets/image/up11.png'),
    name: '影视人-赖宇飞',
    platform: '快手'
  },
  {
    avatar: require('@/assets/image/up12.png'),
    name: 'L叔Lionel',
    platform: '小红书'
  },
  {
    avatar: require('@/assets/image/up13.png'),
    name: '丁香花（剪辑教学)',
    platform: '抖音'
  },
  {
    avatar: require('@/assets/image/up14.png'),
    name: '影视后期素材',
    platform: '抖音'
  },
  {
    avatar: require('@/assets/image/up15.png'),
    name: '云（手机摄影)',
    platform: '抖音'
  },
  {
    avatar: require('@/assets/image/up16.png'),
    name: '芝士见长',
    platform: 'B站'
  }
]

// 博主视频
export const workList = [
  {
    workurl:
      'https://qkodo.playlistmusic.com.cn/default%2FB%E7%AB%99UPLEO%E5%8F%94%E5%8F%94%E7%88%B1%E6%91%84%E5%BD%B1-%E7%94%A8%E5%A4%B4%E5%83%8F%E5%81%9A%E4%B8%80%E4%B8%AA%E5%B1%9E%E4%BA%8E%E8%87%AA%E5%B7%B1%E7%9A%84%E5%8A%A8%E7%94%BB%E7%89%87%E5%A4%B4_1.mp4',
    creator: 'Leo叔叔爱摄影'
  },
  {
    workurl:
      'https://qkodo.playlistmusic.com.cn/default%2FB%E7%AB%99UP%E9%99%88%E4%BA%8C%E7%8B%97chairgo%E4%BB%8B%E7%BB%8D%E8%A7%86%E9%A2%91_1.mp4',
    creator: '陈二狗chairgo'
  },
  {
    workurl:
      'https://qkodo.playlistmusic.com.cn/public-common/playlist%E6%8E%A8%E5%B9%BF-%E6%97%A0%E7%89%87%E5%A4%B4%E6%9C%89%E5%BD%A9%E8%9B%8B%E2%80%94%E5%8E%8B%E7%BC%A9.mp4',
    creator: '卢姥爷Laoye'
  },
  {
    workurl:
      'https://qkodo.playlistmusic.com.cn/default%2FB%E7%AB%99UPLEO%E5%8F%94%E5%8F%94%E7%88%B1%E6%91%84%E5%BD%B1-PR45-%E6%96%87%E5%AD%97%E7%BA%BF%E6%9D%A1logo%E5%8A%A8%E7%94%BB%E7%89%87%E5%A4%B4_1.mp4',
    creator: 'Leo叔叔爱摄影'
  },
  {
    workurl:
      'https://qkodo.playlistmusic.com.cn/default%2FB%E7%AB%99UP%E5%8D%A2%E5%A7%A5%E7%88%B7Laoye%20pr-%E6%B0%B4%E6%B3%A2%E7%BA%B9%E5%AD%97%E5%B9%95-%E5%89%AA%E8%BE%91%E7%89%87_1.mp4',
    creator: '卢姥爷Laoye'
  },
  {
    workurl:
      'https://qkodo.playlistmusic.com.cn/public-common/aiyyo%E8%A7%A3%E8%AF%B4.mp4',
    creator: 'aiyyo解说'
  },
  {
    workurl:
      'https://qkodo.playlistmusic.com.cn/default%2FB%E7%AB%99%E5%8D%9A%E4%B8%BB%EF%BC%9A%E5%89%AA%E6%9E%81%E7%A4%BE.mp4',
    creator: '剪极社'
  },
  {
    workurl:
      'https://qkodo.playlistmusic.com.cn/default%2F%E6%8A%96%E9%9F%B3%E5%BF%AB%E6%89%8B%E5%8D%9A%E4%B8%BB%EF%BC%9A%E9%85%B7%E7%82%AB%E6%91%84%E5%BD%B1%E6%95%99%E5%AD%A6.mp4',
    creator: '酷炫摄影教学'
  },
  {
    workurl:
      'https://qkodo.playlistmusic.com.cn/default%2F%E8%B4%B9%E5%85%88%E7%94%9F%EF%BC%88%E6%91%84%E5%BD%B1%E5%89%AA%E8%BE%91%EF%BC%89pr.mp4',
    creator: '费先生'
  },
  {
    workurl:
      'https://qkodo.playlistmusic.com.cn/default%2F%E5%B0%8F%E7%BA%A2%E4%B9%A6%E5%8D%9A%E4%B8%BB_%E8%83%A1%E8%90%9D%E5%8D%9C.mp4',
    creator: '胡萝卜'
  },
  {
    workurl:
      'https://qkodo.playlistmusic.com.cn/default%2F%E5%89%AA%E8%BE%91%E7%99%BD%E8%A1%AC%E8%A1%AB%E6%8E%A8%E5%B9%BF%E8%A7%86%E9%A2%91%E5%8E%8B%E7%BC%A9%E6%96%87%E4%BB%B6.mp4',
    creator: '剪辑白衬衫'
  }
]

// 创作者活动公告
export const noticeList = [
  { text: '【陈二狗】在【B站】发布了新作品，迅速围观吧！' },
  { text: '【LEO叔叔爱摄影】在【B站】发布了新作品，迅速围观吧！' },
  { text: '【Lu姥爷】在【B站】发布了新作品，迅速围观吧！' },
  { text: '【王点点】在【抖音】发布了新作品，迅速围观吧！' },
  { text: '【胜哥】在【快手】发布了新作品，迅速围观吧！' },
  { text: '【一片叶子】在【B站】发布了新作品，迅速围观吧！' },
  { text: '【大山深处】在【B站】发布了新作品，迅速围观吧！' },
  { text: '【智慧交通】在【抖音】发布了新作品，迅速围观吧！' },
  { text: '【雪凉舞会】在【快手】发布了新作品，迅速围观吧！' },
  { text: '【付付得正】在【B站】发布了新作品，迅速围观吧！' },
  { text: '【强大的我】在【抖音】发布了新作品，迅速围观吧！' },
  { text: '【无敌的存在】在【B站】发布了新作品，迅速围观吧！' },
  { text: '【影视新手】在【B站】发布了新作品，迅速围观吧！' }
]

// 会员类型
export const memberType = [
  {
    label: '个人',
    value: 1
  },
  {
    label: '企业',
    value: 2
  }
]

// 授权书状态
export const authorizationStatus = [
  {
    label: '待激活',
    value: 1
  },
  {
    label: '已激活',
    value: 2
  }
]
