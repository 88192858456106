var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pl-guide",class:{
    'has-back-top': _vm.visible,
    [_vm.oftenClass]: _vm.oftenClass !== ''
  }},[_c('div',{staticClass:"pl-guide-container"},[_vm._m(0),_vm._m(1),_vm._m(2),_c('div',{staticClass:"guide-item help",on:{"click":function($event){return _vm.$emit('help')}}},[_c('img',{staticClass:"active",attrs:{"src":require("../assets/image/guide/help-active.png"),"alt":""}}),_c('img',{staticClass:"usual",attrs:{"src":require("../assets/image/guide/help.png"),"alt":""}}),_c('p',[_vm._v("帮助")])]),_c('div',{staticClass:"guide-item service"},[_c('img',{staticClass:"active",attrs:{"src":require("../assets/image/guide/service-active.png"),"alt":""}}),_c('img',{staticClass:"usual",attrs:{"src":require("../assets/image/guide/service.png"),"alt":""}}),_c('p',[_vm._v("客服")]),_c('div',{staticClass:"prompt-box service"},[_c('div',{staticClass:"robot-main-wrapper"},[_c('ul',[_vm._m(3),_vm._m(4),_c('li',[_vm._m(5),_c('div',{staticClass:"icon-content"},[_c('h4',[_vm._v("在线咨询 "),_c('span',{staticClass:"life-question",on:{"click":function($event){return _vm.$emit('help')}}},[_vm._v("常见问题>>")])]),_c('div',{staticClass:"flex cen-space"},[_vm._m(6),_c('div',{staticClass:"online-chat cursor-pointer",on:{"click":_vm.qimoChatClick}},[_vm._v(" 咨询客服 ")])])])])])])])])]),_c('el-backtop',{ref:"backTop",staticClass:"guid-back-top",attrs:{"bottom":100,"right":0}},[_c('div',{staticClass:"guide-item back-top"},[_c('el-icon',{attrs:{"name":"arrow-up"}}),_c('p',[_vm._v("返回")]),_c('p',[_vm._v("顶部")])],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"guide-item choose-music"},[_c('img',{attrs:{"src":require("../assets/image/guide/choose_music.png"),"alt":""}}),_c('p',[_vm._v("帮您")]),_c('p',[_vm._v("选曲")]),_c('div',{staticClass:"prompt-box choose-music"},[_c('div',{staticClass:"prompt-box-wrapper"},[_c('img',{staticClass:"qrcode",attrs:{"src":require("@/assets/image/guide/chat-qrcode/choose_music_code.png")}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"guide-item suggest"},[_c('img',{staticClass:"active",attrs:{"src":require("../assets/image/guide/suggest-active.png"),"alt":""}}),_c('img',{staticClass:"usual",attrs:{"src":require("../assets/image/guide/suggest.png"),"alt":""}}),_c('p',[_vm._v("加入")]),_c('p',[_vm._v("社群")]),_c('div',{staticClass:"prompt-box suggest"},[_c('div',{staticClass:"prompt-box-wrapper"},[_c('img',{staticClass:"qrcode",attrs:{"src":require("@/assets/image/guide/chat-qrcode/suggest_0725.png")}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"guide-item collect"},[_c('img',{staticClass:"active",attrs:{"src":require("../assets/image/guide/collect-active.png")}}),_c('img',{staticClass:"usual",attrs:{"src":require("../assets/image/guide/collect.png"),"alt":""}}),_c('p',[_vm._v("收藏")]),_c('div',{staticClass:"prompt-box collect"},[_c('div',{staticClass:"prompt-box-wrapper"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/image/guide/robot-phone-icon.png"),"alt":""}})]),_c('div',{staticClass:"icon-content"},[_c('h4',[_vm._v("客服热线")]),_c('p',{staticClass:"phone"},[_vm._v("135-2235-2793")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"qrcode-container"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/image/guide/qrcode-icon.png"),"alt":""}})]),_c('div',{staticClass:"icon-content flex"},[_c('div',[_c('h4',[_vm._v("扫码咨询")]),_c('p',{staticClass:"sub-title"},[_vm._v("获取专业咨询服务")])]),_c('div',[_c('img',{staticClass:"qrcode",attrs:{"src":require("@/assets/image/guide/wechat-icon.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/image/guide/online-answter.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"sub-title"},[_vm._v(" 工作日 "),_c('br'),_vm._v(" 09:30-18:30 ")])
}]

export { render, staticRenderFns }