/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import axios from 'axios';
import VAudio from '@/utils/VAudio';
// import * as api from '@/api';
// import { setDefaultOptions } from '@/common/utils.coffee';

const state = {
  isEnd: true,
  loading: true,
  isPlaying: false,
  isShow: false,
  waveProgress: 0,
  duration: 0,
  playList: [],
  playingMusic: {},
  audio: null,
  volume: 1,
  progressTimer: null
};

const mutations = {};

// const normalizationPreview = (str) => {
//   const exp = new RegExp(/^https:\/\/((www\.vfinemusic)|(vfine\.oss-cn-beijing\.aliyuncs))\.com\//);

//   return str.replace(exp, '');
// };

const actions = {
  setVolumeByBarToItem({ state }, val) {
    state.audio.volume = val
  },

  play({ state, dispatch }, music) {
    // console.log('play',state.audio, state.playingMusic,music);
    if (state.playingMusic.music_id === music.music_id) {
      state.isPlaying = true;

      return state.audio.play();
    }
    return dispatch('stop').then(() => dispatch('initAudio', music));
  },

  pause({ state }) {
    state.loading = false;
    state.isPlaying = false;

    if (state.audio) {
      state.audio.pause();
    }
  },

  stop({ state }) {
    state.loading = false;
    state.isPlaying = false;
    state.playingMusic = {};
    if (!state.audio) {
      return;
    }
    state.audio.pause();
    state.audio.currentTime = 0;
    delete state.audio;
  },

  hide({ state }) {
    state.isShow = false;
  },

  show({ state }) {
    state.isShow = true;
  },

  setProgress({ state }, lastCurrentTime) {
    setTimeout(() => {
      if (state.audio) {
        state.audio.currentTime = lastCurrentTime;
        state.isPlaying = true;
        state.audio.play()
      }
    }, 200);
  },

  record({ state }, music) {
    const musicIndex = state.playList.findIndex(item => item.music_id === music.music_id);

    if (musicIndex !== -1) {
      state.playList.splice(musicIndex, 1);
    }

    state.playList.push(music);
  },

  async initAudio({ state, dispatch }, music) {
    if(state.audio) return
    state.playingMusic = music;
    state.isPlaying = true;
    state.loading = true;

    state.waveProgress = 0;

    const audio = new VAudio();
    // const auditionUrlPromise = await api.getAuditionUrl({
    //   path: normalizationPreview(music.preview)
    // });
    let url = music.watermark_file
    // audio.currentSrc = auditionUrlPromise.data && auditionUrlPromise.data.preview_url;
    audio.currentSrc = url
    state.isEnd = false
    audio.onerror = () => {
      axios.get(audio.src)
        .catch(({ response }) => {
          switch (response && response.status) {
            case 404:
            case 410:
              state.playingMusic = {};
              dispatch('play', music);
              break;
            default:
              break;
          }
        });
    };

    audio.load();
    audio.play();

    setTimeout(() => {
      dispatch('record', music);
    }, 800);

    clearInterval(state.progressTimer);

    audio.addEventListener('loadedmetadata', () => {
      state.duration = audio.duration;
      state.loading = false;
    });

    audio.addEventListener('ended', () => {
      state.isEnd = true
      state.loading = false;
      state.isPlaying = false;
    });

    state.audio = audio;
    state.audio.volume = state.volume
    state.progressTimer = setInterval(() => {
      if (!state.isPlaying) {
        return false;
      }
      state.waveProgress = audio.currentTime / audio.duration * 100;
      return true;
    }, 300);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
