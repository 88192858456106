!function (doc, win) {
    var docEl = doc.documentElement;
    var docs = win.document;
    var metaEl = docs.querySelector('meta[name="viewport"]'),
    // resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
      recalc = function () {
        var clientWidth = docEl.clientWidth;
        if (!clientWidth) return;
        if (clientWidth >= 750) {
          docEl.style.fontSize = '16px';
        } else {
          docEl.style.fontSize = 100 * (clientWidth / 750) + 'px';
          metaEl.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no');
        }
      };
    if (!doc.addEventListener) return;
    win.addEventListener('resize', recalc, false);
    doc.addEventListener('DOMContentLoaded', recalc, false);
}(document, window);