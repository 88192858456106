const AppMain = () => import('@/views/index')

export default {
  path: '/package',
  name: 'Package',
  component: AppMain,
  redirect: '/package/page',
  children: [
    {
      path: '/package/page',
      name: 'Package',
      meta: {
        isLogin: true,
        pageTitle: '会员订阅-PlayList素材网站',
        content: {
          description: '正版素材会员订阅，PlayList提供更优惠的正版素材授权服务，涵盖音乐素材、音效、视频素材、图片素材、AI语音转换，海量正版素材畅选,满足各类个人、商用素材需求。',
          keywords: 'playlist,playlist素材,素材网站,素材库,素材购买,版权音乐,音效,视频素材,图片素材,商用素材,音乐购买网站'
        }
      },
      component: () => import('@/views/package/index.vue')
    }
  ]
}
