import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'

Vue.use(Vuex)

const moduleFiles = require.context('./modules', true, /\.js$/)

const modules = moduleFiles.keys().reduce((modules, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = moduleFiles(modulePath)
  // 如果default为空则跳过当前循环
  if (value.default) {
    // 判断传入的路由是否为数组，是则合并数组，否则直接添加进路由集合中
    modules[moduleName] = value.default
  }
  return modules
}, {})

export default new Vuex.Store({
  modules,
  getters
})
