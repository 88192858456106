import axios from 'axios'
import store from '@/store'
import { getToken } from '@/utils/author'
import { Message } from 'element-ui' 
import qs from 'qs'
import { debounce } from '@/utils/utils'
import router from '../router'
const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL
})
let axiosNum = 0

instance.interceptors.request.use(  
  (config) => {
    // 统一配置header信息
    const token = getToken()
    if (token) {
      config.headers['token'] = token
    }
    config.headers['content-type'] = 'application/x-www-form-urlencoded'

    config.data = qs.stringify(config.data)
    // 记录登录次数
    axiosNum++
    return config
  },
  (err) => {
    return Promise.reject(err)
  }
)

instance.interceptors.response.use(
  (res) => {
    axiosNum--
    return handleResponse(res)
  },
  (err) => {
    axiosNum--
    return handleResponseErr(err)
  }
)

export const loginOut = debounce(() => {
  store.dispatch('user/loginOut')
}, 1000)
const messageError = debounce((info) => {
  Message.error(info)
}, 1000)

function handleResponse(response) {
  // console.log(response)
  let data = response.data
  let ua = navigator.userAgent;
  if (response.status !== 200) {
    // throw new Error(data.msg || '服务器错误')
  }

  // 服务器返回错误
  if (data && data.code && data.code.toString() !== '0') {
    // 登录已过期 ...
    if (data.code >= 2001 && data.code < 2006) {
      // if(response.request.responseURL.indexOf('/fontend/AIVoice/toVoice') > -1) {
      //   if(data.code == -1){
      //     router.push({path: '/package/page'})
      //   }
      //   store.dispatch('user/openLogin')
      // }
      // console.log(response.request.responseURL.indexOf('/fontend/user/shareUserList'))
      if(!ua.match(/(iPhone|iPad|Android|ios)/i)){
        if(response.request.responseURL.indexOf('/fontend/user/shareUserList') == -1){
          loginOut()
        }
      }else{
        router.push({ path: '/login' });  
      }
    }
    console.log(axiosNum)
    if(data.code !== 5271 || data.code !== 5251){
      if(!ua.match(/(iPhone|iPad|Android|ios)/i)){
        messageError(data.msg || '服务器错误')
        throw new Error(data.msg || '服务器错误') 
      }
    }
  }
  return data
}

function handleResponseErr(err) {
  if (err && err.response) {
    switch (err.response.status) {
      case 400:
        err.message = '错误请求'
        break
      case 401:
        err.message = '未授权，请重新登录'
        break
      case 403:
        err.message = '拒绝访问'
        break
      case 404:
        err.message = '请求错误,未找到该资源'
        break
      case 405:
        err.message = '请求方法未允许'
        break
      case 408:
        err.message = '请求超时'
        break
      case 500:
        err.message = '服务器端出错'
        break
      case 501:
        err.message = '网络未实现'
        break
      case 502:
        err.message = '网络错误'
        break
      case 503:
        err.message = '服务不可用'
        break
      case 504:
        err.message = '网络超时'
        break
      case 505:
        err.message = 'http版本不支持该请求'
        break
      default:
        err.message = `连接错误${err.response.status}`
    }
  } else if (err.toString() !== 'Cancel') {
    err.message = '连接到服务器失败'
  }
  console.warn(err.message)
  return err
}

export default instance
