import request from '@/utils/request'

// 获取消息列表
export function fetchMessageList(params) {
  return request.post('/fontend/msg/list', params, {})
}

// 获取消息数量
export function featchMessagCount(params) {
  return request.post('/fontend/msg/count', params, {})
}
// 标记已读
export function MessageRead(params) {
  return request.post('/fontend/msg/read', params, {})
}
// 批量标记已读
export function MessageAllRead(params) {
  return request.post('/fontend/msg/batch-read', params, {})
}

// 删除消息
export function deleteMessage(params) {
  return request.post('/fontend/msg/del', params, {})
}
// 批量删除消息
export function deleteAllMessage(params) {
  return request.post('/fontend/msg/batch-del', params, {})
}
