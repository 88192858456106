const AppMain = () => import('@/views/index')

export default {
  path: '/search',
  name: 'Search',
  component: AppMain,
  redirect: '/search-result',
  children: [
    {
      path: '/search-result',
      name: 'SearchResult',
      meta:{
        keepAlive: false
      },
      component: () => import('@/views/search/result.vue')
    }
  ]
}
