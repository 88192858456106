<template>
  <el-form
    ref="findPwdForm"
    :model="findPwdForm"
    :inline-message="true"
    :rules="findPwdRules"
    class="login-form-container"
  >
    <el-form-item prop="account">
      <div class="files-content">
        <span class="svg-container">
          <img src="@/assets/svg/phone-login-icon.svg" alt="" />
        </span>
        <el-input
          ref="account"
          v-model.trim="findPwdForm.account"
          placeholder="请输入手机号/邮箱"
          name="account"
          type="text"
          tabindex="1"
          autocomplete="on"
        />
      </div>
    </el-form-item>
    <el-form-item prop="smsCode">
      <div class="flex">
        <div class="files-content">
          <span class="svg-container">
            <img src="@/assets/svg/sms-icon.svg" alt="" />
          </span>
          <el-input
            ref="smsCode"
            v-model="findPwdForm.smsCode"
            autocomplete="on"
            name="text"
            placeholder="请输入验证码"
            tabindex="2"
            @keyup.enter.native="handleLogin"
          />
        </div>
        <el-button
          v-if="inSendSmsReady"
          class="send-sms-code"
          style="margin-left: 16px"
          @click="sendMsgForAccount"
          >获取验证码</el-button
        >
        <el-button v-else class="send-sms-code" style="margin-left: 16px">{{
          `${time}秒`
        }}</el-button>
      </div>
    </el-form-item>
    <el-form-item prop="password" style="margin-bottom: 16px">
      <div class="files-content">
        <span class="svg-container">
          <img src="@/assets/svg/password-icon.svg" alt="" />
        </span>
        <el-input
          :key="passwordType"
          ref="password"
          v-model="findPwdForm.password"
          :type="passwordType"
          autocomplete="on"
          name="password"
          placeholder="请输入新密码"
          tabindex="2"
          @keyup.enter.native="handleFindPwd"
        />
        <span class="view-password-container">
          <img
            v-if="passwordType === 'password'"
            src="@/assets/svg/not-view.svg"
            alt=""
            @click="passwordType = 'text'"
          />
          <img
            v-else
            src="@/assets/svg/view.svg"
            alt=""
            @click="passwordType = 'password'"
          />
        </span>
      </div>
    </el-form-item>
    <el-form-item prop="validPassword" style="margin-bottom: 16px">
      <div class="files-content">
        <span class="svg-container">
          <img src="@/assets/svg/password-icon.svg" alt="" />
        </span>
        <el-input
          :key="validPasswordType"
          ref="validPassword"
          v-model="findPwdForm.validPassword"
          :type="validPasswordType"
          autocomplete="on"
          name="validPassword"
          placeholder="再次输入新密码"
          tabindex="2"
          @keyup.enter.native="handleFindPwd"
        />
        <span class="view-password-container">
          <img
            v-if="validPasswordType === 'password'"
            src="@/assets/svg/not-view.svg"
            alt=""
            @click="validPasswordType = 'text'"
          />
          <img
            v-else
            src="@/assets/svg/view.svg"
            alt=""
            @click="validPasswordType = 'password'"
          />
        </span>
      </div>
    </el-form-item>
    <el-button
      :loading="loading"
      type="login"
      style="width: 100%; margin-bottom: 8px"
      @click.native.prevent="handleFindPwd"
    >
      提交
    </el-button>
    <!-- 登录提示 -->
    <p class="findPwd-tips">
      已有账号，<el-button type="text" @click="handleLogin">直接登录</el-button>
    </p>
  </el-form>
</template>

<script>
import CountDown from '@/layout/mixins/CoutDown.js'
import { validAccount, validPhone } from '@/utils/validate'
import { userProtocol } from '@/config'
import { findPwdUser } from '@/api/user'
import { sendSmsForEmail, sendSmsForPhone } from '@/api/common'

export default {
  name: 'FindPwdForm',
  data() {
    const validateAccount = (rule, value, callback) => {
      if (!validAccount(value)) {
        callback(new Error('请输入正确的邮箱号或者手机号'))
      } else {
        callback()
      }
    }
    const validPassword = (rule, value, callback) => {
      const { validPassword } = this.findPwdForm
      if (!validPassword) {
        this.$refs.findPwdForm.validateField('password')
        callback()
      } else if (value !== validPassword) {
        callback(new Error('两次密码输入不一致'))
      } else {
        callback()
      }
    }
    return {
      userProtocol,
      findPwdForm: {
        account: '',
        smsCode: '',
        password: '',
        validPassword: ''
      },
      findPwdRules: {
        account: [
          { required: true, trigger: 'blur', validator: validateAccount }
        ],
        validPassword: [
          { required: true, trigger: 'blur', validator: validPassword }
        ],
        nickname: [
          { required: true, message: '请输入用户名称', trigger: 'blur' }
        ],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        smsCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
      },
      passwordType: 'password',
      validPasswordType: 'password',
      loading: false,
      redirect: undefined,
      transaction_id: undefined, // 验证码凭证
      otherQuery: {}
    }
  },
  watch: {
    $route: {
      handler: function (route) {
        const query = route.query
        if (query) {
          this.redirect = query.redirect
          this.otherQuery = this.getOtherQuery(query)
        }

        // store.dispatch('user/openLogin')
      },
      immediate: true
    }
  },
  mixins: [CountDown],
  methods: {
    /**
     * 处理找回密码
     * 2021-12-16 15:06:37
     * @author SnowRock
     */
    handleFindPwd() {
      this.$refs.findPwdForm.validate(async (valid) => {
        if (!valid) return
        this.loading = true
        // 提交表单验证信息
        const { transaction_id } = this
        const {
          account,
          smsCode,
          password,
          validPassword: password_two
        } = this.findPwdForm
        // 将数据变为接口所需要的数据
        const params = {
          account,
          password,
          password_two,
          verify_code: smsCode,
          transaction_id
        }
        try {
          await findPwdUser(params)
          this.$message.success('找回密码成功！')
          this.handleLogin()
        } catch (e) {
          console.log(e)
        }
        this.loading = false
      })
    },
    /**
     * 发送短信验证码
     * 2021-12-17 10:20:254
     * @author SnowRock
     * @returns {Promise<void>}
     */
    async sendMsgForAccount() {
      this.$refs.findPwdForm.validateField('account', async (valid) => {
        if (!(valid === '')) return
        const { account } = this.findPwdForm
        const fn = validPhone(account) ? sendSmsForPhone : sendSmsForEmail
        const params = validPhone(account)
          ? { phone: account, sms_tpl: 1 }
          : { email: account, email_tpl: 1 }
        try {
          const data = await fn(params)
          this.transaction_id = data.data.transaction_id
          this.countDownTime(true)
        } catch (e) {
          console.log(e)
        }
      })
    },
    /**
     * 处理跳转登录
     * 2021-12-16 16:07:58
     * @author SnowRock
     */
    handleLogin() {
      this.$emit('changeComponent', 'LoginForm')
    },
    /**
     * 展示密码
     * 2021-12-16 14:47:03
     * @author SnowRock
     */
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    /**
     * 获取其他的url 携带参数
     * @param query
     * @returns {{}}
     */
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur]
        }
        return acc
      }, {})
    }
  }
}
</script>

<style lang="scss" scoped>
@import './login-form';

.login-form-container {
  .findPwd-tips {
    text-align: left;
    margin-bottom: 3px;
  }
  /*  /deep/ {

    .is-success {
      .el-input__suffix {

      }
    }
    .el-input__suffix {
      content: '';
      &>* {
        display: none;
      }
    }
  }*/
}
</style>
