import { formatDate } from '@/utils/utils'

/**
 * 全局时间转化
 * @param data
 * @param format
 * @returns {string|*}
 */
export function parseDate(data, format) {
  return formatDate(data, format)
}

/**
 * 时间转化为 HMS格式
 * @param value
 * @returns {string}
 */
export function formatTimeBySecond(value) {
  let h = 60 * 60
  let m = 60
  let arr = [h, m]
  let final = []

  arr.forEach((item) => {
    let divi = Math.floor(value / item)

    // value = Math.round(value % item);
    value = Math.floor(value % item)

    divi && final.push(`${divi}`.padStart(2, '0'))
  })

  final.push(`${value ? value : '0'}`.padStart(2, '0'))

  final.length <= 1 && final.unshift('00')

  return final.join(':')
}

export function transitionValToLabel(val, list){
  const item = list.filter((i) => i.value === val)[0]
  return item?.label || '-'
}
