/**
 * 添加搜索参数
 */
export const $goSearch = function (params) {
  let query = this.$route.query

  params = Object.assign({}, query, params)

  this.$router.push({
    query: params
  })
}
