<template>
  <el-form
    ref="loginForm"
    :model="loginForm"
    :inline-message="true"
    :rules="loginRules"
    class="login-form-container"
  >
    <!-- 账号密码登录区域 -->
    <div v-if="isAccountPwdLogin" class="login-for-account-pwd">
      <el-form-item prop="account">
        <div class="files-content">
          <span class="svg-container">
            <img src="@/assets/svg/phone-login-icon.svg" alt="" />
          </span>
          <el-input
            ref="account"
            v-model.trim="loginForm.account"
            placeholder="请输入手机号或邮箱"
            name="account"
            type="text"
            tabindex="1"
            autocomplete="on"
          />
        </div>
      </el-form-item>

      <el-form-item prop="password">
        <div class="files-content">
          <span class="svg-container">
            <img src="@/assets/svg/password-icon.svg" alt="" />
          </span>
          <el-input
            :key="passwordType"
            ref="password"
            v-model="loginForm.password"
            :type="passwordType"
            autocomplete="on"
            name="password"
            placeholder="请输入密码"
            tabindex="2"
            @keyup.enter.native="handleLogin"
          />
          <span class="view-password-container">
            <img
              v-if="passwordType === 'password'"
              src="@/assets/svg/not-view.svg"
              alt=""
              @click="passwordType = 'text'"
            />
            <img
              v-else
              src="@/assets/svg/view.svg"
              alt=""
              @click="passwordType = 'password'"
            />
          </span>
        </div>
      </el-form-item>
      <el-form-item class="tips-container">
        <div class="files-content">
          <el-button type="text-gary" @click="loginType = 'phone'"
            >验证码登录</el-button
          >
          <el-button type="text-gary" @click="handleFindPwd"
            >忘记密码</el-button
          >
        </div>
      </el-form-item>
    </div>
    <!-- 手机号登录区域 -->
    <div v-if="isPhoneLogin" class="login-for-phone">
      <el-form-item prop="account">
        <div class="files-content">
          <span class="svg-container">
            <img src="@/assets/svg/phone-login-icon.svg" alt="" />
          </span>
          <el-input
            ref="account"
            v-model.trim="loginForm.account"
            placeholder="请输入手机号或邮箱"
            name="account"
            type="text"
            tabindex="1"
            autocomplete="on"
          />
        </div>
      </el-form-item>

      <el-form-item prop="smsCode">
        <div class="flex">
          <div class="files-content">
            <span class="svg-container">
              <img src="@/assets/svg/sms-icon.svg" alt="" />
            </span>
            <el-input
              ref="smsCode"
              v-model.trim="loginForm.smsCode"
              autocomplete="on"
              name="text"
              placeholder="请输入验证码"
              tabindex="2"
              @keyup.enter.native="handleLogin"
            />
          </div>
          <el-button
            v-if="inSendSmsReady"
            class="send-sms-code"
            style="margin-left: 16px"
            @click="sendMsgForAccount"
            >获取验证码</el-button
          >
          <el-button v-else class="send-sms-code" style="margin-left: 16px">{{
            `${time}秒`
          }}</el-button>
        </div>
      </el-form-item>

      <el-form-item class="tips-container">
        <div class="files-content">
          <el-button type="text-gary" @click="loginType = 'account'"
            >账号密码登录</el-button
          >
          <el-button type="text-gary" @click="handleFindPwd"
            >忘记密码</el-button
          >
        </div>
      </el-form-item>
    </div>
    <!-- 登录功能 -->
    <el-button
      :loading="loading"
      type="login"
      style="width: 100%; margin-bottom: 8px"
      @click.native.prevent="handleLogin"
    >
      登录
    </el-button>
    <!-- 登录提示 -->
    <p class="register-tips">
      还没有账号，<el-button type="text" @click="handleRegister"
        >快去注册</el-button
      >
    </p>
    <!-- 渠道登录 -->
    <!--    <p class="channels-login-container">
      使用社交账号登录
      <el-icon name="qq" />
      <el-icon name="wx" />
      <el-icon name="weibo" />
    </p>-->
  </el-form>
</template>

<script>
import CountDown from '@/layout/mixins/CoutDown.js'
import { validAccount, validPhone } from '@/utils/validate'
import { sendSmsForEmail, sendSmsForPhone } from '@/api/common'
import { send_eventApi } from '@/utils/common.js'
import { mapGetters } from 'vuex'
import { getStorageUserId, getStorageH5shareId } from '@/utils/sessionStroge.js'
import { loginForUser } from '@/api/user'
import {
  setTempDeviceId,
  setToken,
  setUserId,
  setUserInfo
} from '@/utils/author'
import { checkedExpiringMembership } from '@/utils/operate'

export default {
  name: 'LoginForm',
  data() {
    const validateAccount = (rule, value, callback) => {
      if (!validAccount(value)) {
        callback(new Error('请输入正确的邮箱号或者手机号'))
      } else {
        callback()
      }
    }
    return {
      loginForm: {
        account: '',
        password: '',
        smsCode: '',
        phone: ''
      },
      loginRules: {
        account: [
          { required: true, trigger: 'blur', validator: validateAccount }
        ],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        smsCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
      },
      passwordType: 'password',
      loading: false,
      redirect: undefined,
      otherQuery: {},
      loginType: 'phone',
      transaction_id: null // 验证码凭据
    }
  },
  computed: {
    isAccountPwdLogin() {
      return this.loginType === 'account'
    },
    isPhoneLogin() {
      return this.loginType === 'phone'
    },
    ...mapGetters({
      userInfo: 'userInfo'
    })
  },
  watch: {
    $route: {
      handler: function (route) {
        const query = route.query
        if (query) {
          this.redirect = query.redirect
          this.otherQuery = this.getOtherQuery(query)
        }
      },
      immediate: true
    },
    loginType: {
      handler: function () {
        this.transaction_id = null
        this.$refs.loginForm.resetFields()
      },
      immediate: false
    }
  },
  mixins: [CountDown],
  methods: {
    /**
     * @description 获取来源信息
     */
    getSourceInfo() {
      if (JSON.stringify(this.$route.query) === '{}') {
        this.$store.dispatch('user/setSourceInfo', null)
      } else {
        this.$store.dispatch('user/setSourceInfo', this.$route.query)
      }
    },
    /**
     * 处理登陆
     * 2021-12-16 11:14:44
     * @author SnowRock
     */
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true
          const { loginType, transaction_id } = this
          const { account, password, smsCode } = this.loginForm
          // 处理登录参数
          // 账号密码登录或者是验证码登录
          const info = {
            account: 1,
            phone: 2
          }
          const params = {
            login_type: info[loginType],
            account
          }
          // 设置渠道来源入参信息
          this.setSourceParams(params)
          // 账号密码登录
          if (loginType === 'account') {
            params.password = password
          } else {
            params.verify_code = smsCode
            params.transaction_id = transaction_id
          }
          const ua = navigator.userAgent
          // // 判断页面是移动端还是pc端
          if (ua.match(/(iPhone|iPad|Android|ios)/i)) {
            loginForUser(params)
              .then((res) => {
                if (res.code == 0) {
                  setToken(res.data?.token || '')
                  setUserId(res.data?.user_id || '')
                  setUserInfo(res.data)
                  const obj = {
                    page_url: window.location.href,
                    login_date: res.data.login_time,
                    reg_date: res.data.create_at,
                    user_name: res.data.name,
                    user_id: res.data.user_id
                  }
                  send_eventApi('e_login', obj)
                  setTempDeviceId()
                  // checkedExpiringMembership()
                  // 设置登录用户id
                  window.gio('setUserId', res.data.user_id)
                  this.$router.go(-1);
                  this.loading = false
                } else {
                  alert('登录失败，账号或者密码错误')
                  this.loading = false
                }
              })
              .catch((error) => {
                console.log(error)
              })
            // 判断是否为被分享用户 只有被分享用户登录或者注册才会显示助力弹窗
            if (getStorageH5shareId()) {
              this.$store.dispatch('mobile/setDialogHelpstatus', true)
            }
          } else {
            this.$store
              .dispatch('user/login', params)
              .then(() => {
                if (this.redirect) {
                  this.$router.push({
                    path: this.redirect || '/',
                    query: this.otherQuery
                  })
                }
                // 判断是否为被分享用户 只有被分享用户登录或者注册才会显示助力弹窗
                if (getStorageUserId()) {
                  this.$store.dispatch('user/setDialogHelp', true)
                }
                const obj = {
                  page_url: window.location.href,
                  login_date: this.userInfo.login_time,
                  reg_date: this.userInfo.create_at,
                  user_name: this.userInfo.name,
                  user_id: this.userInfo.user_id
                }
                this.$store.dispatch('message/updateMessageCount')
                send_eventApi('e_login', obj)
                setTempDeviceId()
                checkedExpiringMembership()
                // 设置登录用户id
                window.gio('setUserId', this.userInfo.user_id)
                this.loading = false
              })
              .catch(() => {
                this.loading = false
              })
          }
        } else {
          return false
        }
      })
    },
    setSourceParams(params) {
      this.getSourceInfo()
      const ua = navigator.userAgent
      let info = this.$store.state.user.sourceInfo
      const defaultInfo = {
        utm_source: 'PlayList',
        utm_medium: '',
        utm_campaign: '',
        utm_content: '',
        utm_term: ''
      }
      if (info) {
        info = Object.assign(defaultInfo, info)
      }
      if (ua.match(/(iPhone|iPad|Android|ios)/i)) {
        info ? (info['utm_terminal'] = 2) : (defaultInfo['utm_terminal'] = 2)
      } else {
        info ? (info['utm_terminal'] = 1) : (defaultInfo['utm_terminal'] = 1)
      }
      if (info) {
        params.utm = JSON.stringify(info)
      } else {
        params.utm = JSON.stringify(defaultInfo)
      }
    },
    /**
     * 发送短信验证码
     * 2021-12-17 10:20:254
     * @author SnowRock
     * @returns {Promise<void>}
     */
    async sendMsgForAccount() {
      this.$refs.loginForm.validateField('account', async (valid) => {
        if (!(valid === '')) return
        const { account } = this.loginForm
        const fn = validPhone(account) ? sendSmsForPhone : sendSmsForEmail
        const params = validPhone(account)
          ? { phone: account, sms_tpl: 1 }
          : { email: account, email_tpl: 1 }
        try {
          const data = await fn(params)
          this.transaction_id = data.data.transaction_id
          this.countDownTime(true)
        } catch (e) {
          console.log(e)
        }
      })
    },
    /**
     * 处理跳转登录
     * 2021-12-16 16:07:58
     * @author SnowRock
     */
    handleRegister() {
      this.$emit('changeComponent', 'RegisterForm')
    },
    /**
     * 处理打开忘记密码
     * 2021-12-16 16:07:58
     * @author SnowRock
     */
    handleFindPwd() {
      this.$emit('changeComponent', 'FindPwdForm')
    },
    /**
     * 展示密码
     * 2021-12-16 14:47:03
     * @author SnowRock
     */
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    /**
     * 获取其他的url 携带参数
     * @param query
     * @returns {{}}
     */
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur]
        }
        return acc
      }, {})
    }
  }
}
</script>

<style lang="scss" scoped>
@import './login-form';
</style>
