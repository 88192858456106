<template>
  <div class="envelope" @click="setProgress">
    <canvas ref="canvasBackground" />

    <div class="canvas-container" :style="{ width: playedProgress + '%' }">
      <canvas ref="canvas" />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import { getWavedata } from '@/api/common.js'

// import filter from '@/filter';

export default {
  name: 'Wave',
  props: {
    music: {
      type: Object,
      required: true
    },
    fillColor: {
      type: String,
      default: '#E52A0D'
    },
    backgroundColor: {
      type: String,
      default: 'rgba(224, 224, 224)'
    },
    spacing: {
      type: Number,
      default: 4
    },
    fillWidth: {
      type: Number,
      default: 1
    }
  },

  data() {
    return {
      playedProgress: 0,
      spectrumData: [],
      devicePixelRatio: Math.ceil(window.devicePixelRatio)
    };
  },

  computed: Object.assign(
    mapState('music', [
      'isPlaying',
      'waveProgress',
      'duration',
      'playingMusic'
    ])
  ),

  watch: {
    music: {
      handler(newVal, oldVal) {
        if (!oldVal || newVal.music_id !== oldVal.music_id) {
          this.init()
        }

      },
      immediate: true
    },

    waveProgress(newValue) {
      const vm = this;
      const { music, playingMusic } = vm;
      if (music.music_id === playingMusic.music_id && newValue !== 0) {
        this.playedProgress = newValue;
      }
    }
  },

  mounted() {
    const vm = this;

    // vm.init();
    window.addEventListener('resize', () => {
      vm.initCanvas();
      vm.initCanvasBackground();
    }, false);
  },

  methods: {
    init() {
      const vm = this;
      if (!vm.music.music_id) {
        return false;
      }

      return vm.getWavedata()
        .then(() => {
          vm.initCanvasBackground();
          vm.initCanvas();
          // vm.$emit('waveRenderOver')
        });
    },

    getElLeft(el) {
      const elRect = el.getBoundingClientRect();
      return elRect.left;
    },

    setProgress(e) {
      const pos = (e.clientX - this.getElLeft(this.$el)) / this.$el.clientWidth * 100;
      return this.$emit('waveProgress', pos);
    },

    async getWavedata() {
      const param = {
        music_ids: this.music.music_id
      }
      const data = await getWavedata(param);
      let waveurl = data.data.waveformhera
      if (data.code == 0 && waveurl) {
        const res = await axios.get(waveurl, {})
        this.spectrumData = res.data.filter((item, index) => (index % this.spacing) === 0);
      } else {
        this.spectrumData = [];
      }
    },
    async getData() {
      let envelopeUrl = ''
      if(!this.music.envelope){
        const {data: {data: {exists, envelope}}} = await this.$axios.GetAudioWaveForm(this.music.music_id)
        envelopeUrl = envelope
        if(!exists || !envelope){
          this.$emit('waveRenderOver')
        }
      }

        return axios.get(this.music.envelope || envelopeUrl)
        .then(({ data }) => {
          this.spectrumData = data.filter((item, index) => (index % this.spacing) === 0);
        });
    },

    initCanvas() {
      const vm = this;
      const { $refs: { canvas } } = vm;

      if (!canvas) {
        return false;
      }
      canvas.width = vm.$el.clientWidth * vm.devicePixelRatio;
      canvas.height = vm.$el.clientHeight * vm.devicePixelRatio;
      canvas.style.width = `${vm.$el.clientWidth}px`;
      canvas.style.height = `${vm.$el.clientHeight * 0.75}px`;
      return vm.drawSpectrum();
    },

    initCanvasBackground() {
      const vm = this;
      const { $refs: { canvasBackground } } = vm;

      if (!canvasBackground) {
        return false;
      }
      canvasBackground.width = vm.$el.clientWidth * vm.devicePixelRatio;
      canvasBackground.height = vm.$el.clientHeight * vm.devicePixelRatio;
      canvasBackground.style.width = `${vm.$el.clientWidth}px`;
      canvasBackground.style.height = `${vm.$el.clientHeight * 0.75}px`;
      return vm.drawSpectrum2();
    },

    fillRoundedRect(ctx, x, y, width, height, radius) {
      if (!ctx) {
        return false;
      }
      if (height < radius) {
        radius = height;
      }
      ctx.beginPath();
      ctx.moveTo(x + radius, y);
      ctx.arcTo(x + width, y, x + width, y + height, radius);
      ctx.arcTo(x + width, y + height, x, y + height, radius);
      ctx.arcTo(x, y + height, x, y, radius);
      ctx.arcTo(x, y, x + width, y, radius);
      // ctx.stroke();
      ctx.fill();
      return ctx.closePath();
    },

    drawSpectrum() {
      const vm = this;
      const {
        spectrumData, $refs, fillWidth, devicePixelRatio
      } = vm;

      const ctx = $refs.canvas.getContext('2d');

      ctx.fillStyle = this.fillColor;
      ctx.strokeStyle = this.fillColor;
      const maxNum = Math.max.apply(null, spectrumData);
      const p = $refs.canvas.height / maxNum;
      const lineWidth = $refs.canvas.width / spectrumData.length;

      spectrumData.forEach((item, index) => {
        const width = fillWidth * devicePixelRatio;
        const height = item * p;
        const x = lineWidth * index;
        const y = $refs.canvas.height / 2 - height / 2;
        const radius = (fillWidth - 1) * devicePixelRatio;
        vm.fillRoundedRect(ctx, x, y, width, height, radius);
      });
    },

    drawSpectrum2() {
      const vm = this;
      const {
        spectrumData, $refs, fillWidth, devicePixelRatio, backgroundColor
      } = vm;

      const ctx = $refs.canvasBackground.getContext('2d');
      ctx.fillStyle = backgroundColor;
      ctx.strokeStyle = backgroundColor;
      const maxNum = Math.max.apply(null, spectrumData);
      const p = $refs.canvasBackground.height / maxNum;
      const lineWidth = $refs.canvasBackground.width / spectrumData.length;

      spectrumData.forEach((item, index) => {
        const width = fillWidth * devicePixelRatio;
        let height = item * p;
        if (height && height < 6) {
          height = 6;
        }
        const x = lineWidth * index;
        const y = $refs.canvasBackground.height / 2 - height / 2;
        const radius = (fillWidth - 1) * devicePixelRatio;
        vm.fillRoundedRect(ctx, x, y, width, height, radius);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
  //en
  .envelope {
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;
    user-select: none;

    .gap-gutter {
      position: absolute;
      top: calc(50% - 2px);
      right: 0;
      height: 2px;
      background-color: #f5f5f5;
      left: 0;
      z-index: 102;
    }

    .played-time {
      z-index: 102;
      width: 44px;
      height: 22px;
      position: absolute;
      bottom: -24px;
      font-size: 14px;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      color: #333333;
      background-color: transparent;
      text-align: center;
      line-height: 22px;

      // &::before {
      //   content: '';
      //   display: block;
      //   border-left: 10px solid transparent;
      //   border-top: 10px solid transparent;
      //   border-right: 10px solid transparent;
      //   border-bottom: 10px solid transparent;
      //   height: 2px;
      //   position: absolute;
      //   top: -20px;
      //   right: 6px;
      // }
    }
    canvas {
      position: absolute;
      z-index: 1;
      display: block;
      transform: translateY(12.5%);
    }

    .canvas-container {
      position: absolute;
      top: 0;
      bottom: 0;
      // background-color: #333333;
      z-index: 2;
      overflow: hidden;
      border-radius: 4px;

      canvas {
        z-index: 2;
      }
    }
  }
</style>
