

export default [
  {
    path: '/login',
    name: 'login',
    meta: {
      isLogin: false,
      hasMember: false
    },
    component: () => import('@/views/login/index.vue')
  }
]
