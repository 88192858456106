import { mapState, mapActions } from 'vuex';

export default {
  computed: {
    ...mapState('music', [
      'isPlaying',
      'duration',
      'playingMusic',
      'waveProgress',
      'audio',
      'isEnd'
    ]),
    music() {
      return this.$store.state.music.tmpMusicInfo
    }
  },

  watch: {
    playingMusic(newValue) {
      const vm = this;
      const { music } = vm;
      if (music.music_id === newValue.music_id) {
        vm.setProgress(0 * newValue.duration / 100);
      }
    }
  },

  methods: {
    ...mapActions('music', [
      'setProgress',
      'musicStop',
      'musicPlay',
      'musicPause',
      'setVolumeByBarToItem'
    ]),

    onPlayOrPause() {
      // console.log('onPlayOrPause');
      const vm = this;
      const { music, isPlaying, playingMusic } = vm;
      // console.log(isPlaying, playingMusic,music);
      if(isPlaying){
        if(playingMusic.music_id === music.music_id){
          return vm.musicPause(music);
        }else {
          vm.musicStop()
          return vm.musicPlay(music);
        }
      }else {
        return vm.musicPlay(music);
      }
    }
  }
};
