import { addGoodsToCart, collectInfoById, shareInfoById } from '@/utils/operate'
import { mapGetters } from 'vuex'
import { getTempDeviceId, getToken } from '@/utils/author'
import { send_eventApi } from '@/utils/common.js'
import { GOODS_TYPE } from '@/enum/common'
import { formDateTime } from '@/utils/formDate.js'
import { copyInfo } from '@/utils/utils'
import { fetchPlayTimes } from '@/api/music'
import { globalAudion } from '@/config'
import { fetchUserVips } from '@/api/user'
import { downloadInfoById } from '@/utils/operate'
export default {
  inject: ['reload'],
  computed: {
    ...mapGetters(['hasMember', 'userInfo']),
    musicInfo() {
      return this.$store.state.music.tmpMusicInfo
    }
  },
  methods: {
    /**
     * 打开分享模态框
     * 2021-12-20 15:47:38
     * @author SnowRock
     */
    async handleShare(link, id, mode, name) {
      // 分享埋点
      const obj = {
        m_type:
          mode == 1
            ? 'AI语音'
            : mode == 2
            ? '音乐'
            : mode == 3
            ? '音效'
            : mode == 4
            ? '视频'
            : '图片',
        m_name: name,
        mid: id,
        user_name: this.userInfo ? this.userInfo.name : '',
        user_id: this.userInfo ? this.userInfo.user_id : ''
      }
      send_eventApi('e_share', obj)
      // console.log(mode)
      name = encodeURI(name)
      let shareUrl = ''
      if (mode == 1) {
        shareUrl = `${window.location.origin}/aiVoice`
        await shareInfoById(id, mode, shareUrl)
      }else if (mode == 2) {
        shareUrl = `${window.location.origin}/music-detail/${id}`
        copyInfo(shareUrl)
        this.$message.success('已复制该链接到剪切板，快去分享吧！')
      } else if (mode == 4) {
        shareUrl = `${window.location.origin}/video/detail?videoId=${id}`
        copyInfo(shareUrl)
        this.$message.success('已复制该链接到剪切板，快去分享吧！')
      } else {
        shareUrl = `${window.location.origin}/share?music_id=${id}&type=${mode}`
        copyInfo(shareUrl)
        this.$message.success('已复制该链接到剪切板，快去分享吧！')
      }
    },
    /**
     * 收藏
     * 2021-12-21 09:48:54
     * @author SnowRock
     * @param id
     * @param mode 类型：2:音乐 3:音效 4:视频
     * @param info 音乐数据
     * @param info.is_collected 收藏参数 J
     * @param info.is_collect 收藏参数 Z
     * @param reload 是否刷新页面
     */
    async handleCollect(id, mode, info, reload = true, isReturn = false) {
      if (!(getToken() && getToken() !== '')) {
        this.$store.dispatch('user/openLogin')
        return
      }
      if (typeof info.is_collected === 'undefined') {
        info.is_collected = info.is_collect || 0
      }
      const { is_collected, is_collect } = info
      const collectedStatus = is_collected || is_collect || 0
      await collectInfoById(id, mode, { ...info })
      if (isReturn) {
        this.$message.success(
          collectedStatus === 1 ? '取消收藏成功！' : '收藏成功'
        )
      } else {
        this.$message.success(
          collectedStatus === 1 ? '收藏成功！' : '取消收藏成功'
        )
      }
      const obj = {
        m_type:
          mode == 1
            ? 'AI语音'
            : mode == 2
            ? '音乐'
            : mode == 3
            ? '音效'
            : '视频',
        m_name: info.name,
        mid: id,
        user_name: this.userInfo ? this.userInfo.name : '',
        user_id: this.userInfo ? this.userInfo.user_id : ''
      }
      send_eventApi('e_collect', obj)
      if (reload) {
        this.reload()
      }
    },

    /**
     * 触发搜索信息
     * 2021-12-23 17:24:19
     * @author SnowRock
     */
    handleSearch({ keyword_name, type, page }) {
      if (!keyword_name) {
        this.$router.push({ path: '/package/page' })
        return
      }
      const { path, query } = this.$route
      // console.log(path)
      if (path === '/music') {
        type = 2
      } else if (path === '/video') {
        type = 4
      } else if (path === '/picture') {
        type = 5
      }
      const searchPages = ['/search-result']
      const route = {
        query: {
          ...query,
          page,
          type,
          keyword_name
        }
      }
      // console.log(this.userInfo)
      const obj = {
        search_name: keyword_name ? keyword_name : '无值',
        search_type:
          Object.values(GOODS_TYPE).filter((i) => i.value + '' === type + '')[0]
            ?.label || '',
        page_url: window.location.href,
        user_name: this.userInfo ? this.userInfo.name : '',
        user_id: this.userInfo ? this.userInfo.user_id : ''
      }
      send_eventApi('e_search', obj)
      // 搜索结果页面
      if (!searchPages.includes(path)) {
        route.path = '/search-result'
      }
      this.$router.push(route)
      this.$nextTick(() => {
        this.reload()
      })
    },
    /**
     * 处理菜单点击时的操作
     * 2021-12-16 10:05:34
     * @author SnowRock
     * @param eventName
     * @param path
     * @param target
     * @param arg
     */
    handleMenuClick({ eventName, path, target = '_self', ...arg }) {
      this.active = eventName
      // 如果有地址则自动跳转到对应地址上
      if (path && path !== '') {
        // 点击我的会员需要保存去详情之前的浏览记录
        if (path === '/member/center/equity') {
          this.$router.push({
            path: path,
            query: this.$store.state.user.vipCheckDetailRouteInfo
          })
          return
        } else {
          this.$store.dispatch('user/setVipRouteInfo', null)
        }
        // 如果是http的链接则自动跳转至对应页面
        if (path.includes('http')) {
          window.open(path, target)
        } else {
          if (target === '_blank') {
            const newPath = this.$router.resolve({
              path
            })
            window.open(newPath.href, target)
          } else {
            this.$router.push({
              path
            })
          }
        }
      }
      this.$emit('onMenuClick', { eventName, path, target, ...arg })
    },
    /**
     * 下载
     * 2021-12-21 09:48:54
     * @author SnowRock
     * @param info 入参信息
     * @param type { 'single' | 'batch'} 下载类型
     */
    async handleDownload(info, type = 'single') {
      if (!(getToken() && getToken() !== '')) {
        this.$store.dispatch('user/openLogin')
        return
      }
      if (!info) {
        throw new Error('下载的内容不是一个有效的对象或者数组')
      }
      if (!this.hasMember) {
        this.$router.push('/package')
        return
      }
      const list = Array.isArray(info) ? [...info] : [info]
      // 打开下载模态框
      await this.$store.dispatch('download/changeChoosePackageVisible', true)
      await this.$store.dispatch('download/changeDownloadType', type)
      await this.$store.dispatch('download/changeDownloadList', list)

      let obj = {}
      const types = {
        1: 'AI语音',
        2: '音乐',
        3: '音效',
        4: '视频',
        5: '图片'
      }
      if (Array.isArray(info)) {
        const infos = info.map((i) => ({
          m_type: types[i.type] || '',
          slt_voice: '',
          op_date: formDateTime(),
          m_name: i.name,
          mid: i.object_id,
          user_name: this.userInfo ? this.userInfo.name : '',
          user_id: this.userInfo ? this.userInfo.user_id : ''
        }))
        infos.forEach((i) => {
          send_eventApi('e_download', i)
        })
        // for(var i=0; i<info.length; i++){
        //   obj = {
        //     m_type: info[i].type == 1 ? 'AI语音' : info[i].type == 2 ? '音乐' : info[i].type == 3 ? '音效' : '视频',
        //     slt_voice: '',
        //     op_date: formDateTime(),
        //     m_name: info[i].name,
        //     mid: info[i].object_id,
        //     user_name: this.userInfo ? this.userInfo.name : '',
        //     user_id: this.userInfo ? this.userInfo.user_id : ''
        //   }
        //   send_eventApi('e_download', obj)
        // }
      } else {
        obj = {
          // m_type: info.type == 1 ? 'AI语音' : info.type == 2 ? '音乐' : info.type == 3 ? '音效' : '视频',
          m_type: types[info.type] || '',
          slt_voice: '',
          op_date: formDateTime(),
          m_name: info.name,
          mid: info.object_id,
          user_name: this.userInfo ? this.userInfo.name : '',
          user_id: this.userInfo ? this.userInfo.user_id : ''
        }
        send_eventApi('e_download', obj)
      }
    },

    async handleDownLoadForFree(info){
      if (!(getToken() && getToken() !== '')) {
        this.$store.dispatch('user/openLogin')
        return
      }
      if (!info) {
        throw new Error('下载的内容不是一个有效的对象或者数组')
      }
      // 如已登录，判断是否有3次下载的免费会员
      const res = await fetchUserVips({is_free: true})
      if(res.code != 0) throw new Error(res.msg)
      let freeVip = res.data?.length && res.data.filter(i=> i.is_free == 1) || []
      if(freeVip && freeVip.length && freeVip[0].down_count_remain){
        // 有，无水印音频下载至本地，消耗一次下载次数
        this.$message.error({
          customClass: 'customfreemessage',
          message: '您正在使用免费会员下载该素材'
        })
        const { object_id, type } = info
        await downloadInfoById(object_id, type, freeVip[0].openrecord_id)
      }else {
        // 无，判断是否有常规正常购买的会员 如无会员，弹窗提示
        if (!this.hasMember) {
          this.show()
        }else{
          //有可使用会员，无水印音频下载至本地，消耗当前会员一次下载次数；
          const list = Array.isArray(info) ? [...info] : [info]
          // 打开下载模态框
          await this.$store.dispatch('download/changeChoosePackageVisible', true)
          await this.$store.dispatch('download/changeDownloadType', 'single')
          await this.$store.dispatch('download/changeDownloadList', list)
          let obj = {}
          const types = {
            1: 'AI语音',
            2: '音乐',
            3: '音效',
            4: '视频',
            5: '图片'
          }
          // 埋点
          obj = {
            // m_type: info.type == 1 ? 'AI语音' : info.type == 2 ? '音乐' : info.type == 3 ? '音效' : '视频',
            m_type: types[info.type] || '',
            slt_voice: '',
            op_date: formDateTime(),
            m_name: info.name,
            mid: info.object_id,
            user_name: this.userInfo ? this.userInfo.name : '',
            user_id: this.userInfo ? this.userInfo.user_id : ''
          }
          send_eventApi('e_download', obj)
        }
      }

    },
    async handleCheckedTimes(type, object_id) {
      // console.log('播放次数限制')
      const vm = this
      return new Promise((resolve) => {
        // 添加限制播放次数拦截
        if (globalAudion) {
          const tempDeviceId = getTempDeviceId()
          const params = {
            device_id: tempDeviceId, // 设备唯一码
            type: type == 1 ? 2 : 1 // 1音乐音效视频 2ai语音
          }
          if (Number(type) !== 1) {
            params.object_id = object_id
          }
          fetchPlayTimes(params).then((res) => {
            const {
              code,
              data: { data }
            } = res
            //  console.log(code, data)
            if (code !== 0 || !data) {
              vm.$store.dispatch('music/closeMusicControls')
              vm.$store.dispatch('music/pause')
              vm.$store.dispatch('music/musicPause')
              const token = getToken()
              // 如果未登录用户试听则进入登录页面
              if (!token) {
                vm.$store.dispatch('user/openLogin')
                resolve(false)
                return
              }

              if (!vm.$store.state.user?.userInfo?.vip_info?.is_vip) {
                // 如果试听30次后无会员再次点击播放跳转至开通会员列表
                vm.$router.push(`/package/page`)
                resolve(false)
                return
              }
              resolve(true)
              return
            }

            resolve(true)
          })
        } else {
          resolve(true)
        }
      })
    },
    /**
     * 播放全部音乐
     * @param type 类型：2:音乐/音效 1. ai语音
     *
     */
    async handlePlayerAll(info, type) {
      if (info) {
        const res = await this.handleCheckedTimes(type, info.object_id)
        if (!res) return
      }

      await this.$store.dispatch('music/changeMusicInfo', info)
      await this.$store.dispatch('music/musicPlay', info)

      const obj = {
        m_type:
          type == 1
            ? 'AI语音'
            : type == 2
            ? '音乐'
            : type == 3
            ? '音效'
            : '视频',
        m_name: info.name,
        mid: info.object_id,
        user_name: this.userInfo ? this.userInfo.name : '',
        user_id: this.userInfo ? this.userInfo.user_id : ''
      }
      send_eventApi('e_play', obj)
    },
    /**
     * 播放音乐
     * 2021-12-21 09:48:54
     * @author SnowRock
     * @param info
     // * @param mode 类型：2:音乐 3:音效 4:视频
     */
    async handlePlayerMusic(info) {
      // AI语音转化时记录次数 播放时不记录次数
      if (info.type !== 1) {
        const musicInfo = this.musicInfo
        if (
          !musicInfo ||
          !(musicInfo && musicInfo.object_id === info.object_id)
        ) {
          const res = await this.handleCheckedTimes(info.type, info.object_id)
          if (!res) return
        }
      }
      // 处理音乐信息
      if (!info.labels) {
        info.labels = info.label
      }
      if (!info.is_collected) {
        info.is_collected = info.is_collect
      }
      await this.$store.dispatch('music/updateOrderList', info)
      await this.$store.dispatch('music/changeMusicInfo', info)

      // if(info.type == 2) {
        await this.$store.dispatch('music/musicPlay', info)
      // }
      // else await this.$store.dispatch('music/play')
      const obj = {
        m_type:
          info.type == 1
            ? 'AI语音'
            : info.type == 2
            ? '音乐'
            : info.type == 3
            ? '音效'
            : '视频',
        m_name: info.name,
        mid: info.object_id,
        user_name: this.userInfo ? this.userInfo.name : '',
        user_id: this.userInfo ? this.userInfo.user_id : ''
      }
      send_eventApi('e_play', obj)
    },
    /**
     * 添加商品至购物车
     * 2021-12-21 09:48:54
     * @author SnowRock
     * @param info
     * @param info.object_id 商品id
     * @param info.type 商品类型 类型：1：AI语音 2:音乐 3:音效 4:视频
     */
    async handleAddGoodsToCart(info, type) {
      let object_type = type || info.type
      const obj = {
        m_type:
          object_type == 1
            ? 'AI语音'
            : object_type == 2
            ? '音乐'
            : object_type == 3
            ? '音效'
            : '视频',
        m_name: info.name,
        mid: info.object_id,
        user_name: this.userInfo ? this.userInfo.name : '',
        user_id: this.userInfo ? this.userInfo.user_id : ''
      }
      send_eventApi('e_cart', obj)

      if (!(getToken() && getToken() !== '')) {
        this.$store.dispatch('user/openLogin')
        return
      }
      // if (!this.hasMember) {
      //   this.$router.push('/package')
      //   return
      // }
      try {
        // const object_type = type || info.type
        const infos = await addGoodsToCart(info.object_id, object_type)
        if (!infos) {
          this.$message.success('已加入购物车！')
        }
      } catch (e) {
        console.log(e)
      }
    },
    // 限免专区下载事件
    async handleLimitExemptDownload(info, type = 'single') {
      if (!(getToken() && getToken() !== '')) {
        this.$store.dispatch('user/openLogin')
        return
      }
      if (!info) {
        throw new Error('下载的内容不是一个有效的对象或者数组')
      }
      if (!this.hasMember) {
        this.$router.push('/package')
        return
      }
      const list = Array.isArray(info) ? [...info] : [info]
      // 打开下载模态框
      await this.$store.dispatch('download/changeChoosePackageVisible', true)
      await this.$store.dispatch('download/changeDownloadType', type)
      await this.$store.dispatch('download/changeDownloadList', list)

      let obj = {}
      const types = {
        1: 'AI语音',
        2: '音乐',
        3: '音效',
        4: '视频',
        5: '图片'
      }
      if (Array.isArray(info)) {
        const infos = info.map((i) => ({
          m_type: types[i.type] || '',
          slt_voice: '',
          op_date: formDateTime(),
          m_name: i.name,
          mid: i.object_id,
          user_name: this.userInfo ? this.userInfo.name : '',
          user_id: this.userInfo ? this.userInfo.user_id : ''
        }))
        infos.forEach((i) => {
          send_eventApi('e_download', i)
        })
        // for(var i=0; i<info.length; i++){
        //   obj = {
        //     m_type: info[i].type == 1 ? 'AI语音' : info[i].type == 2 ? '音乐' : info[i].type == 3 ? '音效' : '视频',
        //     slt_voice: '',
        //     op_date: formDateTime(),
        //     m_name: info[i].name,
        //     mid: info[i].object_id,
        //     user_name: this.userInfo ? this.userInfo.name : '',
        //     user_id: this.userInfo ? this.userInfo.user_id : ''
        //   }
        //   send_eventApi('e_download', obj)
        // }
      } else {
        obj = {
          // m_type: info.type == 1 ? 'AI语音' : info.type == 2 ? '音乐' : info.type == 3 ? '音效' : '视频',
          m_type: types[info.type] || '',
          slt_voice: '',
          op_date: formDateTime(),
          m_name: info.name,
          mid: info.object_id,
          user_name: this.userInfo ? this.userInfo.name : '',
          user_id: this.userInfo ? this.userInfo.user_id : ''
        }
        send_eventApi('e_download', obj)
      }
    },
  }
}
