<template>
  <el-dialog :visible="dialogVisible" class="dialog-self" width="560px" :destroy-on-close="true" append-to-body
    :before-close="handleCancel">
    <div class="dialog-title-self" slot="title">
      <div class="title">选择扣除方式</div>
    </div>
    <el-form ref="choosePackageForm" :model="choosePackageForm" :rules="choosePackageRules" label-width="100px"
      size="small">
      <div class="tips-container">请选择您下载素材使用的套餐扣除方式</div>
      <el-form-item label="可选择" prop="packageId" style="">
        <el-radio-group v-model="choosePackageForm.packageId" style="line-height: 36px; margin-top: 3px">
          <el-radio v-for="(i, j) in packages" :name="i.openrecord_id" :key="`package-${j}`" :value="i.openrecord_id"
            :label="i.openrecord_id">
            {{ i.goods_type == 1 ? '个人' + i.goods_name : '企业' + i.goods_name }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <div class="tips-container" style="font-size: 12px;">
        <el-icon class="icon" name="warning" style="vertical-align: middle" />
        <span style="vertical-align: middle">请在作品投放及会员到期前激活授权书，没有激活授权书的素材不可使用。</span>
      </div>
    </el-form>
    <div slot="footer">
      <el-button type="dark" @click="handleCancel">取消</el-button>
      <el-button type="primary" @click="handleSubmitInfo">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {
  batchDownloadInfoByIds,
  downloadInfoById,
  getCartList
} from '@/utils/operate'
import { fetchUserVips } from '@/api/user'
export default {
  name: 'DialogForChoosePackage',
  data() {
    return {
      choosePackageForm: {
        packageId: ''
      },
      packages: [],
      choosePackageRules: {
        packageId: [
          { required: true, message: '请选择套餐扣除方式', trigger: 'change' }
        ]
      },
      flag: true
    }
  },
  computed: {
    dialogVisible() {
      return this.$store.state.download.dialogVisibleForChoosePackage
    },
    downloadList() {
      return this.$store.state.download.downloadList
    },
    downloadType() {
      return this.$store.state.download.downloadType
    }
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.getVipsList()
      } else {
        this.$nextTick(() => {
          this.packages = []
        })
      }
    }
  },
  methods: {
    /**
     * 获取会员列表
     * 2021-12-22 16:13:56
     * @author SnowRock
     */
    getVipsList() {
      fetchUserVips().then((res) => {
        const { data } = res
        this.packages = data;
      })
    },
    /**
     * 处理关闭弹框
     * 2021-12-21 17:41:04
     * @author SnowRock
     */
    handleCancel() {
      const dispatch = this.$store.dispatch
      dispatch('download/changeChoosePackageVisible', false)
    },
    /**
     * 提交下载信息 不管点多少次 只下载一次
     * 2021-12-21 17:26:34
     * @author SnowRock
     */
    handleSubmitInfo() {
      const dispatch = this.$store.dispatch
      dispatch('download/changeChoosePackageVisible', true)
      let that = this;
      if (this.flag) {
        this.flag = false;
        setTimeout(function () {
          that.flag = true;
        }, 500)
        this.handleDownload();
      }
    },
    handleDownload() {
      const vm = this
      const dispatch = this.$store.dispatch
      vm.$refs.choosePackageForm.validate(async (valid) => {
        if (!valid) return
        const { packageId } = this.choosePackageForm
        const { packages, downloadList, downloadType } = this
        const [packageInfo] = packages.filter(
          (i) => i.openrecord_id === packageId
        )
        const { down_count, down_num } = packageInfo
        const info = {
          dayCount: down_num,
          packageCount: down_count
        }
        // 如果日下载次数不为 n 并且日下载次数大于0
        if (down_num !== 'n') {
          // 打开下载提示框并且配置参数内容
          if (Number(down_num) <= 0) {
            dispatch('download/openDownloadTipsByDayOver', info)
            return
          }
          if (Number(down_num) < downloadList.length) {
            dispatch('download/openDownloadTipsByFullDay', info)
            return
          }
        }
        // 总下载次数限制
        if (down_count !== 'n') {
          if (Number(down_count) <= 0) {
            dispatch('download/openDownloadTipsByPackageOver', info)
            return
          }
          if (Number(down_count) < downloadList.length) {
            dispatch('download/openDownloadTipsByFullPackage', info)
            return
          }
          // 下载提示语内容
        }
        // 下载次数限制文案：
        // 1、当日下载次数使用完：今日下载数量已达上限，可明天继续下载或联系客服（点击直接弹出客服小助手）。
        // 2、会员下载次数使用完：您套餐内下载次数已消耗完毕，可重新购买套餐（可点击，直接跳转到套餐)
        // const params = {
        //   packageId
        // }
        try {
          const list = [...this.downloadList]
          this.$store.dispatch('download/recordCartCommodityID', this.downloadList)
          // await choosePackageForDownload(params)
          if (downloadType === 'single') {
            const { object_id, type } = list[0]
            await downloadInfoById(object_id, type, packageId)
          } else {
            const cart_ids = list.map((i) => i.cart_id)
            await batchDownloadInfoByIds(cart_ids, packageId)
          }
          this.handleCancel()
          await getCartList()
        } catch (e) {
          console.log(e)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.tips-container {
  color: rgba(51, 51, 51, 0.8);
  padding-left: 32px;
  margin-bottom: 10px;

  .icon {
    color: #ff8e14;
    font-size: 16px;
    margin-right: 8px;
  }
}
</style>
