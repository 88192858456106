<template>
  <div class="content">
    <el-dialog
        class="dialog-for-register"
        :visible.sync="dialogVisible"
        :class="device"
        width="600px"
        top="20vh"
        :destroy-on-close="true"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
    <div slot="title">
        注册成功，欢迎加入Playlist
    </div>
    <div class="info-success">
        <p>PlayList，视频工作者素材平台</p>
        <p>现仅需29.9元，音乐、视频、音效、AI语音正版优质资源全站畅享。</p>
        <p>素材持续更新，先到先得，快快行动吧！</p>
    </div>
    <div class="btns-container">
      <el-button type="empty" @click="handleBeforeClose">我知道了</el-button>
      <el-button type="primary" @click="handleBuyPackage">开通会员</el-button>
    </div>
  </el-dialog>
  </div>
</template>

<script>
export default {
    name: "DialogForRegisterSuccess",
    computed:{
        dialogVisible(){
            return this.$store.state.user.registerSuccessDialogVisible
        },
        device() {
            return this.$store.state.user.device
        },
    },
    methods:{
        handleBeforeClose(){
            this.$store.dispatch('user/closeRegisterSuccessDialog')
        },
        /**
         * 开通会员
         */
        handleBuyPackage(){
            this.$store.dispatch('user/closeRegisterSuccessDialog')
            this.$router.push({ path: '/package/page' })
        }
    }
}
</script>

<style lang="scss" scoped>
/deep/ {
    .el-dialog__header {
      padding: 23px 36px 0px;
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
      line-height: 28px;
    }

    .el-dialog__body {
      padding: 60px;
      padding-top: 18px;
      padding-bottom: 18px;
    }
    .info-success{
        p{
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 28px;   
        }
    }

    .el-button {
      padding: 0 24px;
      height: 36px;
      line-height: 36px;
      vertical-align: top;
      box-sizing: border-box;
    }
    .el-button--empty {
      border: 1px solid #E52A0D;
      border-radius: 2px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #E52A0D;
    }
    .btns-container {
        text-align: right;
        margin: 49px 0px 33px 0px;
        .el-button{
            margin: 0px 9px;
        }
    }
}

@media screen and (max-width: 768px){
    /deep/.el-dialog {
        width: 76.6667vw!important;
        font-size: .32rem;
        border-radius: .24rem;
        background-image: url("./assets/bg-expired.png");
        background-size: 100% auto;
        background-position: 0 -0.1vw;
        background-repeat: no-repeat;
        margin-top: calc(50vh - 50%)!important;

        &:before {
          width: 100%;
          content: '温馨提示';
          display: block;
          position: absolute;
          top: 6.7vw;
          text-align: center;
          font-size: 5.3vw;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
        }

        .el-dialog__header {
          font-size: 4.2vw;
          padding: 1.76rem .3rem 0rem .3rem;
        }

        .info-success{
            p{
                font-size: .28rem;
                font-family: PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: .48rem;
            }
        }
        .el-dialog__body {
            padding: .1rem .3rem .4rem .3rem;
            line-height: 5.3vw;
        }

        .btns-container {
          text-align: center;
          padding-top: 5.1vw;
          margin: 0;
          .el-button {
            width: 26.666vw;
            height: 10.6vw;
            line-height: 10.2vw;
            border-radius: 0.533vw;
            font-size: 4vw;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            text-align: center;
            padding: 0;
            &+.el-button {
              margin-left: 5.3vw;
            }
            &.el-button--empty {
              background: #F5F6FA;
              border-color: #F5F6FA;
              color: #999999;
            }
          }
        }
      }
}
</style>