/**
 * 通用模块api
 * 2021-12-02 17:26:48
 * @author SnowRock
 */

import request from '@/utils/request'
import { downloadByLink } from '@/utils/utils'

/**
 * 登录短信验证码
 * 2021-12-16 18:05:20
 * @param params
 * @param params.phone 手机号
 * @param params.sms_tpl 验证码模板 默认填 1
 * @author SnowRock
 */
export function sendSmsForPhone(params) {
  return request.post('/fontend/captcha/sms', params, {})
}

/**
 * 邮箱发送验证码
 * 2021-12-16 18:09:09
 * @param params
 * @param params.email 邮箱
 * @param params.email_tpl 验证码模板 默认填 1
 * @author SnowRock
 */
export function sendSmsForEmail(params) {
  return request.post('/fontend/captcha/email', params, {})
}

/**
 * 获取完整的地址
 * 2021-12-20 11:22:42
 * @author SnowRock
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getFileUrlPvt(params) {
  return request.post('/fontend/upload/getFileUrlPvt', params, {})
}

/**
 * 用户操作
 * 2021-12-20 11:23:51
 * @author SnowRock
 * @param params
 * @param params.mode 行为模式：2:音乐 3:音效 4:视频 5:歌单
 * @param params.type 行为类型：1:收藏 2:分享 3:试听 4:下载
 * @param params.object_id 操作Id
 * @returns {Promise<AxiosResponse<any>>}
 */
export function operationInfoByTypeAndId(params) {
  return request.post('/fontend/deed/save', params, {})
}

/**
 * 获取下载链接
 * 2021-12-21 17:45:58
 * @author SnowRock
 */
export function fetchDownloadLink(params) {
  return request.post('/fontend/certificate/download', params, {})
}

/**
 * 获取批量下载的链接
 * 2021-12-23 16:28:27
 * @author SnowRock
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function fetchBatchDownloadLink(params) {
  return request.post('/fontend/certificate/batchDownload', params, {})
}

/**
 * 下载授权
 * 2021-12-27 11:17:22
 * @author SnowRock
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function downloadAuth(params) {
  downloadByLink(
    `${process.env.VUE_APP_BASE_URL}/fontend/license/license/${params.license_id}`
  )
}

/**
 * 获取波形音乐数据
 */
export function getWavedata(params){
  return request.post('/fontend/music/waveform', params)
}

// 零售音乐下载

export function fetchRetailDownloadLink(params) {
  return request.post('/fontend/retail/order-download', params, {})
}
