<template>
  <el-dialog
    class="dialog-self dialog-for-copy-link"
    :visible="dialogVisible"
    :destroy-on-close="true"
    :before-close="handleBeforeClose"
    append-to-body
  >
    <div class="dialog-title-self" slot="title">
      <div class="title">转发分享</div>
    </div>
    <div class="copy-container">{{ info && info.link }}</div>
    <div slot="footer">
      <el-button type="primary" @click="handleCopyAndClose">复制链接</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { copyInfo } from '@/utils/utils'

export default {
  name: 'DialogForCopyLink',
  data() {
    return {}
  },
  computed: {
    dialogVisible() {
      return this.$store.state.music.copyInfoVisible
    },
    info() {
      return this.$store.state.music.copyInfo
    }
  },
  methods: {
    /**
     * 关闭复制弹框
     */
    handleBeforeClose() {
      this.$store.dispatch('music/closeCopyLinkDialog')
    },
    /**
     * 关闭并且复制弹框
     */
    handleCopyAndClose() {
      const { link } = this.info || {}
      copyInfo(link)
      this.$message.success('复制成功！')
      this.handleBeforeClose()
    }
  }
}
</script>

<style lang="scss" scoped>
.copy-container {
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(51, 51, 51, 0.8);
  line-height: 22px;
}
</style>
