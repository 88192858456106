import Vue from 'vue'
import ElementUI from 'element-ui'
import './styles/element-variables.scss'
import App from './App.vue'
import router from './router'
import store from './store'
import * as filters from '@/filters'
import * as RootFunc from '@/utils/rootFunc';
import '@/assets/fonts/font.css'
import '@/utils/rem.js';
// import '@/utils/vconsole.js'

import VueScroller from 'vue-scroller'
Vue.use(VueScroller)

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})
Vue.use(ElementUI)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App),
  methods: {
    ...RootFunc
  }
}).$mount('#app')
