import { formatDate as ElementFormatDate } from 'element-ui/src/utils/date-util'
import { debounce as _debounce } from './underscore'
import qs from 'qs'

export const formatDate = ElementFormatDate

export const debounce = _debounce

/**
 * 时间戳字符
 * @returns {string}
 */
export function getTimeRandom() {
  let now = new Date()
  let hour = now.getHours().toString().padStart(2, '0')
  let min = now.getMinutes().toString().padStart(2, '0')
  let second = now.getSeconds().toString().padStart(2, '0')

  return `${hour}${min}${second}`
}
export function getFileName(filename) {
  let pos = filename.lastIndexOf('.')
  let suffix = ''

  if (pos != -1) {
    suffix = filename.substring(pos)
  }

  return suffix
}

export function random_string(len) {
  len = len || 10
  var chars = '0123456789' // ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678
  var maxPos = chars.length
  var pwd = ''

  for (let i = 0; i < len; i++) {
    pwd += chars.charAt(Math.floor(Math.random() * maxPos))
  }

  return pwd
}

export function changeUrlArg(url, name, val) {
  const newUrl = new URL(url)
  if (newUrl.search) {
    const obj = qs.parse(newUrl.search) || {}
    if (val) {
      obj[name] = val
    } else {
      delete obj[name]
    }
    newUrl.search = qs.stringify(obj)
  }
  return newUrl.href
}

/**
 * 复制文字
 * 2021-12-20 15:33:01
 * @author SnowRock
 * @param str
 */
export function copyInfo(str) {
  const el = document.createElement('textarea')

  el.value = str
  el.setAttribute('readonly', '')
  el.style.position = 'absolute'
  el.style.left = '-9999px'

  document.body.appendChild(el)

  const selected =
    document.getSelection().rangeCount > 0
      ? document.getSelection().getRangeAt(0)
      : false

  el.select()

  document.execCommand('copy')
  document.body.removeChild(el)

  if (selected) {
    document.getSelection().removeAllRanges()
    document.getSelection().addRange(selected)
  }
}

/**
 * 通过A标签下载内容
 * 2021-12-21 17:53:02
 * @author SnowRock
 * @param link
 * @param fileName
 */
export function downloadByLink(link, fileName) {
  var a = document.createElement('a')
  a.href = link
  a.target = '_blank'
  a.download = fileName || ''
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
  a = null
}

// 金额转大写
export const intToChinese = (money) => {
  //汉字的数字
  var cnNums = new Array(
    '零',
    '壹',
    '贰',
    '叁',
    '肆',
    '伍',
    '陆',
    '柒',
    '捌',
    '玖'
  )
  //基本单位
  var cnIntRadice = new Array('', '拾', '佰', '仟')
  //对应整数部分扩展单位
  var cnIntUnits = new Array('', '万', '亿', '兆')
  //对应小数部分单位
  var cnDecUnits = new Array('角', '分', '毫', '厘')
  //整数金额时后面跟的字符
  var cnInteger = '整'
  //整型完以后的单位
  var cnIntLast = '元'
  //最大处理的数字
  var maxNum = 999999999999999.9999
  //金额整数部分
  var integerNum
  //金额小数部分
  var decimalNum
  //输出的中文金额字符串
  var chineseStr = ''
  //分离金额后用的数组，预定义
  var parts
  if (money == '') {
    return ''
  }
  money = parseFloat(money)
  if (money >= maxNum) {
    //超出最大处理数字
    return ''
  }
  if (money == 0) {
    chineseStr = cnNums[0] + cnIntLast + cnInteger
    return chineseStr
  }
  //转换为字符串
  money = money.toString()
  if (money.indexOf('.') == -1) {
    integerNum = money
    decimalNum = ''
  } else {
    parts = money.split('.')
    integerNum = parts[0]
    decimalNum = parts[1].substr(0, 4)
  }
  //获取整型部分转换
  if (parseInt(integerNum, 10) > 0) {
    var zeroCount = 0
    var IntLen = integerNum.length
    for (var i = 0; i < IntLen; i++) {
      var n = integerNum.substr(i, 1)
      var p = IntLen - i - 1
      var q = p / 4
      var m = p % 4
      if (n == '0') {
        zeroCount++
      } else {
        if (zeroCount > 0) {
          chineseStr += cnNums[0]
        }
        //归零
        zeroCount = 0
        chineseStr += cnNums[parseInt(n)] + cnIntRadice[m]
      }
      if (m == 0 && zeroCount < 4) {
        chineseStr += cnIntUnits[q]
      }
    }
    chineseStr += cnIntLast
  }
  //小数部分
  if (decimalNum != '') {
    var decLen = decimalNum.length
    for (var j = 0; j < decLen; j++) {
      var k = decimalNum.substr(j, 1)
      if (k != '0') {
        chineseStr += cnNums[Number(k)] + cnDecUnits[j]
      }
    }
  }
  if (chineseStr == '') {
    chineseStr += cnNums[0] + cnIntLast + cnInteger
  } else if (decimalNum == '') {
    chineseStr += cnInteger
  }
  return chineseStr
}
