/**
 * 下载相关信息
 * 2021-12-21 16:05:29
 * @author SnowRock
 * @type {{}}
 */
const state = {
  dialogVisibleForChoosePackage: false, // 打开下载弹框的模态框
  dialogVisibleForTips: false, // 打开下载限制提示语弹框
  downloadType: 'single', // 下载类型
  tipsType: 1, // 提示语信息 1 单日限制  2 套餐无下载内容 3 歌曲大于单日限制 4 歌曲大于套餐
  dayCount: 0, // 选中的套餐今日剩余次数
  packageCount: 0, // 选中的套餐剩余次数
  downloadList: [], // 下载列表】
  auth: {
    dialogVisibleAuth: false, // 是否显示授权下载成功提示弹框
    certifcateNum: 10000 // 激活基数
  },
  dialogVisibleAuthTimes: false, // 是否显示授权下载次数限制弹框
  cartDownloadList: [] // 购物车下载的商品
}
const actions = {
  // 打开下载限制提示语 当前音乐数量超出套餐剩余次数
  openDownloadTipsByFullPackage(store, { dayCount, packageCount }) {
    store.dispatch('openDownLoadTips', {
      dayCount,
      packageCount,
      type: 4
    })
  },
  // 打开下载限制提示语 当前音乐数量超出本日可下载次数
  openDownloadTipsByFullDay(store, { dayCount, packageCount }) {
    store.dispatch('openDownLoadTips', {
      dayCount,
      packageCount,
      type: 3
    })
  },
  // 打开下载限制提示语 单日限制
  openDownloadTipsByDayOver(store, { dayCount, packageCount }) {
    store.dispatch('openDownLoadTips', {
      dayCount,
      packageCount,
      type: 1
    })
  },
  // 打开下载限制提示语 套餐限制
  openDownloadTipsByPackageOver(store, { dayCount, packageCount }) {
    store.dispatch('openDownLoadTips', {
      dayCount,
      packageCount,
      type: 2
    })
  },
  /**
   * 打开下载提示弹框
   * 2021-12-22 16:56:48
   * @author SnowRock
   * @param store
   * @param dayCount
   * @param packageCount
   * @param type
   */
  openDownLoadTips(store, { dayCount, packageCount, type }) {
    store.commit('CHANGE_CHOOSE_PACKAGE_VISIBLE', false)
    store.commit('CHANGE_TIPS_VISIBLE', true)
    store.commit('CHANGE_DAY_COUNT', dayCount)
    store.commit('CHANGE_PACKAGE_COUNT', packageCount)
    store.commit('CHANGE_TIPS_TYPE', type)
  },
  // 打开关闭选择套餐弹框
  changeChoosePackageVisible(store, show) {
    store.commit('CHANGE_CHOOSE_PACKAGE_VISIBLE', show)
  },
  // 记录购物车下载的商品ID
  recordCartCommodityID(store, list) {
    store.commit('RECORD_CART_COMMODITY_ID', list)
  },
  // 打开关闭提示语弹框
  changeTipsVisible(store, show) {
    store.commit('CHANGE_TIPS_VISIBLE', show)
  },
  // 更新下载内容列表
  changeDownloadList(store, list) {
    store.commit('SET_DOWNLOAD_LIST', list)
  },
  // 设置下载模式
  changeDownloadType(store, type) {
    store.commit('SET_DOWNLOAD_TYPE', type)
  },
  // 清除下载列表
  clearDownloadList(store) {
    store.commit('SET_DOWNLOAD_LIST', [])
  },
  // 更新授权下载弹框信息
  updateAuthDialogvisible(store, data) {
    store.commit('UPDATE_AUTHDIALOG_VISIBLE', data)
  },
  updateAuthtimesDialog(store, data) {
    store.commit('UPDATE_AUTHTIMES_DIALOG', data)
  }
}
const mutations = {
  CHANGE_CHOOSE_PACKAGE_VISIBLE(state, show) {
    state.dialogVisibleForChoosePackage = show
  },
  RECORD_CART_COMMODITY_ID(state, list) {
    state.cartDownloadList = list
  },
  CHANGE_TIPS_VISIBLE(state, show) {
    state.dialogVisibleForTips = show
  },
  CHANGE_TIPS_TYPE(state, type) {
    state.tipsType = type
  },
  SET_DOWNLOAD_TYPE(state, type) {
    state.downloadType = type
  },
  CHANGE_DAY_COUNT(state, count) {
    state.dayCount = count
  },
  CHANGE_PACKAGE_COUNT(state, count) {
    state.packageCount = count
  },
  SET_DOWNLOAD_LIST(state, list) {
    state.downloadList = list
  },
  UPDATE_AUTHDIALOG_VISIBLE(state, data) {
    state.auth = { ...data }
  },
  UPDATE_AUTHTIMES_DIALOG(state, val) {
    state.dialogVisibleAuthTimes = val
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
