<template>
  <div id="app">
    <!-- <keep-alive>
      <router-view v-if="$route.meta.keepAlive" class="router-view">
      </router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" class="router-view" ></router-view> -->
    <router-view v-if="routerStatus" />
    <!-- 播放器 -->
    <!--<div v-if="isShowAudioBlock" class="audio-block" />-->
    <vf-audio />
    <!-- 全局登录弹框 -->
    <DialogForLogin ref="DialogForLogin" />
    <!-- 分享信息弹框 -->
    <DialogForCopyLink ref="DialogForCopyLink" />
    <!-- 下载选择套餐 -->
    <DialogForChoosePackage ref="DialogForChoosePackage" />
    <!-- 下载限制提示语 -->
    <DialogForDownloadTips ref="DialogForChoosePackage" />
    <!-- 即将过期会员弹框 -->
    <ExpiringMembershipDialog />
    <!-- 已过期会员弹框 -->
    <ExpiredMembershipDialog />
    <!-- 注册成功提示弹框 -->
    <DialogForRegisterSuccess />

    <!-- 右侧导航 -->
    <PlGuide v-if="isShowIntrduce" :often-class="oftenClass" @help="handleNavHelp"
      @introduce="handleMouseOver('introduce')" @question="wenjuan()" />
    <!-- 活动介绍弹窗 -->
    <div class="activeIntroduce_dialog" v-show="showIntroduce">
      <div class="active_detail">
        <div class="active_video">
          <div class="mask_cont" v-show="showMask">
            <img src="@/assets/image/play.png" @click="playVideo" />
          </div>
          <img src="@/assets/image/close_video.png" class="close_video" @click="closeVideodialog" />
          <video ref="video" :poster="poster" id="video" width="720px" height="407px" controls>
            <source :src="videoUrl" />
          </video>
        </div>
      </div>
    </div>

    <!-- 立即助力 -->
    <el-dialog class="dialog-member-help" :visible="dialogVisibleHelp" :destroy-on-close="false" :show-close="false"
      :append-to-body="false" :before-close="handleBeforeClosehelp" center>
      <div class="member_help_dialog">
        <div class="member_help_content">
          <p>
            您正在参加推广分享送会员活动 购买任意会员后
            分享人可免费获得月会员一个
          </p>
          <img src="@/assets/image/help_btn.png" alt="" class="help_btn" @click="immediate_help" />
        </div>
      </div>
    </el-dialog>

    <!--未登录提示框-->
    <div class="header-Prompt" v-if="showLoginTip">
      <div class="prompt container_media">
        <aside class="prompt_l">
          <span>正版授权 100%正品保障</span>
          <span>全站资源每月只需29.9元</span>
        </aside>
        <aside class="prompt_r">
          <span>一键快捷登录：</span>
          <aside class="login_img" @click="handleLogin">
            <el-image fit="cover" :src="loginTip"></el-image>
          </aside>
        </aside>
      </div>
    </div>
  </div>
</template>

<style lang="scss" src="./styles/index.scss"></style>
<script>
import DialogForLogin from '@/components/DialogForLogin'
import DialogForCopyLink from '@/components/DialogFoCopyLink'
import VfAudio from '@/components/VfAudio'
import DialogForChoosePackage from '@/components/DialogForChoosePackage'
import DialogForDownloadTips from '@/components/DialogForDownloadTips'
import { userIncline } from '@/api/member.js'
import ExpiringMembershipDialog from '@/components/ExpiringMembershipDialog'
import ExpiredMembershipDialog from '@/components/ExpiringMembershipDialog/Expired'
import DialogForRegisterSuccess from '@/components/DialogForRegisterSuccess'
import PlGuide from '@/components/PlGuide'
// import { mapState } from 'vuex'
import { debounce as _debounce } from '@/utils/underscore'
import { getToken } from '@/utils/author'

export default {
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      showLoginTip: false,
      routerStatus: true,
      loginTip: require('@/assets/image/phone.png'),
      introduce_url: require('@/assets/image/active.png'),
      survey_url: require('@/assets/svg/survey.svg'),
      zixun_url: require('@/assets/image/kefu.png'),
      member_url: require('@/assets/image/give_member1.png'),
      feedback_url: require('@/assets/image/feedback.png'),
      collect_url: require('@/assets/image/collect_icon.png'),
      help_url: require('@/assets/svg/help.svg'),
      oftenClass: '',
      ishover: false,
      ismember: false,
      isActive: false,
      isCollect: false,
      isFeed: false,
      isSurvey: false,
      isHelp: false,
      dialogVisiblesurvey: true,
      isShowIntrduce: true,
      isShowRightIcon: true,
      showMask: true,
      showIntroduce: false,
      videoUrl: 'https://qkodo.playlistmusic.com.cn/default%2FIMG_3362_1.mp4', // 活动介绍视频地址
      // videoUrl: 'https://qkodo.playlistmusic.com.cn/default%2F%E8%B7%AF%E8%BF%87%E6%88%91%E7%9A%84%E7%94%9F%E6%B4%BB1.mp4',  // 活动介绍视频地址
      // videoUrl: 'https://qkodo.playlistmusic.com.cn/public-common/playlist.mp4',  // 活动介绍视频地址
      poster: require('@/assets/image/poster_video.png') // 封面
    }
  },
  components: {
    PlGuide,
    ExpiredMembershipDialog,
    ExpiringMembershipDialog,
    DialogForDownloadTips,
    DialogForChoosePackage,
    VfAudio,
    DialogForCopyLink,
    DialogForLogin,
    DialogForRegisterSuccess
  },
  computed: {
    // 是否展示占位模块
    isShowAudioBlock() {
      return this.$store.state.music.musicControlsVisible
    },
    // 是否展示分享助力弹窗
    dialogVisibleHelp() {
      return this.$store.state.user.dialogForHelpVisible
    },
    // showMember(){
    //   return window.sessionStorage.getItem('memberStatus')
    // },
    user() {
      return this.$store.state.user.userInfo
    }
  },
  watch: {
    $route: {
      handler() {
        // 判断是否为手机端活动页面 是隐藏右侧悬浮图标
        const ua = navigator.userAgent
        if (ua.match(/(iPhone|iPad|Android|ios)/i)) {
          this.isShowIntrduce = false
          this.isShowRightIcon = false
        } else {
          this.isShowIntrduce = true
          this.isShowRightIcon = true
        }
      },
      immediate: true
    },
    user: {
      handler(v) {
        if (this.showLoginTip && v) {
          this.showLoginTip = false
        }
      },
      immediate: true
    }
  },
  mounted() {
    window.addEventListener(
      'scroll',
      _debounce(() => {
        this.scroll()
      }, 50)
    )
    setTimeout(() => {
      this.getSourceInfo()
    }, 100)
  },
  methods: {
    /**
     * @description 获取来源信息
     */
    getSourceInfo() {
      if (JSON.stringify(this.$route.query) === '{}') return
      this.$store.dispatch('user/setSourceInfo', this.$route.query)
    },
    /**
     * 关闭立即助力弹窗
     */
    handleBeforeClosehelp() {
      this.$store.dispatch('user/setDialogHelp', false)
    },
    /**
     * 点击立即助力
     */
    async immediate_help() {
      const share_token = window.localStorage.getItem('share_token')
      const param = {
        share_token: share_token
      }
      const res = await userIncline(param)
      if (res.code == '0') {
        this.$router.push({ path: '/package' })
        this.$store.dispatch('user/setDialogHelp', false)
      }
    },
    /**
     * 进入帮助中心
     */
    handleNavHelp() {
      this.$router.push({ path: '/help' })
    },
    handleMember() {
      this.$router.push({ path: '/package/page' })
    },
    // 播放活动介绍视频
    playVideo() {
      this.$refs.video.play()
      this.showMask = false
    },
    /**
     * 关闭活动介绍弹窗
     */
    closeVideodialog() {
      this.showIntroduce = false
      this.isActive = false
      this.oftenClass = ''
      this.introduce_url = require('./assets/image/active.png')
      this.$refs.video.load()
    },
    /**
     * 进入注册送会员页面
     */
    // goMember(){
    //   this.$router.push({path:'/activity/active'})
    // },
    reload() {
      this.routerStatus = false
      this.$nextTick(() => {
        this.routerStatus = true
      })
    },
    handleMouseOver(item) {
      this.oftenClass = item
      if (item === 'introduce') {
        this.isActive = true
        this.showIntroduce = true
        this.introduce_url = require('./assets/image/active_hover.png')
      } else if (item === 'survey') {
        this.isSurvey = true
        this.survey_url = require('./assets/svg/survey_active.svg')
      } else if (item === 'help') {
        this.isHelp = true
        this.help_url = require('./assets/svg/help_hover.svg')
      } else if (item === 'kefu') {
        this.ishover = true
        this.zixun_url = require('./assets/image/kefu_hover.png')
      }
    },
    handleMouseLeave(item) {
      if (item === 'kefu') {
        this.ishover = false
        this.oftenClass = ''
        this.zixun_url = require('./assets/image/kefu.png')
      } else if (item === 'help') {
        this.isHelp = false
        this.oftenClass = ''
        this.help_url = require('./assets/svg/help.svg')
      } else if (item === 'survey') {
        this.isSurvey = false
        this.oftenClass = ''
        this.survey_url = require('./assets/svg/survey.svg')
      }
    },
    wenjuan() {
      window.open('https://www.wenjuan.com/s/r2uMjyu/')
    },
    // pc端 未登录时，下滑至第二屏，导航下方出现登录提示，提示用户登录
    scroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      const ua = navigator.userAgent
      if (!ua.match(/(iPhone|iPad|Android|ios)/i)) {
        if (scrollTop > 80) {
          if (!getToken()) {
            this.showLoginTip = true
            // this.$store.dispatch('user/openLogin')
          } else {
            this.showLoginTip = false
          }
        } else {
          this.showLoginTip = false
        }
      } else {
        this.showLoginTip = false
      }
    },
    handleLogin() {
      if (this.showLoginTip && !getToken()) {
        this.$store.dispatch('user/openLogin')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@keyframes solltTop {
  0% {
    transform: translateY(-60px);
  }

  100% {
    transform: translateY(0px);
  }
}

.header-Prompt {
  position: fixed;
  top: 60px;
  right: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background: rgba(0, 0, 0, 0.5);
  user-select: none;
  z-index: 5000;
  animation: solltTop 0.5s;

  .prompt {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding: 15px 105px;
    // box-sizing: border-box;

    .prompt_l {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;

      span {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 24px;
      }
    }

    .prompt_r {
      display: flex;
      align-items: center;

      span {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 22px;
      }

      .login_img {
        margin-left: 20px;
        width: 40px;
        height: 40px;
        padding: 10px;
        box-sizing: border-box;
        background: #ffa43b;
        border-radius: 50%;
        cursor: pointer;

        .el-image {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.audio-block {
  height: 268px;
  background-color: #343539;
}

.zixun_cont,
.member_cont,
.active_introduce,
.collect_cont,
.feedback_cont,
.help_cont {
  z-index: 1000;
  position: fixed;
  right: 2px;
  width: 65px;
  height: 65px;
  background: #ffffff;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.09);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: #333;
  // font-size: 15px;
  border-radius: 6px;
  cursor: pointer;

  .menu_icon {
    width: 25px;
    height: 25px;
  }

  span {
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #b2b2b2;
    display: block;
    padding-top: 2px;
    text-shadow: 0px 0px 12px rgba(0, 0, 0, 0.09);
  }
}

.active_introduce {
  bottom: 385px;
}

.member_cont {
  bottom: 460px;
}

.feedback_cont {
  bottom: 310px;
}

.help_cont {
  bottom: 230px;
}

.collect_cont {
  bottom: 155px;
}

.zixun_cont {
  bottom: 80px;
}

/**
  活动介绍
*/
.activeIntroduce_dialog {
  z-index: 2000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);

  .active_detail {
    width: 100%;
    height: 100%;
    @include center-middle-flex;

    .active_video {
      width: 720px;
      height: 407px;
      position: relative;

      .mask_cont {
        z-index: 3000;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
        position: absolute;
        left: 0;
        top: 0;
        @include center-middle-flex;

        img {
          width: 80px;
          cursor: pointer;
        }
      }

      .close_video {
        z-index: 3000;
        position: absolute;
        width: 30px;
        position: absolute;
        right: -40px;
        top: 0px;
        cursor: pointer;
      }
    }
  }
}

/** 立即助力弹窗 */
/deep/ .dialog-member-help {
  // position: fixed!important;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  // margin-top: 30px;
  .el-dialog {
    width: 499px;
    height: 522px;
    margin-top: 0 !important;
    box-shadow: none !important;
    background: url('~@/assets/image/member_help.png') no-repeat;
    background-size: 100% 100%;
    background-position: center center;

    .member_help_dialog {
      width: 100%;
      height: 100%;

      .member_help_content {
        width: 100%;
        height: 522px;
        position: relative;

        p {
          @include custom-position($position: absolute,
            $left: 50%,
            $bottom: 150px);
          margin-left: -110px;
          text-align: center;
          width: 220px;
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: rgba(255, 255, 255, 0.86);
          line-height: 30px;
        }

        .help_btn {
          @include custom-position($position: absolute,
            $left: 50%,
            $bottom: 85px);
          margin-left: -110px;
          text-align: center;
          width: 220px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
