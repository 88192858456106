<template>
  <el-form
    ref="findPwdForm"
    :model="findPwdForm"
    :inline-message="true"
    :rules="registerRules"
    class="login-form-container"
  >
    <el-form-item prop="nickname">
      <div class="files-content">
        <span class="svg-container">
          <img src="@/assets/svg/account-icon.svg" alt="" />
        </span>
        <label class="vf-reg-label">用户名</label>
        <el-input
          ref="nickname"
          v-model="findPwdForm.nickname"
          placeholder="请输入用户名称"
          name="nickname"
          type="text"
          tabindex="1"
          autocomplete="on"
        />
      </div>
    </el-form-item>
    <el-form-item prop="account">
      <div class="files-content">
        <span class="svg-container">
          <img src="@/assets/svg/phone-login-icon.svg" alt="" />
        </span>
        <label class="vf-reg-label">账号</label>
        <el-input
          ref="account"
          v-model="findPwdForm.account"
          placeholder="请输入手机号/邮箱"
          name="account"
          type="text"
          tabindex="1"
          autocomplete="on"
        />
      </div>
    </el-form-item>
    <el-form-item prop="smsCode">
      <div class="flex">
        <div class="files-content">
          <span class="svg-container">
            <img src="@/assets/svg/sms-icon.svg" alt="" />
          </span>
          <label class="vf-reg-label">验证码</label>
          <el-input
            ref="smsCode"
            v-model="findPwdForm.smsCode"
            autocomplete="on"
            name="text"
            placeholder="请输入验证码"
            tabindex="2"
            @keyup.enter.native="handleLogin"
          />
        </div>
        <el-button
          v-if="inSendSmsReady"
          class="send-sms-code"
          style="margin-left: 16px"
          @click="sendMsgForAccount"
          >获取验证码</el-button
        >
        <el-button v-else class="send-sms-code" style="margin-left: 16px">{{
          `${time}秒`
        }}</el-button>
      </div>
    </el-form-item>
    <el-form-item prop="password" style="margin-bottom: 16px">
      <div class="files-content">
        <span class="svg-container">
          <img src="@/assets/svg/password-icon.svg" alt="" />
        </span>
        <label class="vf-reg-label">设置密码</label>
        <el-input
          :key="passwordType"
          ref="password"
          v-model="findPwdForm.password"
          :type="passwordType"
          autocomplete="on"
          name="password"
          placeholder="请输入密码"
          tabindex="2"
          @keyup.enter.native="handleRegister"
        />
        <span class="view-password-container">
          <img
            v-if="passwordType === 'password'"
            src="@/assets/svg/not-view.svg"
            alt=""
            @click="passwordType = 'text'"
          />
          <img
            v-else
            src="@/assets/svg/view.svg"
            @click="passwordType = 'password'"
            alt=""
          />
        </span>
      </div>
    </el-form-item>
    <el-form-item prop="invite_code">
      <div class="files-content">
        <span class="svg-container">
          <img src="@/assets/svg/phone-login-icon.svg" alt="" />
        </span>
        <label class="vf-reg-label">邀请码</label>
        <el-input
          ref="invite_code"
          v-model="findPwdForm.invite_code"
          placeholder="请输入邀请码（选填）"
          name="invite_code"
          type="text"
          tabindex="1"
          autocomplete="off"
        />
      </div>
    </el-form-item>
    <el-button
      :loading="loading"
      type="login"
      style="width: 100%; margin-bottom: 8px"
      @click.native.prevent="handleRegister"
    >
      注册
    </el-button>
    <!-- 注册协议 -->
    <p class="register-tips">
      注册即代表同意
      <a class="link-type" :href="userProtocol" target="_blank"
        >《Playlist用户协议》</a
      ><a class="link-type" :href="PrivacyProtocol" target="_blank"
        >《Playlist隐私协议》</a
      >及<a class="link-type" :href="subscriptionServiceAgreement" target="_blank"
        >《Playlist订阅服务协议》</a
      >
    </p>
    <!-- 登录提示 -->
    <p class="register-tips">
      已有账号，<el-button type="text" @click="handleLogin">直接登录</el-button>
    </p>
  </el-form>
</template>

<script>
import CountDown from '@/layout/mixins/CoutDown.js'
import { validAccount, validPhone } from '@/utils/validate'
import { userProtocol, PrivacyProtocol, subscriptionServiceAgreement } from '@/config'
import { registerUser } from '@/api/user'
import { sendSmsForEmail, sendSmsForPhone } from '@/api/common'
import { setTempDeviceId, setToken, setUserId } from '@/utils/author'
import { checkedExpiringMembership, getCartList } from '@/utils/operate'
import { send_eventApi } from '@/utils/common.js'
import { mapGetters } from 'vuex'

export default {
  name: 'FindPwdForm',
  data() {
    const validateAccount = (rule, value, callback) => {
      if (!validAccount(value)) {
        callback(new Error('请输入正确的邮箱号或者手机号'))
      } else {
        callback()
      }
    }
    return {
      PrivacyProtocol,
      userProtocol,
      subscriptionServiceAgreement,
      findPwdForm: {
        nickname: '', // 用户名
        account: '', // 账号
        smsCode: '', // 验证码
        password: '', // 密码
        invite_code: '' // 邀请码
      },
      registerRules: {
        account: [
          { required: true, trigger: 'blur', validator: validateAccount }
        ],
        nickname: [
          { required: true, message: '请输入用户名称', trigger: 'blur' }
        ],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        smsCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
      },
      passwordType: 'password',
      loading: false,
      redirect: undefined,
      transaction_id: undefined, // 验证码凭证
      otherQuery: {}
    }
  },
  watch: {
    $route: {
      handler: function (route) {
        const query = route.query
        if (query) {
          this.redirect = query.redirect
          this.otherQuery = this.getOtherQuery(query)
        }

        // store.dispatch('user/openLogin')
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'userInfo'
    })
  },
  mixins: [CountDown],
  created() {},
  methods: {
    /**
     * @description 获取来源信息
     */
     getSourceInfo() {
      if (JSON.stringify(this.$route.query) === '{}') {
        this.$store.dispatch('user/setSourceInfo', null)
      } else {
        this.$store.dispatch('user/setSourceInfo', this.$route.query)
      }
    },
    /**
     * 处理注册
     * 2021-12-16 15:06:37
     * @author SnowRock
     */
    handleRegister() {
      this.$refs.findPwdForm.validate(async (valid) => {
        if (!valid) return
        this.loading = true
        // 提交表单验证信息
        const { transaction_id } = this
        const {
          nickname: name,
          account,
          smsCode,
          password,
          invite_code
        } = this.findPwdForm
        let params = {}

        // 判断是否是通过分享打开的链接注册
        const share_token = window.localStorage.getItem('share_token')
        if (share_token) {
          // 将数据变为接口所需要的数据
          params = {
            name,
            account,
            password,
            invite_code,
            verify_code: smsCode,
            transaction_id,
            share_user_id: share_token,
            share_type: 1
          }
        } else {
          params = {
            name,
            account,
            password,
            invite_code,
            verify_code: smsCode,
            transaction_id
          }
        }
        // 设置渠道来源入参信息
        this.setSourceParams(params)
        try {
          const { data } = await registerUser(params)
          // this.$message.success('注册成功！');
          setToken(data?.token || '')
          setUserId(data?.user_id || '')
          await this.$store.dispatch('user/setUserInfo', data)
          await this.$store.dispatch('user/closeLoginDialog')
          await this.$store.dispatch('user/openRegisterSuccessDialog')
          await getCartList()
          await this.$store.dispatch('message/updateMessageCount')
          // 设置登录用户id
          window.gio('setUserId', this.userInfo.user_id || data.user_id)

          // 百度检测代码 注册成功
          if (process.env.NODE_ENV === 'production') {
            window._agl && window._agl.push(['track', ['success', { t: 3 }]])
          }
          const ua = navigator.userAgent
          let obj = {}
          // 判断页面是移动端还是pc端
          if (ua.match(/(iPhone|iPad|Android|ios)/i)) {
            obj = {
              reg_date: this.userInfo ? this.userInfo.create_at : '',
              user_name: this.userInfo ? this.userInfo.name : '',
              user_id: this.userInfo ? this.userInfo.user_id : '',
              p_origin: '手机等移动设备'
            }
            send_eventApi('e_register', obj)
            this.$router.push({
              path: '/home'
            })
          } else {
            obj = {
              reg_date: this.userInfo ? this.userInfo.create_at : '',
              user_name: this.userInfo ? this.userInfo.name : '',
              user_id: this.userInfo ? this.userInfo.user_id : '',
              p_origin: 'web端'
            }
            send_eventApi('e_register', obj)
            this.$router.push({ path: '/' })
          }
          setTempDeviceId() // 获取或者重新绑定用户
          await checkedExpiringMembership()
          // send_eventApi('e_register', obj); // 注册事件埋点
        } catch (e) {
          console.log(e)
        }
        this.loading = false
      })
    },
    setSourceParams(params) {
      this.getSourceInfo()
      const ua = navigator.userAgent
      let info = this.$store.state.user.sourceInfo
      const defaultInfo = {
        utm_source: 'PlayList',
        utm_medium: '',
        utm_campaign: '',
        utm_content: '',
        utm_term: ''
      }
      if (info) {
        info = Object.assign(defaultInfo, info)
      }
      if (ua.match(/(iPhone|iPad|Android|ios)/i)) {
        info ? (info['utm_terminal'] = 2) : (defaultInfo['utm_terminal'] = 2)
      } else {
        info ? (info['utm_terminal'] = 1) : (defaultInfo['utm_terminal'] = 1)
      }
      if (info) {
        params.utm = JSON.stringify(info)
      } else {
        params.utm = JSON.stringify(defaultInfo)
      }
    },
    /**
     * 发送短信验证码
     * 2021-12-17 10:20:254
     * @author SnowRock
     * @returns {Promise<void>}
     */
    async sendMsgForAccount() {
      this.$refs.findPwdForm.validateField('account', async (valid) => {
        if (!(valid === '')) return
        const { account } = this.findPwdForm
        const fn = validPhone(account) ? sendSmsForPhone : sendSmsForEmail
        const params = validPhone(account)
          ? { phone: account, sms_tpl: 1 }
          : { email: account, email_tpl: 1 }
        try {
          const data = await fn(params)
          this.transaction_id = data.data.transaction_id
          this.countDownTime(true)
        } catch (e) {
          console.log(e)
        }
      })
    },
    /**
     * 处理跳转登录
     * 2021-12-16 16:07:58
     * @author SnowRock
     */
    handleLogin() {
      this.$emit('changeComponent', 'LoginForm')
    },
    /**
     * 展示密码
     * 2021-12-16 14:47:03
     * @author SnowRock
     */
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    /**
     * 获取其他的url 携带参数
     * @param query
     * @returns {{}}
     */
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur]
        }
        return acc
      }, {})
    }
  }
}
</script>

<style lang="scss" scoped>
@import './login-form';

.vf-reg-label {
  white-space: nowrap;
  font-weight: 500;
  margin-left: 5px;
  color: #666;
}
.login-form-container {
  .register-tips {
    text-align: left;
    margin-bottom: 3px;
  }
  /*  /deep/ {

    .is-success {
      .el-input__suffix {

      }
    }
    .el-input__suffix {
      content: '';
      &>* {
        display: none;
      }
    }
  }*/
}
</style>
