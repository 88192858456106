<template>
  <div
    class="pl-guide"
    :class="{
      'has-back-top': visible,
      [oftenClass]: oftenClass !== ''
    }"
  >
    <div
      class="pl-guide-container"
    >
      <!-- 推荐、会员、问卷、反馈、帮助、收藏、客服 -->
      <!-- <div class="guide-item introduce" @click="$emit('introduce')">
        <img class="active" src="../assets/image/guide/activity-active.png" alt="">
        <img class="usual" src="../assets/image/guide/activity.png" alt="">
        <p>推荐</p>
      </div> -->
      <!-- <div class="guide-item member">
        <img class="active" src="../assets/image/guide/member.svg" alt="">
        <img class="usual" src="../assets/image/guide/member.svg" alt="">
        <p>会员</p>
        <div class="prompt-box member">
          <div class="prompt-box-wrapper ">
            <img src="~@/assets/image/guide/chat-qrcode/member.png" alt="" class="qrcode">
          </div>
        </div>
      </div> -->
      <!-- <div
        class="guide-item question"
        @click="$emit('question')"
      >
        <img class="active" src="../assets/image/guide/questions-active.png" alt="">
        <img class="usual" src="../assets/image/guide/questions.png" alt="">
        <p>问卷</p>
      </div> -->
      <div class="guide-item choose-music">
        <img src="../assets/image/guide/choose_music.png" alt="">
        <!-- <img class="usual" src="../assets/image/guide/choose_music.png" alt=""> -->
        <p>帮您</p>
        <p>选曲</p>
        <div class="prompt-box choose-music">
          <div class="prompt-box-wrapper">
            <img src="~@/assets/image/guide/chat-qrcode/choose_music_code.png" class="qrcode">
          </div>
        </div>
      </div>
      <div class="guide-item suggest">
        <img class="active" src="../assets/image/guide/suggest-active.png" alt="">
        <img class="usual" src="../assets/image/guide/suggest.png" alt="">
        <p>加入</p>
        <p>社群</p>
        <div class="prompt-box suggest">
          <div class="prompt-box-wrapper">
            <img src="~@/assets/image/guide/chat-qrcode/suggest_0725.png" class="qrcode">
          </div>
        </div>
      </div>
      <div class="guide-item collect">
        <img class="active" src="../assets/image/guide/collect-active.png" >
        <img class="usual" src="../assets/image/guide/collect.png" alt="">
        <p>收藏</p>
        <div class="prompt-box collect">
          <div class="prompt-box-wrapper" />
        </div>
      </div>
      <div class="guide-item help" @click="$emit('help')">
        <img class="active" src="../assets/image/guide/help-active.png" alt="">
        <img class="usual" src="../assets/image/guide/help.png" alt="">
        <p>帮助</p>
      </div>
      <div class="guide-item service">
        <img class="active" src="../assets/image/guide/service-active.png" alt="">
        <img class="usual" src="../assets/image/guide/service.png" alt="">
        <p>客服</p>
        <div class="prompt-box service">
          <div class="robot-main-wrapper">
            <ul>
              <li>
                <div class="icon"><img src="~@/assets/image/guide/robot-phone-icon.png" alt=""></div>
                <div class="icon-content">
                  <h4>客服热线</h4>
                  <p class="phone">135-2235-2793</p>
                </div>
              </li>
              <li class="qrcode-container">
                <div class="icon"><img src="~@/assets/image/guide/qrcode-icon.png" alt=""></div>
                <div class="icon-content flex">
                  <div>
                    <h4>扫码咨询</h4>
                    <p class="sub-title">获取专业咨询服务</p>
                  </div>

                  <div>
                    <img class="qrcode" src="~@/assets/image/guide/wechat-icon.png" alt="">
                  </div>
                </div>
              </li>
              <li>
                <div class="icon"><img src="~@/assets/image/guide/online-answter.png" alt=""></div>
                <div class="icon-content">
                  <h4>在线咨询
                    <span class="life-question" @click="$emit('help')">常见问题>></span>
                  </h4>
                  <div class="flex cen-space">
                    <p class="sub-title">
                      工作日 <br>
                      09:30-18:30
                    </p>
                    <div class="online-chat cursor-pointer" @click="qimoChatClick">
                      咨询客服
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <el-backtop
      :bottom="100"
      :right="0"
      class="guid-back-top"
      ref="backTop"
    >
      <div class="guide-item back-top">
        <el-icon name="arrow-up" />
        <p>返回</p>
        <p>顶部</p>
      </div>
    </el-backtop>
  </div>
</template>

<script>
export default {
  name: "PlGuide",
  props: {
    oftenClass: {
      type: String,
      default: ''
    }
  },
  created() {
  },
  data() {
    return {
      visible: false
    };
  },

  mounted() {
    const vm = this;
    vm.$refs?.backTop.$watch("visible", function(value) {
      vm.visible = value;
    });
  },
  methods: {

    qimoChatClick() {
      window.qimoChatClick();
    }
  }
};
</script>

<style lang="scss" scoped>
$jet: #000000;
$jet-85: rgba(0, 0, 0, 0.85);
.flex {
  display: flex;
}

.cen-space {
  align-items: center;
  justify-content: space-between;
}

.pl-guide {
  position: fixed;
  right: 0px;
  bottom: 100px;
  z-index: 99;
  padding-bottom: 60px;

  &.has-back-top {
    background: #FFFFFF;
    box-shadow: -2px 0px 5px 0px rgba(31, 32, 37, 0.1000);

    .pl-guide-container {
      box-shadow: none;
    }
  }
  &.question .question,
  &.introduce .introduce,
  &.member .member,
  &.suggest .suggest,
  &.help .help,
  &.collect .collect{
    p {
      color: rgba(255, 47, 15, 0.8);
    }
    img.usual {
      display: none;
    }

    img.active {
      display: initial;
    }
  }

  .life-question {
    float: right;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #0061F3;
    line-height: 12px;
    text-shadow: 0px 0px 12px rgba(31,32,37,0.1200);
  }

  &-container {
    width: 40px;
    background: #FFFFFF;
    box-shadow: -2px 0px 5px 0px rgba(31, 32, 37, 0.10);
  }

  .guide-item {
    text-align: center;
    padding-top: 8px;
    padding-bottom: 6px;
    position: relative;
    cursor: pointer;

    &:not(.choose-music):hover {
      p {
        color: rgba(255, 47, 15, 0.8);
      }

      img.usual {
        display: none;
      }

      img.active {
        display: initial;
      }
    }

    & + .guide-item {
      border-top: 1px solid #EDEDED;
    }

    p {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5000);
      margin: 0;
    }

    img {
      width: 24px;
      height: 24px;
      margin: 0;
    }

    img.usual {
      display: initial;
    }

    img.active {
      display: none;
    }

    &:hover {
      .prompt-box {
        display: block;
      }
    }

    .prompt-box {
      padding-right: 12px;
      right: 40px;
      bottom: 0px;
      position: absolute;
      display: none;
      .prompt-box-wrapper {
        width: 290px;
        height: 140px;
        background-size: cover;
        margin-bottom: -81px;
        position: relative;

        .qrcode {
          width: 80px;
          height: 80px;
          border-radius: 8px;
          position: absolute;
          right: 46px;
          top: 33px;
        }
      }
      &.member {
        .prompt-box-wrapper {
          background: url("~@/assets/image/guide/guide-memeber-container-bg.png") no-repeat;
          background-size: cover;
          margin-bottom: -81px;
        }
      }
      &.collect {
        .prompt-box-wrapper {
          background: url("~@/assets/image/guide/guide-collect-container-bg.png") no-repeat;
          background-size: cover;
          margin-bottom: -81px;
        }
      }
      &.choose-music {
        .prompt-box-wrapper {
          background: url("~@/assets/image/guide/choose_music_bg.png") no-repeat;
          background-size: cover;
          margin-bottom: -81px;
          .qrcode {
            width: 76px;
            height: 76px;
            right: 44px;
            top: 32px;
          }
        }
      }
      &.suggest {
        .prompt-box-wrapper {
          background: url("~@/assets/image/guide/guide-suggestion-container-bg.png") no-repeat;
          background-size: cover;
          margin-bottom: -81px;
        }
      }

      &.service {
        .robot-main-wrapper {
          width: 296px;
          background: #FFFFFF;
          box-shadow: 0px 0px 12px 0px rgba(51, 59, 69, 0.1200);
          border-radius: 10px;
          transition: all .3s ease;
          padding: 24px;
          margin-bottom: -70px;

          ul {
            li {
              display: flex;
              padding-bottom: 16px;
              padding-top: 16px;
              border-bottom: 1px solid #efefef;
              text-align: left;

              &:first-of-type {
                padding-top: 0;
              }

              &:last-of-type {
                padding-bottom: 0;
                border-bottom: none;
              }

              .icon {
                margin-right: 8px;

                img {
                  width: 16px;
                  height: 16px;
                }
              }

              .icon-content {
                flex: 1;

                h4 {
                  font-size: 16px;
                  font-family: OPPOSans-M, OPPOSans;
                  font-weight: bold;
                  color: $jet;
                  line-height: 16px;
                  padding: 0;
                  margin: 0;
                  margin-bottom: 10px;
                }

                .phone {
                  font-size: 16px;
                  font-family: OPPOSans-B, OPPOSans;
                  font-weight: bold;
                  color: $jet;
                  line-height: 16px;
                }

                .sub-title {
                  font-size: 12px;
                  font-family: OPPOSans-R, OPPOSans;
                  font-weight: normal;
                  color: rgba(51, 59, 69, 0.5000);
                  line-height: 16px;
                  margin-bottom: 0;
                }

                .qrcode {
                  width: 86px;
                  height: 86px;
                  margin-top: 12px;
                }

                .online-chat {
                  width: 80px;
                  height: 32px;
                  background: linear-gradient(90deg, #FF5C22 0%, #E52A0D 100%);
                  border-radius: 20px;
                  font-size: 12px;
                  font-family: OPPOSans-B, OPPOSans;
                  font-weight: bold;
                  color: #FFFFFF;
                  line-height: 32px;
                  text-align: center;
                  cursor: pointer;
                }
              }


              &.qrcode-container {
                padding-top: 15px;
                padding-bottom: 16px;

                .icon {
                  padding-top: 4px;
                }

                .icon-content {
                  display: flex;
                  justify-content: space-between;
                  h4 {
                    margin-top: 4px;
                  }
                  img {
                    margin: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .guide-item.choose-music{
    p{
      color: #E52A0D;
      line-height: 14px;
    }
  }
  .guide-item.suggest{
    p{
      line-height: 14px;
    }
  }
}

/deep/ {
  .guid-back-top.el-backtop {
    width: 40px;
    height: auto;
    border-radius: inherit;
    color: rgba(0, 0, 0, 0.5000);
    border-top: 1px solid #EDEDED;
    box-shadow: none;

    &:hover {
      background-color: transparent;
    }

    .guide-item {
      padding-top: 0px;
      height: 60px;

      &:hover {
        color: rgba(255, 47, 15, 0.8);

        p {
          color: rgba(255, 47, 15, 0.8);
        }
      }

      p {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5000);
        line-height: 14px;
      }

      .el-icon-arrow-up {
        font-size: 14px;
        margin-bottom: 2px;
      }
    }
  }
}
</style>
