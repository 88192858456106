import axios from 'axios';
import VAudio from '@/utils/VAudio';
/**
 * 播放器相关内容状态均在此
 * 2021-12-20 13:13:48
 * @author SnowRock
 */

const state = {
  musicControlsVisible: false, // 是否展示弹框
  copyInfoVisible: false, // 是否展示复制弹框信息
  copyInfo: null, // 复制弹框信息
  isPlaying: false, // 是否正在播放
  orderPlayList: [], // 播放列表
  tmpMusicList: [], // 临时播放列表
  tmpMusicInfo: {}, // 目前音乐播放器所播放的音乐
  isDisabledplayAllbtn: true,// 根据播放次数限制全部播放按钮
  seek: 0, // 音乐播放进度百分比
  progressIsChange: false, // 拖动音乐播放进度
  musicProgress: 0, // 音乐播放进度

  isEnd: true,
  loading: true,
  // isPlaying: false,
  isShow: false,
  waveProgress: 0,
  duration: 0,
  playList: [],
  playingMusic: {},
  audio: null,
  volume: 1,
  progressTimer: null
}

const actions = {
  // 更新全部播放顺序列表
  updateOrderList(store, info){
    store.commit('UPDATE_ORDER_ALLMUSIC', info)
  },
  // 根据播放次数限制全部播放按钮
  updateBtnstatus(store, info){
    store.commit('UPDATE_BTN_STATUS', info)
  },
  // 更新音乐列表
  async updateList(store, info) {
    await store.commit('UPDATE_MUSIC_TO_LIST', info)
  },
  // 展示音乐播放器
  showMusicControls(store) {
    store.commit('UPDATE_MUSIC_CONTROLS_VISIBLE', true)
  },
  // 展示音乐播放器
  changeMusicInfo(store, info) {
    // console.log(info)
    store.commit('UPDATE_MUSIC_INFO', info)
  },
  // 关闭音乐播放器
  closeMusicControls(store) {
    store.commit('UPDATE_MUSIC_CONTROLS_VISIBLE', false)
  },
  // 播放音乐操作
  play(store) {
    store.commit('UPDATE_PLAYING', true)
    // 如果播放音乐时 音乐播放器关闭 则自动打开播放器
    if (!store.state.musicControlsVisible) {
      store.commit('UPDATE_MUSIC_CONTROLS_VISIBLE', true)
    }
  },
  // 停止播放音乐
  pause(store) {
    store.commit('UPDATE_PLAYING', false)
  },
  // 打开复制弹框信息
  openCopyLinkDialog(store, link) {
    store.commit('UPDATE_COPY_LINK_INFO', {
      link
    })
    store.commit('UPDATE_COPY_LINK_VISIBLE', true)
  },
  // 关闭复制弹框信息
  closeCopyLinkDialog(store) {
    store.commit('UPDATE_COPY_LINK_VISIBLE', false)
    store.commit('UPDATE_COPY_LINK_INFO', null)
  },
  changeSeekToMusic(store, value){
    store.commit('CHANGE_SEEK_TOMUSIC', value)
  },
  // 是否允许拖拽音频播放滚动条
  changeProgressIsChange(store, value){
    store.commit('CHANGE_PROGRESS_CHANGE', value)
  },
  setVolumeByBarToItem({ state }, val) {
    state.audio.volume = val
  },

  musicPlay({ state, dispatch }, music) {
    if (state.playingMusic.music_id === music.music_id) {
      state.isPlaying = true;
      return state.audio.play();
    }

    return dispatch('musicStop').then(() => dispatch('initAudio', music));
  },

  musicPause({ state }) {
    state.loading = false;
    state.isPlaying = false;

    if (state.audio) {
      state.audio.pause();
    }
  },

  musicStop({ state }) {
    state.loading = false;
    state.isPlaying = false;
    state.playingMusic = {};
    if (!state.audio) {
      return;
    }
    state.audio.pause();
    state.audio.currentTime = 0;
    state.audio = null;
  },

  setProgress({ state }, lastCurrentTime) {
    setTimeout(() => {
      if (state.audio) {
        state.audio.currentTime = lastCurrentTime;
        state.isPlaying = true;
        state.audio.play()
      }
    }, 200);
  },

  record({ state }, music) {
    const musicIndex = state.playList.findIndex(item => item.music_id === music.music_id);

    if (musicIndex !== -1) {
      state.playList.splice(musicIndex, 1);
    }

    state.playList.push(music);
  },

  async initAudio({ state, dispatch, commit }, music) {
    if(state.audio) return
    state.playingMusic = music;
    state.isPlaying = true;
    if (!state.musicControlsVisible) {
      commit('UPDATE_MUSIC_CONTROLS_VISIBLE', true)
    }
    state.loading = true;

    state.waveProgress = 0;

    const audio = new VAudio();
    // const auditionUrlPromise = await api.getAuditionUrl({
    //   path: normalizationPreview(music.preview)
    // });
    let url = music.watermark_file || music.watermark_url
    // console.log(url,'url');
    // audio.currentSrc = auditionUrlPromise.data && auditionUrlPromise.data.preview_url;
    audio.currentSrc = url
    state.isEnd = false
    audio.onerror = () => {
      axios.get(audio.src)
        .catch(({ response }) => {
          switch (response && response.status) {
            case 404:
            case 410:
              state.playingMusic = {};
              dispatch('musicPlay', music);
              break;
            default:
              break;
          }
        });
    };

    audio.load();
    audio.play();

    // setTimeout(() => {
    //   dispatch('record', music);
    // }, 800);

    clearInterval(state.progressTimer);

    audio.addEventListener('loadedmetadata', () => {
      state.duration = audio.duration;
      state.loading = false;
    });

    audio.addEventListener('ended', () => {
      state.isEnd = true
      state.loading = false;
      state.isPlaying = false;
    });

    state.audio = audio;
    state.audio.volume = state.volume
    state.progressTimer = setInterval(() => {
      if (!state.isPlaying) {
        return false;
      }
      state.waveProgress = audio.currentTime / audio.duration * 100;
      return true;
    }, 200);
  }
}

const mutations = {

  // 根据播放次数限制全部播放按钮
  UPDATE_BTN_STATUS(state, value){
    state.isDisabledplayAllbtn = value
  },
  // 更新全部音乐播放列表
  UPDATE_ORDER_ALLMUSIC(state, value){
    // console.log(value)
    if(state.orderPlayList.length !== 0){
      state.orderPlayList = [];
    }
    state.orderPlayList = value;
  },
  // 更新复制信息的弹框是否打开
  UPDATE_COPY_LINK_VISIBLE(state, value) {
    state.copyInfoVisible = value
  },
  // 更新复制弹框信息
  UPDATE_COPY_LINK_INFO(state, value) {
    state.copyInfo = value
  },
  // 更新音乐播放器是否展示
  UPDATE_MUSIC_CONTROLS_VISIBLE(state, value) {
    state.musicControlsVisible = value
  },
  // 更新音乐播放器状态
  UPDATE_PLAYING(state, value) {
    // console.log(value,'当前播放状态');
    state.isPlaying = value
  },
  // 更新当前播放音乐的信息
  UPDATE_MUSIC_INFO(state, info) {
    // console.log(info);
    if (info && typeof info.is_collected === "undefined") {
      info.is_collected = info.is_collect
    }
    state.tmpMusicInfo = info
  },
  // 替换临时音乐列表
  UPDATE_MUSIC_LIST(state, list) {
    state.tmpMusicList = list
  },
  // 更新全局的临时音乐列表
  UPDATE_LIST_TO_MUSIC_LIST(state, list) {
    state.tmpMusicList = [...list, ...state.tmpMusicList]
  },
  // 新增音乐至临时音乐列表
  ADD_MUSIC_TO_LIST(state, info) {
    const { object_id } = info
    if (
      object_id &&
      !state.tmpMusicList.map((i) => i.object_id).includes(object_id)
    ) {
      state.tmpMusicList.push(info)
    }
  },
  // 更新音乐的信息至音乐列表
  UPDATE_MUSIC_TO_LIST(state, info) {
    const oldList = [...state.tmpMusicList]
    state.tmpMusicList = oldList.map((i) => {
      if (i.object_id === info.object_id) {
        return info
      }
      return i
    })
  },
  // 改变音乐进度
  CHANGE_SEEK_TOMUSIC(state, value){
    state.seek = value
  },
  CHANGE_PROGRESS_CHANGE(state, value){
    state.changeProgressIsChange = value
  },
  // 设置音乐进度
  CHANGE_MUSIC_PROGRESS(state, value) {
    state.musicProgress = value;
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
