<template>
  <div v-if="musicInfo" class="music-audio-container" :class="{ 'is-opened': isShowAudio }">
    <div class="music-main">
      <!-- 音乐基础信息 -->
      <div class="base-music-info">
        <!-- 音乐封面 -->
        <div class="music-logo-container">
          <div class="music-logo">
            <el-image :src="musicInfo.logo" :fit="'cover'">
              <div slot="error" class="image-slot">
                <img src="@/assets/image/default-music-img.png" alt="" />
              </div>
            </el-image>
            <div class="audio-status">
              <img v-if="!playing" src="~@/assets/svg/pause-audio-icon.svg" @click="playAudio" />
              <img v-else src="~@/assets/svg/play-audio-icon.svg" @click="pauseAudio" />
            </div>
          </div>
          <div class="music-logo-bg">
            <img src="~@/assets/svg/record-bg.svg" alt="唱片背景" />
          </div>
        </div>
        <!-- 音乐信息 -->
        <div class="music-title-container">
          <div class="music-name">{{ musicInfo.name }}</div>
          <div class="music-tags">
            {{
              musicInfo.labels &&
              musicInfo.labels.slice(0, 3).map((i) => i.name).join('，')
            }}
          </div>
        </div>
      </div>
      <!-- 音乐进度条 -->
      <div class="audio-press">
        <!-- 音乐进度 -->
        <!-- <div v-if="musicInfo.type == 1 || musicInfo.type == 3" class="progress-time">{{ currentTime | formatTimeBySecond }}</div> -->
        <div class="progress-time">{{ currentTime | formatTimeBySecond  }}</div>
        <div class="progress-audio">
          <el-slider v-if="musicInfo.type == 1 || musicInfo.type == 3" v-model="progress" :show-tooltip="false" :min="0"
            :step="0.1" :max="Number(musicInfo.duration) || 0" @change="handleProgressChange" />
          <!-- <WaveSurfer v-else ref="WaveSurferAudio" :audio-url="waveAudioUrl" :wave-data="waveData" @ready="onReady"
            @error="onError" @finish="onEnded" @seek="handleSeek" /> -->
            <div v-else class="wave-music">
              <WaveImage :music="musicInfo" @waveProgress="handleSetProgress" ></WaveImage>
            </div>
        </div>
        <div class="progress-time">{{ Number(musicInfo.duration) | formatTimeBySecond }}</div>
      </div>
      <!-- 音量进度 -->
      <div class="volume-controller">
        <!-- 音量进度 -->
        <div class="el-icon">
          <img src="~@/assets/svg/volume-icon.svg" alt="" />
        </div>
        <div class="progress-container">
          <el-slider v-model="volumeProgress" :show-tooltip="false" :min="0" :max="1" :step="0.01"/>
        </div>
      </div>

      <!-- 音乐按钮 -->
      <div class="music-buttons-container">
        <el-button v-if="musicInfo.type !== 1"
          :type="musicInfo.is_collected === 1 ? 'text-primary-active' : 'text-primary'" @click="handleAuditCollect">
          <p>
            <span class="icon collect" />
          </p>
          <p>收藏</p>
        </el-button>
        <el-button type="text-primary" @click="handleShare('', musicInfo.object_id, musicInfo.type, musicInfo.name)">
          <p><span class="icon share" /></p>
          <p>分享</p>
        </el-button>
        <el-button type="text-primary" @click="handleDownload(musicInfo)" v-if="!musicInfo.file_url">
          <p><span class="icon download" /></p>
          <p>下载</p>
        </el-button>
        <el-button type="text-primary" @click="handleAddGoodsToCart(musicInfo)" v-if="!musicInfo.file_url">
          <p><span class="icon cart" /></p>
          <p>购买</p>
        </el-button>
      </div>
      <div class="close-show-button" @click="handleHideAudio">
        <el-icon :name="iconName" />
      </div>
    </div>
  </div>
</template>

<script>
import Buttons from '@/layout/mixins/Buttons'
import WaveImage from '@/components/WaveImage'
import { getWavedata } from '@/api/common.js'
import axios from '@/utils/request'
import { getToken } from '@/utils/author'
import waveMixin from '@/mixins/waveMixin';
import { mapState } from 'vuex';

export default {
  name: 'VfAudio',
  data() {
    return {
      progress: 0, // 音乐进度
      volumeProgress: 1, // 音量进度
      id: null,
      mode: null,
      loading: true,
      newPlaying: true, // 播放状态
      musicIndex: 0,
      waveAudioUrl: '', // 波形url
      waveData: [], // 波形图数据
      timer: null
    }
  },
  mixins: [Buttons, waveMixin],
  components: { WaveImage },
  computed: {
    ...mapState('music', [
      'audio'
    ]),
    // 是否展示占位模块
    isShowAudio() {
      return this.$store.state.music.musicControlsVisible
    },
    iconName() {
      return this.isShowAudio ? 'arrow-down' : 'arrow-up'
    },
    musicInfo() {
      return this.$store.state.music.tmpMusicInfo
    },
    objectId() {
      return this.$store.state.music?.tmpMusicInfo?.object_id || null
    },
    playing() {
      return !!this.$store.state.music.isPlaying
    },
    playSongList() {
      return this.$store.state.music.orderPlayList
    },
    isEnd() {
      return this.$store.state.music.isEnd
    },
    type() {
      return this.$store.state.music.audioType
    },
    currentTime() {
      const { duration, waveProgress } = this;
      const curTime = Math.floor(duration * waveProgress / 100);

      return curTime
    },
  },
  watch: {
    // 监听音乐播放完成是否播放下一首
    isEnd: {
      handler(newVal) {
        if(newVal){
          this.progress = this.audio.currentTime
          this.orderPlay()
        }
      },
      immediate: false

    },
    objectId() {
      if (this.playing) {
        this.pauseAudio()
      }
    },
    waveProgress() {
      this.progress = this.audio.currentTime
    },
    // playing: {
    //   handler(newVal) {
    //     console.log('playing?????');
    //     if(this.musicInfo.type!=2){
    //       newVal ? this.playAudio() : this.pauseAudio();
    //     }

    //   },
    //   immediate: false
    // },
    // newPlaying:{
    //   handler(newVal){
    //     newVal ? this.playAudio() : this.pauseAudio();
    //   },
    //   immediate: false
    // },
    musicInfo: {
      handler(newVal, oldVal) {
        // console.log(newVal.type, oldVal,'11111',this.playing);
        // if(newVal.type!=2 && this.playing) this.$store.dispatch('music/musicPause');
        if (this.playing) {
          setTimeout(() => {
            // if(newVal.type!=2) this.$store.dispatch('music/pause');
            this.pauseAudio();
          }, 30)
        }
        if (newVal && newVal.music_id && (oldVal && newVal.music_id !== oldVal.music_id || !oldVal)) {
          this.$nextTick(() => {
            try {
              this.loadAudio(newVal.watermark_file || '' || newVal.watermark_url);
            } catch (error) {
              console.error(error);
            }
          })
        }
      },
      immediate: true
    },
    volumeProgress: {
      handler(newVal) {
        this.setVolums(newVal)
      },
      immediate: false
    }
  },
  beforeDestroy() {
    this.clearTimer();
  },
  methods: {
    // 处理点击波形图初始播放
    handleSetProgress(pos){
      this.setProgress(pos * this.playingMusic.duration / 100);
    },
    /**
     * 处理收藏音乐
     * 2021-12-30 11:45:28
     * @author SnowRock
     */
    async handleAuditCollect() {
      // const { musicInfo } = this
      if (!(getToken() && getToken() !== '')) {
        this.$store.dispatch('user/openLogin')
        return
      }
      await this.handleCollect(this.musicInfo.object_id, this.musicInfo.type, this.musicInfo, false, true)
      setTimeout(()=> {
        this.$store.commit('music/UPDATE_PLAYING', true)
      },50)
    },
    /**
     * 设置音量
     */
    setVolums(val) {
      val = val < 0 ? 0 : val > 1 ? 1 : val;
      if (this.musicInfo) {
        this.setVolumeByBarToItem(val)
      }
    },
    /**
     * 进度发生改变时
     * 2021-12-21 13:14:24
     * @author SnowRock
     */
    handleProgressChange() {
      this.setProgress(this.progress);

    },
    /**
     * 处理隐藏播放器
     * 2021-12-20 18:15:00
     * @author SnowRock
     */
    handleHideAudio() {
      const fn = this.isShowAudio ? 'closeMusicControls' : 'showMusicControls'
      this.$store.dispatch(`music/${fn}`)
    },
    /**
     * 获取波形数据
    */
    async getWavedata(music_id) {
      const param = {
        music_ids: music_id
      }
      const data = await getWavedata(param);
      let waveurl = data.data.waveformhera
      if (data.code == 0 && waveurl) {
        const res = await axios.get(waveurl, {})
        this.waveData = res;
      } else {
        this.waveData = null;
      }
    },
    /**
     * 初始化音频
     */
    async loadAudio(url) {
      const { musicInfo } = this;
      this.progress = 0;
      this.clearTimer()
      if (musicInfo && musicInfo.type == 2) {
        await this.getWavedata(this.musicInfo.music_id)
        this.waveAudioUrl = url
      }
    },
    /**
     * 重新播放
     */
    replay() {
      const { musicInfo } = this;
      if (musicInfo && (musicInfo.type == 1 || musicInfo.type == 3)) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.playAudio();
          }, 500);
        })
      }
    },
    /**
     * 播放器播放音乐
     * 2021-12-21 13:03:02
     * @author SnowRock
     */
    playAudio() {
      const { musicInfo } = this;
      // if (musicInfo && (musicInfo.type == 1 || musicInfo.type == 3)) {
      //   this.playAudioSlider()
      // } else {
      //   console.log(2222);
      //   this.onPlayOrPause()
      // }
      if(musicInfo){
        this.onPlayOrPause()
      }
    },
    /**
     * 音乐波形图播放
     */
    // handleWaveSurferPlay() {
    //   this.$refs.WaveSurferAudio.initPlaying = true
    //   this.$refs.WaveSurferAudio.play();
    //   this.onPlay()
    //   this.getAudioStatus();
    // },
    /**
    * 暂停音乐
   */
    pauseAudio() {

      const { musicInfo } = this;
      // console.log(musicInfo.type,'pauseAudio......');
      // if (musicInfo && (musicInfo.type == 1 || musicInfo.type == 3)) {
      //   this.pauseAudioSlider()
      // } else {
      //   console.log(1111);
      //   this.onPlayOrPause()
      // }
      if(musicInfo){
        this.onPlayOrPause()
      }
    },
    /**
     * 判断加载完毕是否为自动播放或者暂停
     */
    onReady(playing) {
      if (this.musicInfo && (this.musicInfo.type !== 1 || this.musicInfo.type !== 3)) {
        this.$nextTick(() => {
          playing ? this.playAudio() : this.pauseAudio();
          this.$store.commit('music/UPDATE_PLAYING', playing)
        })
      }
    },
    /**
     * 播放音频
     */
    onPlay() {
      this.$store.commit('music/UPDATE_PLAYING', true)
    },
    /**
     * 暂停音频
     */
    onPause() {
      this.$store.commit('music/UPDATE_PLAYING', false)
    },
    /**
     * 播放结束
     */
    onEnded() {
      if (this.playSongList.length > 1) {
        this.orderPlay();
        this.$store.commit('music/UPDATE_PLAYING', true)
      } else {
        this.$store.commit('music/UPDATE_PLAYING', false)
        this.clearTimer()
      }
    },
    /**
     * 获取音频播放状态信息
    */
    getAudioStatus() {
      // const { musicInfo } = this
      this.clearTimer();
      this.timer = setInterval(() => {
      }, 100);
    },
    /**
     * 清除定时器
    */
    clearTimer() {
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }
    },
    /**
     * 检查音频是否有可以使用加载完成数据
     *
     * 0 = HAVE_NOTHING - 没有关于音频是否就绪的信息
     * 1 = HAVE_METADATA - 关于音频就绪的元数据
     * 2 = HAVE_CURRENT_DATA - 关于当前播放位置的数据是可用的，但没有足够的数据来播放下一帧/毫秒
     * 3 = HAVE_FUTURE_DATA - 当前及至少下一帧的数据是可用的
     * 4 = HAVE_ENOUGH_DATA - 可用数据足以开始播放
    */
    checkReliable() {
      let status = this.audioContent ? this.audioContent.readyState : 0
      if (status <= 3) {
        this.loading = true;
      } else {
        this.loading = false;
      }
    },
    /**
     * 顺序播放全部
     */
    orderPlay() {
      if (this.playSongList.length) {
        // 判断当前播放歌曲是否是从第一首开始的
        if (this.musicInfo.music_id !== this.playSongList[0].music_id) {
          this.$store.dispatch('music/changeMusicInfo', this.playSongList[0])
        }
        // for(var i = 0; i< this.playSongList.length; i++){
        // const item = this.playSongList[i]
        // if(item.music_id == this.musicInfo.music_id || !this.musicInfo.music_id){
        this.musicIndex++
        if (this.musicIndex === this.playSongList.length - 1) {
          this.musicIndex = 0;
          return;
        }
        this.handlePlayerAll(this.playSongList[this.musicIndex], 2) // 限制音乐/音效试听播放次数
        // return;
        // }
        // }
      }
    },
    onError() {
      this.$store.dispatch('music/musicPause');
      this.clearTimer()
    }
  }
}
</script>

<style lang="scss" scoped>
.music-audio-container {
  position: fixed;
  bottom: -73px;
  width: 1360px;
  height: 83px;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.4s ease;
  padding-top: 10px;
  z-index: 999;
  font-family: PingFangSC-Medium, PingFang SC;

  &.is-opened {
    bottom: 20px;

    .base-music-info .music-logo-container .music-logo {
      bottom: 0;
    }
  }

  .music-main {
    width: 100%;
    height: 100%;
    background-color: #fff;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
    border-radius: 41px;
    display: flex;
    align-items: center;
  }

  .base-music-info {
    display: flex;
    align-items: center;
    margin-right: 59px;

    .music-logo-container {
      position: relative;
      margin-right: 24px;

      .music-logo {
        width: 86px;
        height: 86px;
        border-radius: 8px;
        position: absolute;
        left: 0;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
        overflow: hidden;
        z-index: 3;
        transition: all 0.4s ease;
        bottom: -15px;
        cursor: pointer;

        /deep/ .el-image {
          width: 100%;
          height: 100%;
        }

        img {
          width: 100%;
          height: 100%;
        }

        .audio-status {
          width: 86px;
          height: 86px;
          left: 0;
          background: rgba(0, 0, 0, 0.2);
          border-radius: 8px;
          position: absolute;
          top: 0;
          z-index: 2;
          text-align: center;
          cursor: pointer;

          img {
            width: 23px;
            height: 26px;
            margin-top: 30px;
          }
        }
      }

      .music-logo-bg {
        position: relative;
        z-index: 2;
        width: 73px;
        height: 73px;
        margin-left: 39px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .music-title-container {
      width: 200px;

      .music-name {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        line-height: 25px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .music-tags {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        line-height: 20px;
        margin-top: 2px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .audio-press {
    width: 514px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 43px;
    font-weight: 500;
    color: rgba(51, 51, 51, 0.6);
    line-height: 17px;

    .progress-container {
      width: 406px;
      display: flex;
    }

    .progress-time {
      flex: 1;
      text-align: center;
    }

    .progress-audio {
      width: 406px;
      // height: 42px;
      // display: flex;
      // align-items: center;
      .wave-music{
        width: 100%;
        height: 46px;
      }
    }
  }

  .volume-controller {
    display: flex;
    align-items: center;

    .el-icon {
      height: 20px;

      img {
        @include square(20px);
      }
    }

    .progress-container {
      width: 96px;
      margin-left: 11px;
      height: 34px;

      /deep/ {
        .el-slider {
          &:before {
            display: none;
          }
        }
      }
    }
  }

  .music-buttons-container {
    margin-right: 63px;
    flex: 1;
    text-align: right;
    justify-content: space-between;
    margin-left: 60px;

    /deep/ {
      .el-button {
        &:hover {
          .icon {
            &.collect {
              background-image: url('~@/assets/svg/collect-icon-audio-select.svg');
            }
          }
        }

        &.el-button--text-primary-active {
          .icon {
            &.collect {
              background-image: url('~@/assets/svg/collect-icon-audio-select.svg');
            }
          }
        }

        &+.el-button {
          margin-left: 20px;
        }
      }

      .icon {
        @include square(20px);

        &.collect {
          background-image: url('~@/assets/svg/collect-icon-audio.svg');
          //
          //&:hover, &:active, &:visited {
          //  background-image: url('~@/assets/svg/collect-icon-audio-select.svg');
          //}
        }

        &.share {
          background-image: url('~@/assets/svg/share-icon-audio.svg');
          //
          //&:hover, &:active, &:visited {
          //  background-image: url('~@/assets/svg/share-icon-audio-select.svg');
          //}
        }

        &.download {
          background-image: url('~@/assets/svg/download-icon-audio.svg');

          //&:hover, &:active, &:visited {
          //  background-image: url('~@/assets/svg/download-icon-audio-select.svg');
          //}
        }

        &.cart {
          background-image: url('~@/assets/svg/cart-icon-audio.svg');
          //&:hover, &:active, &:visited {
          //  background-image: url('~@/assets/svg/cart-icon-audio-select.svg');
          //}
        }
      }
    }
  }

  /deep/ {
    .el-slider {
      width: 100%;

      .el-slider__runway {
        height: 2px;
        background-color: #282828;
      }

      .el-slider__bar {
        height: 2px;
      }

      .el-slider__button-wrapper {
        top: -10px;
        width: 8px;
        height: 8px;

        .el-slider__button {
          width: 8px;
          height: 8px;
          background-color: #E52A0D;
        }
      }
    }

    .el-button {
      &.el-button--text-primary {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        line-height: 17px;
        margin-top: 3px;
      }

      [class^='el-icon'] {
        font-size: 20px;
      }
    }
  }

  .close-show-button {
    width: 40px;
    height: 16px;
    line-height: 16px;
    position: absolute;
    right: 40px;
    top: -4px;
    z-index: -1;
    background-color: #fff;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
    text-align: center;
    font-weight: bold;
    border-radius: 4px 4px 0 0;
    cursor: pointer;
  }
}
</style>
