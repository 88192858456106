const AppMain = () => import('@/views/index')

export default {
  path: '/activity',
  name: 'Activity',
  component: AppMain,
  redirect: '/activity/active',
  children: [
    // 助力活动
    {
      path: '/activity/active',
      name: 'Active',
      meta: {
        isLogin: false
      },
      component: () => import('@/views/activity/index.vue')
    },
    // 分享裂变活动
    {
      path: '/activity/shareActive',
      name: 'shareActive',
      meta: {
        isLogin: false
      },
      component: () => import('@/views/activity/shareActive.vue')
    },
    // 创作者扶持计划活动
    {
      path: '/activity/creatorActive',
      name: 'creatorActive',
      meta: {
        isLogin: false
      },
      component: () => import('@/views/activity/creatorActive.vue')
    },
    // H5活动页面
    {
      path: '/activity/activeMobile',
      name: 'activeMobile',
      meta: {
        isLogin: false
      },
      component: () => import('@/views/activity/activeMobile.vue')
    },
    {
      path: '/activity/shareMobile',
      name: 'shareMobile',
      meta: {
        isLogin: false
      },
      component: () => import('@/views/activity/shareMobile.vue')
    },
    // 露营活动
    {
      path: '/camping',
      name: 'camping',
      meta: {
        isLogin: false
      },
      component: () => import('@/views/activity/camping/index.vue')
    },
    // 教师节
    {
      path: '/teacher-day',
      name: 'TeacherDay',
      meta: {
        isLogin: false
      },
      component: () => import('@/views/activity/teacher/index.vue')
    },
    // 中秋节
    {
      path: '/mid-autumn-festival',
      name: 'midAutumnFestival',
      meta: {
        isLogin: false
      },
      component: () => import('@/views/activity/mid-autumn-festival/index.vue')
    },
    // 国庆节
    {
      path: '/nationalDay',
      name: 'nationalDay',
      meta: {
        isLogin: false
      },
      component: () => import('@/views/activity/nationalDay')
    },
    // 双十一
    {
      path: '/activityFor618',
      name: 'activityFor618',
      meta: {
        isLogin: false
      },
      component: () => import('@/views/activity/dobuleEleven')
    },
    {
      path: '/doubleElevenH5',
      name: 'doubleElevenH5',
      meta: {
        isLogin: false
      },
      component: () => import('@/views/activity/dobuleEleven/h5')
    },
    // 免费专区购买
    {
      path: '/limitPackage',
      name: 'limitPackage',
      meta: {
        isLogin: true
      },
      component: () => import('@/views/limitExempt/vip/index')
    },
    // 免费专区详情
    {
      path: '/limitDetails',
      name: 'limitDetails',
      meta: {
        isLogin: true
      },
      component: () => import('@/views/limitExempt/detail/index')
    },
  ]
}
