export default{
    namespaced: true, // 模块命名
    state:{
        showHelpDialog: false,
        showSuccessDialog: false, // 支付成功弹窗
        banner_id: null,
        musicInfo: {
            name: '', // 音乐名称
            music_src: '', // 音乐地址
            music_id: '', // 音乐id
            duration: 0, // 音乐时长
            logo: '', // 音乐封面
        },
        isPlay: false,  // 是否播放
        currentTime: 0 // 当前播放进度
    },
    actions:{
        setDialogHelpstatus(store, val){
            store.commit('SET_DIALOGHELPSTATUS', val)
        },
        setDialogSuccess(store, val){
            window.localStorage.setItem('showSuccessDialog', val)
            store.commit('SET_DIALOGSUCCESS', val)
        },
        setBannerid(store, val){
            store.commit('SET_BANNERID', val)
        },
        updateCurrentMusic(store, val){
            store.commit('UPDATE_CURRENTMUSIC', val)
        },
        // 更新播放状态
        updatePlaystatus(store, val){
            console.log(val, '播放状态')
            store.commit('UPDATE_PLAYING', val)
        },
        updateCurrentTime(store, val){
            store.commit('UPDATE_CURRENTTIME', val)
        },
        // 播放音乐操作
        play(store) {
            store.commit('UPDATE_PLAYING', true)
        },
        // 停止播放音乐
        pause(store) {
            store.commit('UPDATE_PLAYING', false)
        },
    },
    mutations:{
        /**
         * 设置支付成功弹窗状态
         * @param {*} state
         * @param {*} val
         */
        SET_DIALOGSUCCESS(state, val){
            state.showSuccessDialog = val
        },
        /**
         * 设置助力弹窗状态
         * @param {*} state
         * @param {*} val
         */
        SET_DIALOGHELPSTATUS(state,val){
            state.showHelpDialog = val;
        },
        /**
         * 设置banner ID
         * @param {*} state
         * @param {*} val
         */
        SET_BANNERID(state, val){
            console.log(val,'bannerid')
            state.banner_id = val;
        },
        /**
         * 更新当前播放音乐信息
         * @param {*} state
         * @param {*} val
         */
        UPDATE_CURRENTMUSIC(state, val){
            state.musicInfo = val
        },
        /***
         * 更新当前音乐播放状态
         */
        UPDATE_PLAYING(state, val){
            state.isPlay = val
        },
        /**
         * 更新播放进度
         * @param {*} store
         * @param {*} val
        */
        UPDATE_CURRENTTIME(state, val){
            state.currentTime = val
        }
    },
    getters:{

    }
}
