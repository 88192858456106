<template>
  <el-dialog
    :visible="dialogVisible"
    class="dialog-self"
    width='80%'
    :destroy-on-close="true"
    append-to-body
    :before-close="handleCancel">
    <div class="dialog-title-self" slot="title">
      <div class="title">提示</div>
    </div>
    <div class="tips-container">
      <div v-if="downloadTipsType === 1">
        当日下载次数使用完：今日下载数量已达上限，可明天继续下载或
        <a class="el-button--text" @click="handleOpenServe">联系客服</a>。
      </div>
      <div v-if="downloadTipsType === 2">
        会员下载次数使用完：您套餐内下载次数已消耗完毕，可
        <a class="el-button--text" @click="handleBuyPackage">重新购买套餐</a>。
      </div>
      <div v-if="downloadTipsType === 3">
        今日下载数量剩余{{ dayCount }}次，请重新勾选，也可明天继续下载或
        <a class="el-button--text" @click="handleOpenServe">联系客服</a>。
      </div>
      <div v-if="downloadTipsType === 2">
        会员下载次数剩余{{ packageCount }}次，请重新勾选，也可
        <a class="el-button--text" @click="handleBuyPackage">重新购买套餐</a>或
        <a class="el-button--text" @click="handleOpenServe">联系客服</a>。
      </div>
    </div>
    <div slot="footer" class="right">
      <el-button type="dark" @click="handleCancel">重新选择套餐</el-button>
      <el-button type="primary" @click="handleClose">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {}
  },
  computed: {
    // isApp(){
    //   const ua = navigator.userAgent;
    //   return ua.match(/(iPhone|iPad|Android|ios)/i);
    // },
    dialogVisible() {
      return this.$store.state.download.dialogVisibleForTips
    },
    downloadTipsType() {
      return this.$store.state.download.tipsType
    },
    dayCount() {
      return this.$store.state.download.dayCount
    },
    packageCount() {
      return this.$store.state.download.packageCount
    }
  },
  methods: {
    /**
     * 处理取消
     * 2021-12-21 17:41:04
     * @author SnowRock
     */
    handleCancel() {
      this.handleOpenChooseDialog()
      this.handleClose()
    },
    /**
     * 处理取消
     * 2021-12-21 17:41:04
     * @author SnowRock
     */
    handleOpenChooseDialog() {
      this.$store.dispatch('download/changeChoosePackageVisible', true)
    },
    /**
     * 处理关闭弹框
     * 2021-12-21 17:41:04
     * @author SnowRock
     */
    handleClose() {
      this.$store.dispatch('download/changeTipsVisible', false)
    },
    /**
     * 打开客服
     */
    handleOpenServe() {
      // TODO 打开套餐页面
      console.log('打开客服')
    },
    /**
     * 打开套餐页面
     */
    handleBuyPackage() {
      // TODO 打开套餐页面
      this.handleClose()
    }
  }
}
</script>
<style lang="scss" scoped>
.tips-container {
  color: rgba(51, 51, 51, 0.8);
  padding-left: 30px;
  margin-bottom: 10px;
  word-break: break-all;
  button {
    display: inline;
    padding: 0 !important;
    margin: 0 !important;
    white-space: pre-wrap;
    word-break: break-all;
  }
}
</style>
