const AppMain = () => import('@/views/index')

export default {
  path: '/share',
  name: 'Share',
  component: AppMain,
  children: [
    {
      path: '/',
      name: 'ShareResult',
      component: () => import('@/views/share/result.vue')
    }
  ]
}
