const AppMain = () => import('@/views/index')

export default {
  path: '/cart',
  name: 'Cart',
  component: AppMain,
  redirect: '/cart/list',
  children: [
    {
      path: '/cart/list',
      name: 'CartList',
      meta: {
        isLogin: true,
        hasMember: true
      },
      component: () => import('@/views/cart/index.vue')
    }
  ]
}
