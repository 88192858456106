import request from '@/utils/request'

/**
 * 获取音乐信息
 * 2021-12-20 14:34:17
 * @author SnowRock
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function fetchMusicInfo(params) {
  return request.post('/fontend/LabelList/music-detail', params, {})
}

/**
 * 获取搜素结果页面信息
 * 2021-12-23 17:37:48
 * @author SnowRock
 */
export function fetchSearchWordList(params) {
  return request.post('/fontend/Music/searchHome', params, {})
}

/**
 * 全部歌单
 * @param params
 * @param params.page 页码
 * @param params.limit 条数
 */
export function fetchPlaylist(params) {
  return request.post('/tjz/Music/index-song-list', params, {})
}

/**
 * 歌单内音乐列表
 * @param params
 * @param params.playlist_id 歌单id
 */
export function playlistMusic(params) {
  return request.post('/fontend/Music/songMusicList', params, {})
}

/**
 * 全部音乐
 * @param params
 * @param params.page 页码
 * @param params.limit 条数
 * @param params.label_id 标签id
 */
export function fetchMusicList(params) {
  return request.post('/fontend/music/music-list', params, {})
}

/**
 * 音乐热搜词
 */
export function fetchMusicHotSearch(params) {
  return request.post('/fontend/hotsearch/list', params, {})
}

/**
 * 全部音乐标签
 */
export function fetchMusicTags() {
  return request.get('/fontend/music/label-list')
}

/**
 * 获取全部音乐歌单列表
 * 2021-12-30 10:56:01
 * @author SnowRock
 */
export function fetchPlaylistList(params) {
  return request.post('/tjz/Music/IndexSongList', params)
}

/**
 * 获取是否能播放试听
 * @param params
 * @returns {*}
 */
export function fetchPlayTimes(params) {
  return request.post('/fontend/check/limits', params)
}


export function playlistMusicDetail(params) {
  return request.post('/fontend/music/sharelist', params, {})
}
// 音乐详情信息
export function fetchMusicDetail(params) {
  return request.get(`/fontend/music/music-info/${params}`)
}
// 相似音乐
export function fetchMusicSimilar(params) {
  return request.get(`/fontend/music/music-similar/${params}`)
}

// 零售下单
export function addMusicOrder(params) {
  return request.post('/fontend/retail/place-order', params, {})
}
// 重新支付
export function reAddMusicOrder(params) {
  return request.post('/fontend/retail/readd-order', params, {})
}
// 再次购买
export function repeatMusicOrder(params) {
  return request.post('/fontend/retail/repeat-order', params, {})
}
// 获取零售支付二维码
export function fetchMusicPayCode(params) {
  return request.post(`/fontend/payment/retail-pay`, params, {})
}
// 获取零售支付二维码
export function fetchPayCodeResult(params) {
  return request.post(`/fontend/pay/lookPayStatusRetail`, params, {})
}


// https://tapi.playlistmusic.com.cn/fontend/music/music-info/7300e98fbe25f000001
