/**
 * 会员中心
 * 2021-12-17 16:15:27
 * @author SnowRock
 */
import request from '@/utils/request'

/**
 * 个人授权提交(与公司授权提交接口一致)
 * 2021-12-17 16:16:11
 * @author SnowRock
 */
export function authInfoForPeople(params) {
  return request.post('/fontend/user/addContract', params, {})
}

/**
 * 公司授权提交(与个人授权提交接口一致)
 * 2021-12-17 16:16:11
 * @author SnowRock
 */
export function authInfoForCompany(params) {
  return request.post('/fontend/user/addContract', params, {})
}

/**
 * 授权下载 音乐
 * 2021-12-17 16:16:11
 * @author SnowRock
 */
export function completeAuthForSingeMusic(params) {
  return request.post('/fontend/certificate/certificate', params, {})
}

/**
 * 申请开票
 * 2021-12-17 17:09:07
 * @author SnowRock
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function applyInvoiceForDownload(params) {
  return request.post('/fontend/invoice/apply', params, {})
}
// 零售申请发票
export function applyRetailInvoiceForDownload(params) {
  return request.post('/fontend/invoice/retail-apply', params, {})
}


/**
 * 获取会员信息列表
 * 2021-12-20 13:40:48
 * @author SnowRock
 * @param params
 * @param params.mode 类型：2音乐 3音效 4视频
 * @param params.page 页数
 */
export function fetchMemberCollectList(params) {
  return request.post('/fontend/user/collect', params, {})
}

/**
 * 获取授权下载列表
 * 2021-12-24 16:59:30
 * @author SnowRock
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function fetchMemberCertificatesList(params) {
  return request.post('/fontend/user/certificates', params, {})
}
// 获取零售授权列表
export function fetchRetailCertificatesList(params) {
  return request.post('/fontend/user/certificate-list', params, {})
}

/**
 * 获取协议内容信息
 * 2021-12-28 14:01:47
 * @author SnowRock
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function fetchMemberContractInfo(params) {
  return request.post('/fontend/certificate/contract', params, {})
}

/**
 * @description 获取协议地址
 */
export function fetchMemberContractPdf(id) {
  window.open(`${process.env.VUE_APP_BASE_URL}/fontend/license/contract/${id}`)
}

/**
 * 查询活动期间注册剩余次数
 * 2022-1-25 17:18:30
 */
export function getRegisterNum() {
  return request.post('/fontend/ad/get_last_register_num')
}

/**
 * 获取推广用户列表
 * 2022-2-22 16:23:30
 */
export function getUserlist(params) {
  return request.post('/fontend/user/shareUserList', params, {})
}

/**
 * 生成分享链接
 * 2022-2-22 16:24:30
 */
export function createSharelink(params) {
  return request.post('/fontend/user/shareLink', params, {})
}

/**
 * 用户点击助力
 */
export function userIncline(params) {
  return request.post('/fontend/user/incline', params, {})
}

/**
 * 获取即将到期的会员
 * 2022-04-21 13:30:38
 * @author SnowRock
 * @param params
 * @returns {*}
 */
export function fetchExpiringMembership(params) {
  return request.post('/fontend/check/vip', params, {})
}

/**
 * 获取开通会员用户购买信息
 * @param {*} params
 */
export function getMemberbuyInfo(params) {
  return request.post('/fontend/order/newOrderUser', params, {})
}

/**
 * 兑换码兑换
 * @param {*} params
 */
export function exchangeGenCode(params) {
  return request.post('/fontend/exchange/genCode', params, {})
}

/**
 * 用户兑换码列表
 * @param {*} params
 */
export function exchangeList(params) {
  return request.post('/fontend/exchange/list', params, {})
}

/**
 * @description 批量授权
 */
export function batchCertificate(params) {
  return request.post('/fontend/certificate/batchCertificate', params, {})
}

// 零售订单列表 pay_status 0待支付 2已超时 4已支付
export function feachRetailOrderList(params) {
  return request.post('/fontend/user/order-list', params, {})
}
// 零售订单详情
export function feachRetailOrderInfo(params) {
  return request.post('/fontend/retail/order-info', params, {})
}


// 零售订单激活授权
export function batchRetailCertificate(params) {
  return request.post('/fontend/certificate/retail-certificate', params, {})
}
