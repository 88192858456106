export function timeFormat(val){
    return val.toString().split('')
}

export function formDateTime(time){
    const date = time ?  new Date(time) : new Date();
    const y = date.getFullYear();
    const m = date.getMonth()+1 < 10 ? '0' + (date.getMonth()+1) : (date.getMonth()+1);
    const d = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
    const hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
    const minutes = date.getMinutes() < 10 ? '0' +date.getMinutes() : date.getMinutes()
    const second = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
    return y + '-'+ m + '-' + d + ' ' + hours + ':' + minutes +':'+second
}
