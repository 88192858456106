<template>
  <el-dialog
    class="dialog-for-expiring"
    :class="device"
    :visible.sync="dialogVisible"
    width="600px"
    :top="undefined"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    append-to-body
  >
    <div slot="title">
      尊贵的PlayList会员用户：
    </div>
    <div>
      <div class="info-memberships">
        您的<span v-for="(i, j) in membershipExpiredList" :key="j">
          【{{ i.goods_name }}】
        </span>已到期，未激活授权书的素材不可使用，如您需要，请继续开通会员使用。
        <p>PlayList将持续为您提供优质素材。</p>
      </div>

    </div>
    <div class="btns-container">
      <el-button type="empty" @click="handleBeforeClose">我知道了</el-button>
      <el-button type="primary" @click="handleBuyPackage">开通会员</el-button>
    </div>
  </el-dialog>
</template>

<script>

export default {
  name: "ExpiredMembershipDialog",
  computed: {
    // 即将过期会员列表
    membershipExpiredList() {
      return this.$store.state.user.membershipExpiringList.filter(i => i.is_expired);
    },
    device() {
      return this.$store.state.user.device
    },
    dialogVisible: {
      get() {
        return this.$store.state.user.expiredDialogVisible;
      },
      set(val) {
        return this.$store.commit("user/UPDATE_EXPIRED_DIALOG_VISIBLE", val);
      }
    }
  },
  mounted() {
  },
  methods: {
    handleBeforeClose() {
      this.dialogVisible = false;
    },
    handleBuyPackage() {
      this.dialogVisible = false;
      this.$router.push('/package')
    }
  }
};
</script>

<style lang="scss" scoped>
.dialog-for-expiring {
  &.mobile {
    /deep/ {
      .el-dialog {
        width: 76.6667vw!important;
        font-size: .32rem;
        border-radius: 3.2vw;
        background-image: url("./assets/bg-expired.png");
        background-size: 100% auto;
        background-position: 0 -0.1vw;
        background-repeat: no-repeat;
        margin-top: calc(50vh - 50%)!important;

        &:before {
          width: 100%;
          content: '温馨提示';
          display: block;
          position: absolute;
          top: 6.7vw;
          text-align: center;
          font-size: 5.3vw;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
        }

        .el-dialog__header {
          font-size: 4.2vw;
          padding-top: 22vw;
          padding-left: 5.3vw;
          padding-right: 5.3vw;
          padding-bottom: 1.6vw;
        }

        .info-memberships {
          font-size: 4.2vw;
          line-height: 5.3vw;
          font-weight: 400!important;
          span {
            line-height: 5.3vw;
          }
          p {
            margin: 0;
          }
        }
        .el-dialog__body {
          padding-top: 0;
          padding-left: 5.3vw;
          padding-right: 5.3vw;
          padding-bottom: 4.2vw;
          line-height: 5.3vw;
        }

        .btns-container {
          text-align: center;
          padding-top: 5.1vw;
          margin: 0;

          .el-button {
            width: 26.666vw;
            height: 10.6vw;
            line-height: 10.2vw;
            border-radius: 0.533vw;
            font-size: 4vw;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            text-align: center;
            padding: 0;

            &+.el-button {
              margin-left: 5.3vw;
            }
            &.el-button--empty {
              background: #F5F6FA;
              border-color: #F5F6FA;
              color: #999999;
            }
          }
        }
      }
    }
  }

  /deep/ {
    .el-dialog__header {
      padding: 23px 36px 0px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
      line-height: 28px;
    }

    .el-dialog__body {
      padding: 60px;
      padding-top: 18px;
      padding-bottom: 18px;
    }

    .el-button {
      padding: 0 24px;
      height: 36px;
      line-height: 36px;
      vertical-align: top;
      box-sizing: border-box;
    }
    .el-button--empty {
      border: 1px solid #E52A0D;
      border-radius: 2px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #E52A0D;
    }
  }

  .info-memberships {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 18px;
    margin-bottom: 7px;
    color: #333333;

    span {
      color: #E52A0D;
      line-height: 24px;
    }
    p {
      margin-top: 12px;
    }
  }

  .btns-container {
    text-align: right;
    padding-top: 30px;
    padding-bottom: 14px;
    margin-right: -24px;
  }
  .info-material-authorization {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 24px;

    .info-material-authorization-title {

    }

    .info-content {
      width: 480px;
      max-height: 270px;
      background: #FFFFFF;
      margin-top: 8px;
      border: 1px solid #D9D9D9;
      box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.03);
      border-radius: 3px;
      padding: 12px 16px 2px;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 12px;
      opacity: 0.8;

      li {
        margin-bottom: 13px;
      }
    }
  }
}
</style>
