const AppMain = () => import('@/views/index')

export default {
  path: '/',
  component: AppMain,
  redirect: '/home',
  children: [
    {
      path: '/home',
      name: 'home',
      meta: {
        isLogin: false
      },
      component: () => import('@/views/H5/index.vue')
    },
    // 移动端歌单列表
    {
      path: '/mobile/music',
      name: 'music',
      component: () => import('@/views/H5/musicList.vue')
    },
    {
      path: '/mobile/musiclist',
      name: 'musiclist',
      component: () => import('@/views/H5/music.vue')
    },
    {
      path: '/mobile/videolist',
      name: 'videolist',
      component: () => import('@/views/H5/video.vue')
    },
    {
      path: '/mobile/videoInfo',
      name: 'videoInfo',
      component: () => import('@/views/H5/videoInfo.vue')
    },
    {
      path: '/mobile/voicelist',
      name: 'voicelist',
      component: () => import('@/views/H5/voice.vue')
    },
    {
      path: '/mobile/ailist',
      name: 'ailist',
      component: () => import('@/views/H5/ai.vue')
    },
    // 移动端开通会员
    {
      path: '/mobile/member',
      name: 'member',
      component: () => import('@/views/H5/member.vue')
    },
    // 创作者扶持活动移动端
    {
      path: '/mobile/creatorActive',
      name: 'creatorActive',
      component: () => import('@/views/H5/creatorActive.vue')
    }
  ]
}
