import { featchMessagCount } from '../../api/message'
// 消息通知
const state = {
  messageCount: 0
}

const actions = {
  // 更新未读消息数量
  async updateMessageCount(store) {
    try {
      const { data } = await featchMessagCount({is_open: 0})
      store.commit('UPDATE_MESSAGE_CUNOT', data.total)
    } catch (e) {
      console.log(e);
    }
  }
}

const mutations = {
  // 更新未读消息数量
  UPDATE_MESSAGE_CUNOT(state, count) {
    state.messageCount = count
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
