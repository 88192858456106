const Index = () => import('@/views/index')

export default {
  path: '/index',
  component: Index,
  redirect: '/playList',
  children: [
    {
      path: '/playList',
      name: 'home',
      // newIndex
      component: () => import('@/views/home/index'),
      meta: {
        pageTitle: 'PlayList素材网站-视频工作者正版素材平台',
        content: {
          description: 'PlayList视频创作者素材网站。涵盖音乐素材、音效、视频素材、图片素材、AI语音转换，海量正版素材畅选,满足各类个人、商用素材需求。',
          keywords: 'playlist,playlist素材,素材网站,素材下载,正版素材,商用素材,素材库,背景音乐,音效,视频素材,图片素材,ai语音'
        }
      }
    },
    {
      path: '/about',
      name: 'About',
      component: () => import('@/views/about')
    },
    {
      path: '/licensePage',
      name: 'LicensePage',
      component: () => import('@/views/license')
    },
    {
      path: '/music/detail/:id',
      name: 'MusicDetail',
      component: () => import('@/views/home/detail')
    },
    {
      path: '/help',
      name: 'Help',
      component: () => import('@/views/help')
    },
    {
      path: '/music',
      name: 'music',
      component: () => import('@/views/music'),
      meta: {
        keepAlive: true,
        pageTitle: '音乐素材曲库-PlayList素材网站_正版音乐素材授权',
        content: {
          description: 'PlayList音乐素材库，精选各类风格正版背景音乐素材，满足广告宣传、自媒体、直播、活动公播等各种场景的背景音乐、商用音乐授权需求。',
          keywords: 'playlist,playlist素材,音乐素材网站,正版音乐,商用音乐,背景音乐,音乐素材库,音乐下载,版权音乐'
        }
      }
    },
    {
      path: '/similar/:id',
      name: 'Similar',
      meta: {
        keepAlive: false
      },
      component: () => import('@/views/music/similar/index')
    },
    {
      path: '/music-detail/:id',
      name: 'musiceDetail',
      meta: {
        keepAlive: false
      },
      component: () => import('@/views/music/detail/detail')
    },
    {
      path: '/music-down-detail/:id',
      name: 'musiceDownDetail',
      meta: {
        keepAlive: false
      },
      component: () => import('@/views/music/detail/downDetail')
    },
    {
      path: '/music/order-result/:id',
      name: 'musiceOrderResult',
      meta: {
        keepAlive: false
      },
      component: () => import('@/views/music/result/index')
    },
    {
      path: '/soundEffect',
      name: 'soundEffect',
      component: () => import('@/views/soundEffect'),
      meta: {
        pageTitle: '音效素材库-PlayList素材网站_正版音效库',
        content: {
          description: 'PlayList音效素材库，精选各类场景音效，正版音效素材授权，涵盖转场音效、环境音效、影视音效、游戏电子音效等，海量音效素材下载。',
          keywords: 'playlist,playlist素材,音效素材网站,音效下载,正版音效,商用音效,音效,音效素材库'
        }
      }
    },
    {
      path: '/video',
      name: 'video',
      component: () => import('@/views/video'),
      meta: {
        keepAlive: true,
        pageTitle: '高清视频素材库-PlayList素材网站_正版商用视频素材',
        content: {
          description: 'PlayList视频素材,正版视频素材授权平台,高清视频素材下载,适用各类商用视频素材需求。',
          keywords: 'playlist,playlist素材,视频素材网,视频素材,高清视频素材,正版视频素材,商用视频素材'
        }
      }
    },
    {
      path: '/video/detail',
      name: 'videoDetail',
      component: () => import('@/views/video/detail'),
      meta: {
        keepAlive: false
      }
    },
    {
      path: '/picture',
      name: 'picture',
      meta: {
        keepAlive: true,
        pageTitle: '高清图片素材库-PlayList素材网站_高清正版图片素材',
        content: {
          description: 'PlayList图片素材,正版高清图片素材网站,提供实拍照片、海报、科技、城市、商务、风景、美食、家居图片素材,背景图片设计素材大全可商用下载。',
          keywords: 'playlist,playlist素材,高清图片,图库,背景图片,设计素材,风景图片,图片大全,图片素材'
        }
      },
      component: () => import('@/views/picture')
    },
    {
      path: '/picture/detail',
      name: 'pictureDetail',
      meta: {
        keepAlive: false
      },
      component: () => import('@/views/picture/detail')
    },
    {
      path: '/aiVoice',
      name: 'aiVoice',
      meta: {
        keepAlive: false,
        pageTitle: 'AI语音-文字转语音-PlayList素材网站-语音合成',
        content: {
          description: 'PlayList网站AI语音,提供在线文字转语音，文字语音合成服务,10类人声语音转换，可快速对文本进行语音转换配音。',
          keywords: 'playlist,ai语音,文字转语音,短视频配音,语音合成,文本转语音,文字朗读'
        }
      },
      component: () => import('@/views/aiVoice')
    },
    {
      path: '/all-play-list',
      name: 'Playlist',
      meta: {
        keepAlive: false
      },
      component: () => import('@/views/playlist')
    },
    {
      path: '/identify',
      name: 'identify',
      meta: {
        keepAlive: false
      },
      component: () => import('@/views/identify')
    },
    {
      path: '/limitExempt',
      name: 'limitExempt',
      meta: {
        keepAlive: false,
        pageTitle: '限时免费素材专区-PlayList素材网站-正版素材',
        content: {
          description: 'PlayList免费素材专区，精选音乐素材、音效、视频素材，限时免费下载，正版无水印素材。',
          keywords: 'playlist,playlist素材,音乐素材,视频素材,音效,素材下载,免费素材,免费素材下载'
        }
      },
      component: () => import('@/views/limitExempt')
    },
  ]
}
