const AppMain = () => import('@/views/index')

export default {
  path: '/member',
  name: 'Member',
  component: AppMain,
  redirect: '/member/center',
  children: [
    {
      path: '/member/center',
      name: 'Center',
      meta: {
        isLogin: true,
        hasMember: true
      },
      component: () => import('@/views/newMember/index.vue'),
      redirect: '/member/center/equity',
      children: [
        {
          path: '/member/center/equity',
          name: 'Equity',
          meta: {
            isLogin: true,
            hasMember: true
          },
          component: () => import('@/views/newMember/components/memberCard')
        },
        {
          path: '/member/center/equity/detail/:id',
          name: 'EquityDetail',
          meta: {
            isLogin: true,
            hasMember: true
          },
          component: () => import('@/views/member/equity/detail.vue')
        },
        {
          path: '/member/center/equity/impowerDetail/:id',
          name: 'ImpowerDetail',
          meta: {
            isLogin: true,
            hasMember: true
          },
          component: () => import('@/views/member/equity/impowerDetail.vue')
        },
        {
          path: '/member/center/order',
          name: 'Order',
          meta: {
            isLogin: true,
            hasMember: true
          },
          component: () => import('@/views/newMember/components/orderCard')
        },
        {
          path: '/member/center/order/detail/:id',
          name: 'OrderDetail',
          meta: {
            isLogin: true,
            hasMember: true
          },
          component: () => import('@/views/member/order/detail.vue')
        },
        {
          path: '/member/center/authorized-download',
          name: 'AuthorizedDownload',
          meta: {
            isLogin: true,
            hasMember: true
          },
          component: () =>
            import('@/views/member/authorized-download/index.vue')
        },
        {
          path: '/member/center/invoice',
          name: 'Invoice',
          meta: {
            isLogin: true,
            hasMember: true
          },
          component: () => import('@/views/member/invoice/index.vue')
        },
        {
          path: '/member/center/collect',
          name: 'Collect',
          meta: {
            isLogin: true,
            hasMember: true
          },
          component: () => import('@/views/newMember/components/CollectCard.vue')
        },
        {
          path: '/member/center/conversion',
          name: 'Conversion',
          meta: {
            isLogin: true,
            hasMember: true
          },
          component: () => import('@/views/member/conversion/index.vue')
        }
      ]
    }
  ]
}
