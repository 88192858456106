// 操作页面
import store from '@/store'
import {
  fetchBatchDownloadLink,
  fetchDownloadLink,
  operationInfoByTypeAndId,
  fetchRetailDownloadLink
} from '@/api/common'
import { fetchCardGoodsList, insertGoodsToCart } from '@/api/cart'
import { downloadByLink } from '@/utils/utils'
import { getToken } from '@/utils/author'
import { fetchExpiringMembership } from '@/api/member'
import { Message } from 'element-ui'
// import { EventBus } from './bus'

/**
 * 用户行为： 收藏
 * 2021-12-20 11:22:07
 * @author SnowRock
 * @param id id
 * @param mode 行为内容 2:音乐 3:音效 4:视频 5:歌单
 * @param info 信息
 */

export async function collectInfoById(id, mode, info) {
  // 首先收藏音乐\音效\视频\歌单 一致
  const notUpdateModes = [5, 4] // 不更新的mode
  try {
    await operationInfoByTypeAndId({
      object_id: id,
      mode,
      type: 1
    })
    info.is_collected = info.is_collected === 1 ? 0 : 1
    // 将信息更新至全局
    if (!notUpdateModes.includes(mode)) {
      // 更新全局数据内容
      await store.dispatch('music/updateList', info)
      if (store.state.music?.tmpMusicInfo?.object_id === info.object_id) {
        await store.dispatch('music/changeMusicInfo', info)
      }
    }
  } catch (e) {
    Promise.reject(e)
  }
}

/**
 * 用户行为： 分享内容
 * 2021-12-20 11:27:27
 * @author SnowRock
 * @param id
 * @param mode 行为内容 2:音乐 3:音效 4:视频 5:歌单
 * @param link 分享的链接
 * @returns {Promise<*>}
 */
export async function shareInfoById(id, mode, link) {
  try {
    if (getToken() && getToken() !== '') {
      await operationInfoByTypeAndId({
        object_id: id,
        mode,
        type: 2
      })
    }
    // 后续操作 打开模态框 将信息输入进去
    await store.dispatch('music/openCopyLinkDialog', link)
  } catch (e) {
    Promise.reject(e)
  }
}

/**
 * 用户行为：试听音乐
 * 2021-12-20 11:31:57
 * @author SnowRock
 * @param id
 * @param mode 行为内容 2:音乐 3:音效 4:视频 5:歌单
 * @returns {Promise<void>}
 */
export async function auditionInfoById(id, mode) {
  const info = {}
  try {
    await operationInfoByTypeAndId({
      object_id: id,
      mode,
      type: 3
    })
    // 将音乐加入全局音乐列表
    await store.dispatch('music/updateList', info)
  } catch (e) {
    Promise.reject(e)
  }
}

/**
 * 用户行为：下载
 * 2021-12-20 11:31:57
 * @author SnowRock
 * @param id
 * @param mode 行为内容 2:音乐 3:音效 4:视频 5:歌单
 * @param openrecord_id 开通记录id
 * @returns {Promise<void>}
 */
export async function downloadInfoById(id, mode, openrecord_id) {
  let ua = navigator.userAgent
  await operationInfoByTypeAndId({
    object_id: id,
    mode,
    type: 4
  })
  if (ua.match(/(iPhone|iPad|Android|ios)/i)) {
    let formdata = new FormData()
    formdata.append('openrecord_id', openrecord_id)
    formdata.append('object_id', id)
    formdata.append('object_type', mode)
    const response = await fetch(
      `${process.env.VUE_APP_BASE_URL}/fontend/certificate/download`,
      {
        method: 'POST',
        headers: {
          token: getToken()
        },
        body: formdata
      }
    )
    let result = await response.json()
    if (result.code !== 0) {
      this.$message.error(result.msg)
      return
    }
    let a = document.createElement('a')
    a.href = result.data.down_url
    a.style.display = 'none'
    a.download = 'download' // decodeURIComponent(filename)
    a.click()
    window.URL.revokeObjectURL(result.data.down_url)
  } else {
    try {
      // await operationInfoByTypeAndId({
      //   object_id: id,
      //   mode,
      //   type: 4
      // })
      // 将音乐加入全局音乐列表
      const { data, code, msg } = await fetchDownloadLink({
        openrecord_id,
        object_id: id,
        object_type: mode
      })
      if (code !== 0) {
        Message.error(msg)
        return
      }
      // const ua = navigator.userAgent;
      // if(ua.match(/(iPhone|iPad|Android|ios)/i)){
      //   EventBus.$emit('getDownInfo', data);
      // }else{
      downloadByLink(data.down_url, data.name)
      // }
    } catch (e) {
      Promise.reject(e)
    }
  }
}
/**
 * 下载
 * 2021-12-20 11:31:57
 * @author SnowRock
 * @param cart_ids 购物车ids
 * @param openrecord_id 开通记录id
 * @returns {Promise<void>}
 */
export async function batchDownloadInfoByIds(cart_ids, openrecord_id) {
  try {
    // await operationInfoByTypeAndId({
    //   object_id: id,
    //   mode,
    //   type: 4
    // })
    // 将音乐加入全局音乐列表
    const { data } = await fetchBatchDownloadLink({
      openrecord_id,
      cart_ids
    })
    data.forEach((i) => {
      downloadByLink(i.down_url, i.name)
    })
  } catch (e) {
    Promise.reject(e)
  }
}
// 零售音乐下载
export async function batchRetailMusicDownload(order_id) {
  try {
    const { data } = await fetchRetailDownloadLink({order_id})
    downloadByLink(data.url, data.name)
  } catch (e) {
    Promise.reject(e)
  }
}

/**
 * 新增商品至购物车
 * 2021-12-21 14:23:34
 * @author SnowRock
 * @param id
 * @param mode 商品类型：1AI语音 2音乐 3音效 4视频
 * @returns {Promise<void>}
 */
export async function addGoodsToCart(id, mode) {
  await insertGoodsToCart({
    object_id: id,
    object_type: mode
  })
  await getCartList()
}

/**
 * 获取购物车列表
 * 2021-12-21 17:55:23
 * @author SnowRock
 * @returns {Promise<void>}
 */
export async function getCartList() {
  try {
    // 将音乐加入购物车
    const { data } = await fetchCardGoodsList()
    store.dispatch('cart/updateList', data.list)
  } catch (e) {
    Promise.reject(e)
  }
}

/**
 * 获取已到期及即将到期的会员列表进行处理并且调整结果内容
 * 2022-04-21 13:30:08
 * @author SnowRock
 * @returns {Promise<void>}
 */
export async function checkedExpiringMembership() {
  try {
    const { data } = await fetchExpiringMembership()

    if (data.length > 0) {
      const expiringList = data.filter((i) => !i.is_expired) // 即将到期的会员
      store.dispatch('user/updateMembershipExpiringList', data)
      // 默认打开弹框用于处理功能信息
      // 如果即将到期的会员有数据则打开会员提醒到期弹框 否则打卡爱会员已过期弹框
      store.dispatch(
        expiringList.length > 0
          ? 'user/openExpiringDialog'
          : 'user/openExpiredDialog'
      )
    }
  } catch (e) {
    console.log(e)
  }
}
