const AppMain = () => import('@/views/index')

export default {
  path: '/message',
  name: 'Message',
  component: AppMain,
  redirect: '/message/page',
  children: [
    {
      path: '/message/page',
      name: 'Message',
      meta: {
        isLogin: true
      },
      component: () => import('@/views/message/index.vue')
    }
  ]
}
