import { fetchTempDeviceId } from "@/api/user";

export function getToken() {
  return localStorage.getItem('token') || null
}
export function getUserId() {
  return localStorage.getItem('userId') || null
}
export function getUserInfo() {
  let result = null
  const info = localStorage.getItem('userInfo')
  if (info && info !== '') {
    result = JSON.parse(info)
  }
  return result
}
export function setToken(info) {
  localStorage.setItem('token', info)
}
export function setUserId(info) {
  localStorage.setItem('userId', info)
}
export function setUserInfo(info) {
  localStorage.setItem('userInfo', JSON.stringify(info))
}

/**
 * 获取唯一标识
 */
export function getTempDeviceId() {
  const tempDeviceId = localStorage.getItem('tempDeviceId')
  return tempDeviceId
}

export function setTempDeviceId() {
  fetchTempDeviceId().then(res => {
    const { code, data: { device_id } } = res

    if (code !== 0) return

    localStorage.setItem('tempDeviceId', device_id)
  })
}
export function clearTempDeviceId() {
  localStorage.removeItem('tempDeviceId')
}
