<template>
  <el-dialog
    class="dialog-for-login"
    :visible="dialogVisible"
    :destroy-on-close="true"
    :before-close="handleBeforeClose"
    append-to-body>
    <div class="login-model-box">
      <div class="brand-box" />
      <div class="right-info-box">
        <div class="form-container login">
          <div class="title-container">
            <div class="title">
              <span>PLAY</span>
              <span>LIST</span>
            </div>
          </div>

          <component
            :is="componentName"
            @changeComponent="handleChangeComponent"
          />
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import RegisterForm from './components/RegisterForm'
import FindPwdForm from './components/FindPwdForm'
import LoginForm from './components/LoginForm'
import { getToken } from '@/utils/author'

export default {
  name: 'DialogForLogin',
  components: { RegisterForm, LoginForm, FindPwdForm },
  data() {
    return {
      isOpenLogin: null
    }
  },
  watch: {
    $route: {
      handler: function (route) {
        const query = route.query
        if (query) {
          this.isOpenLogin = query.isOpenLogin
        }
      },
      immediate: true
    }
  },
  computed: {
    componentName() {
      return this.$store.state.user.loginComponentsName
    },
    dialogVisible() {
      return this.$store.state.user.dialogForLoginVisible
    }
  },
  mounted() {
    this.isOpenLogin = this.$route.query.isOpenLogin
    if (!getToken() && this.isOpenLogin === '1') {
      this.$store.dispatch('user/openLogin')
    }
  },
  methods: {
    handleChangeComponent(name) {
      this.$store.dispatch('user/changeLoginFormComponentName', name)
    },
    handleBeforeClose() {
      this.$store.dispatch('user/closeLoginDialog')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./index";
</style>
