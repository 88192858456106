/**
 * vip 类型
 * @type {{personal: {label: string, value: number}, company: {label: string, value: number}}}
 */
export const VIP_TYPE = {
  PERSONAL: {
    label: '个人',
    value: 1
  },
  COMPANY: {
    label: '企业',
    value: 2
  }
}

/**
 * 商品类型
 * 2021-12-22 13:21:28
 * @author SnowRock
 * @type {{MUSIC: {label: string, value: number}, VIDEO: {label: string, value: number}, EFFECT: {label: string, value: number}, AI_SOUND: {label: string, value: number}}}
 */
export const GOODS_TYPE = {
  AI_SOUND: { label: 'AI语音', value: 1 },
  MUSIC: { label: '音乐', value: 2 },
  EFFECT: { label: '音效', value: 3 },
  VIDEO: { label: '视频', value: 4 },
  PICTURE: { label: '图片', value: 5 }
}

/**
 * 订单支付状态
 */
export const ORDER_PAY_STATUS = {
  WAITING: { label: '支付确认中', value: '0' },
  REFUND: { label: '支付成功', value: '4' }
}

/**
 * 会员套餐年限
 * @type {{mouth: {label: string, value: number}, year: {label: string, value: number}, halfYear: {label: string, value: number}}}
 */
export const USE_MODES = {
  year: { label: '年度', value: 3 },
  halfYear: { label: '半年', value: 2 },
  mouth: { label: '月度', value: 1 }
}

/**
 * 发票状态
 */
export const INVOICE_STATUS = {
  unapply: { label: '未开票', value: -1 },
  apply: { label: '已申请待审核', value: 0 },
  authSuccess: { label: '已开票', value: 1 },
  authFail: { label: '审核未通过', value: 2 }
}
/**
 * 零售订单支付状态
 */
 export const RETAIL_PAY_STATUS = [
  { label: '待支付', value: 0 },
  { label: '已超时', value: 2 },
  { label: '已支付', value: 4 }
]
/**
 * 零售订单支付按钮文案
 */
 export const RETAIL_PAY_BTN = [
  { label: '立即支付', value: 0 },
  { label: '重新支付', value: 2 },
  { label: '再次购买', value: 4 }
]
// 支付方式
export const PAY_TYPE = [
  { label: '对公转账', value: 'PUBLIC_PAY' },
  { label: '微信支付', value: 'WX_PAY' },
  { label: '微信移动支付', value: 'WX_H5' },
  { label: '支付宝支付', value: 'ALI_PAY' },
  { label: '支付宝移动支付', value: 'ALI_H5' },
  { label: '支付宝APP支付', value: 'ALI_APP' },
  { label: '支付宝包月', value: 'ALI_CYCLE_PAY' },
]
