class VAudio {
  constructor() {
    this.player = new Audio();
    this.currentSrc = '';
    Object.defineProperty(this.player, 'currentSrc', {
      get() {
        return this.currentSrc;
      },
      set: (function (_this) {
        return function (currentSrc) {
          _this.currentSrc = currentSrc;
          if (!_this.currentSrc) {
            return;
          }
          return _this.player.src = _this.currentSrc;
        };
      }(this))
    });
    return this.player;
  }
}

export default VAudio;
