export default {
  name: 'CountDown',
  data() {
    return {
      inSendSmsReady: true,
      timer: null,
      time: 60
    }
  },
  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = null
      this.inSendSmsReady = true
    }
  },
  methods: {
    /**
     * 倒计时
     * 2021-12-16 14:57:43
     * @author SnowRock
     */
    countDownTime(isReload) {
      if (isReload) {
        this.time = 60
        this.inSendSmsReady = false
      }
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      if (this.time === 0) {
        this.inSendSmsReady = true
        return
      }
      this.time--
      this.timer = setTimeout(() => {
        this.countDownTime(false)
      }, 1000)
    }
  }
}
