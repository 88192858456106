/**
 * 购物车
 * 2021-12-20 13:13:48
 * @author SnowRock
 */
const state = {
  cartList: []
}

const actions = {
  // 更新购物车列表
  updateList(store, list) {
    store.commit('UPDATE_CART_LIST', list)
  }
}

const mutations = {
  // 更新购物车列表
  UPDATE_CART_LIST(state, list) {
    state.cartList = list
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
